import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CustomQueryService {
  private customQueryDetails: object = {};
  private customQuerySubject = new Subject<any>();
  customQueryState = this.customQuerySubject.asObservable();

  constructor() {}

  saveCustomQueryDetails(uuid: string, customQuery: object) {
    if (uuid && customQuery) {
      this.customQueryDetails[uuid] = customQuery;
      this.customQuerySubject.next({
        customQueryName: this.customQueryDetails[uuid].name,
        customQueryUuid: uuid,
      } as any);
    }
  }

  initCustomQueryDetails(customQueryDetails: {}) {
    this.customQueryDetails = customQueryDetails;
  }

  getCustomQueryDetails(uuid: string = null) {
    if (uuid) {
      return this.customQueryDetails[uuid];
    }
    return this.customQueryDetails;
  }

  removeNode(nodeName: string) {
    delete this.customQueryDetails[nodeName];
  }
}
