// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}
.card {
  min-width: 160px;
  min-height: 77px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.card-inner {
  padding: 10px;
}
.card-heading {
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
}
.card-subHeading {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/app/studies/studies.component.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,WAAA;AACF;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;AADF;AAIA;EACE,eAAA;EACA,gBAAA;EACA,6BAAA;AAFF;AAKA;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAHF","sourcesContent":[".card-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 0.5rem;\n  width: 100%;\n}\n\n.card {\n  min-width: 160px;\n  min-height: 77px;\n  border-radius: 2px;\n  display: flex;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.card-inner {\n  padding: 10px;\n}\n\n.card-heading {\n  font-size: 14px;\n  font-weight: 600;\n  background-color: transparent;\n}\n\n.card-subHeading {\n  font-size: 20px;\n  font-weight: 600;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
