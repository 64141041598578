import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StudiesComponent } from './studies/studies.component';
import { AppInterceptor } from './_helpers/app.interceptor';
import { WidgetsModule } from './widgets/widgets.module';

import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './_components/page-layout/header/header.component';
import { UserBarComponent } from './_components/page-layout/user-bar/user-bar.component';
import { TopNavComponent } from './_components/page-layout/top-nav/top-nav.component';
import { FooterComponent } from './_components/page-layout/footer/footer.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { CreateflowComponent } from './createflow/createflow.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlowNodeComponent } from './_components/flow-node/flow-node.component';
import { FlowNodeContainerComponent } from './_components/flow-node-container/flow-node-container.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './_components/modal/modal.component';
import { ModalActionDefinitionComponent } from './_components/modal-action-definition/modal-action-definition.component';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { ZsSelectComponent } from './_components/zs-select/zs-select.component';
import { ToastrModule } from 'ngx-toastr';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { SortDirective } from './_directives/sort.directive';
import { CommonService } from './_services/common.service';
import { DisableControlDirective } from './_directives/disable-control.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlowDefinitionComponent } from './_components/flow-definition/flow-definition.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [
    AppComponent,
    StudiesComponent,
    UserBarComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    TopNavComponent,
    CreateflowComponent,
    ModalComponent,
    FlowNodeComponent,
    FlowNodeContainerComponent,
    ModalActionDefinitionComponent,
    SafeHtmlPipe,
    ZsSelectComponent,
    SortDirective,
    DisableControlDirective,
    FlowDefinitionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    DragDropModule,
    NgbModule,
    MatButtonModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatIconModule,
    WidgetsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }, DatePipe, CommonService, NgDialogAnimationService],
  // entryComponents: [FlowNodeComponent, ModalComponent, ModalActionDefinitionComponent, FlowDefinitionComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
