import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, Renderer2, OnDestroy, AfterContentInit } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { common } from 'src/app/app.messages';
import { COLOR_CODE_1 } from 'src/app/app.constants';

@Component({
  selector: 'app-d3-horizontal-bar',
  templateUrl: './d3-horizontal-bar.component.html',
  styleUrls: ['./d3-horizontal-bar.component.less'],
})

// tslint:disable: radix
// tslint:disable: variable-name
export class D3HorizontalBarComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  public commonMessage = common;
  @ViewChild('svgHorizontalBarChartContainer', { static: true }) svgHorizontalBarChartContainer: ElementRef;
  @Input() inputData;
  @Input() isAxisLeft = true;
  @Input() width = 130;
  @Input() height = 200;
  @Input() customColorDomainType = 'drugs';
  @Input() colorRange = COLOR_CODE_1;

  private margin = { top: 0, right: 80, bottom: 10, left: 80 };
  private color: any;
  private _data;
  private barLegends = [];

  // group containers (X axis, Y axis and bars)
  private gx: any;
  private gy: any;
  private bars: any;
  

  // Axis and Scales
  private xAxis: any;
  private yAxis: any;
  private xScale: any;
  private yScale: any;

  // Drawing containers
  private svg: any;
  private mainContainer: any;

  // Tooltip and labels
  private tooltip: any;
  private labels: any;
  public showNoDataFound = false;

  public chartLabel = '';

  public svgWidth = 600;
  public svgHeight = 300;
  private story = null;
  private _unsubscribeResize = null;

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}

  getSvgDimension() {
    this.svgWidth = this.width + this.margin.left + this.margin.right;
    this.svgHeight = this.height + this.margin.top + this.margin.bottom;
  }

  updateLabels() {
    const newLabels = this.inputData.horizontalBarData.map((el) => el.name);
    this.labels = this.labels && this.areArraysEqual(this.labels, newLabels) ? this.labels : newLabels;
  }

  areArraysEqual(arr1, arr2) {
    return (
      arr1 &&
      arr2 &&
      arr1.length === arr2.length &&
      arr1.every((element) => {
        arr2.includes(element);
      })
    );
  }

  renderBar() {
    this.getSvgDimension();
    const groupConfig = {
      left: this.margin.left,
      top: 20,
    };
    if (!this.isAxisLeft) {
      groupConfig.left = this.svgWidth - this.margin.right;
    }
    this._data = this.inputData;
    this.chartLabel = this.inputData.label;
    this.updateLabels()

    this.svg = d3.select(this.svgHorizontalBarChartContainer.nativeElement).append('svg');
    this.xScale = d3.scaleBand();
    this.yScale = d3.scaleLinear();
    this.setSVGDimensions();
    this.color = d3
    .scaleOrdinal()
    .range([...this.colorRange])
    .domain(this.labels);
    this.mainContainer = this.svg.append('g').attr('transform', `translate(${groupConfig.left}, ${groupConfig.top})`);
    this.gy = this.mainContainer.append('g');
    this.gx = this.mainContainer.append('g');

    this.tooltip = d3.select('#bar').append('div').attr('class', 'toolTip');
    const { resize, heightAspect, widthAspect } = this._responsivefy(this.svg);

    this.draw()
    return { resize };
  }

  ngOnChanges() {
    if (this.svg) {
      this.svg.remove();
    }
    if (this.inputData.horizontalBarData.length > 0) {
      this.showNoDataFound = false;
      this.story = this.renderBar();
    } else {
      this.showNoDataFound = true;
    }
  }

  private setSVGDimensions() {
    this.svg.attr('width', this.svgWidth).attr('height', this.svgHeight);
  }

  private drawAxis() {
    const yAxisFontSize = this.inputData.yAxisFontSize ? this.inputData.yAxisFontSize : 12.8;
    this.gy
      .style('font-size', `${yAxisFontSize}px`)
      .attr('transform', `translate(0, 0)`)
      .attr('class', `axis axis--y`)
      .call(this.yAxis);
  }

  private setAxisScales() {
    let xScaleDomain = [this.width, 0];
    if (this.isAxisLeft) {
      xScaleDomain = [0, this.width];
    }
    this.xScale = d3.scaleLinear().range(xScaleDomain);
    this.yScale = d3.scaleBand().range([this.height, 0]).padding(0.5);

    this.xScale.domain([0, Math.max(...this._data.horizontalBarData.map((x) => Math.ceil(x.percentage / 5) * 5))]);
    this.yScale.domain(this._data.horizontalBarData.map((d) => d.name));

    this.xAxis = d3.axisTop(this.xScale).tickSize(0);

    if (this.isAxisLeft) {
      this.yAxis = d3.axisLeft(this.yScale).tickSize(0);
    } else {
      this.yAxis = d3.axisRight(this.yScale).tickSize(0);
    }
  }

  private draw() {
    this.setAxisScales();
    this.drawAxis();
    this.drawBars();
    this.drawLabels();
  }

  private resize() {
    this.setSVGDimensions();
    this.draw();
  }

  private drawBars() {
    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'graph-tooltip dark')
      .attr('id', 'grouped-stack-chart-custom-tooltip')
      .style('display', 'none')
      .style('position', 'absolute');

    this.bars = this.mainContainer
      .selectAll('.bar')
      .data(this._data.horizontalBarData)
      .enter()
      .append('rect')
      .attr('fill', (d, i) => this.color(`${d.name}`))
      .on('mouseover', function (d) {
        const originalColor = d3.select(this).style('fill');
        d3.select(this).style('fill', d3.rgb(originalColor).darker(1));
        div.style('display', 'inline');
      })
      .on('mousemove', (d) => {
        const name = d.name;
        const value = d.value;
        div
          .html(`${name} : ${value}`)
          .style('left', d3.event.pageX + 15 + 'px')
          .style('top', d3.event.pageY - 10 + 'px')
          .style('font-size', '12px')
          .style('z-index', '1000');
      })
      .on('mouseout', function (d) {
        const changedColor = d3.select(this).style('fill');
        d3.select(this).style('fill', d3.rgb(changedColor).brighter(1));
        div.style('display', 'none');
      });

    this.bars.attr('y', (d) => this.yScale(d.name)).attr('height', this.yScale.bandwidth());

    this.bars
      .attr('x', (d) => {
        return this.getXScaleValue(d.percentage);
      })
      .attr('width', (d) => {
        if (d.percentage > 0) {
          return this.isAxisLeft ? this.xScale(d.percentage) : this.xScale(0) - this.xScale(d.percentage);
        } else {
          return 0;
        }
      });
  }

  private drawLabels() {
    this.labels = this.mainContainer
      .selectAll('.label')
      .remove()
      .exit()
      .data(this._data.horizontalBarData)
      .enter()
      .append('text')
      .attr('y', (d) => this.yScale(d.name) + this.yScale.bandwidth() * 0.7);

    this.labels
      .attr('x', (d) => this.getXScaleValue(d.percentage, true))
      .attr('text-anchor', this.isAxisLeft ? 'start' : 'end')
      .text((d) => d.percentage + '%')
      .attr('font-size', () => {
        const barSize = this.inputData.yAxisFontSize ? this.inputData.yAxisFontSize : 12.8;
        return barSize;
      });
    // this.barWidth = this.yScale.bandwidth();
    var yAxis = d3.select('.axis--y'); //Selector should be the class given to the x axis

    yAxis
      .selectAll('.tick')
      .selectAll('text')
      .html('')
      .append('tspan')
      .text(function (d) {
        return d;
      })
      .each(this.wrap);
  }

  getXScaleValue(value, isLegends = false) {
    if (this.isAxisLeft) {
      return isLegends ? this.xScale(value) + 5 : 1;
    } else {
      const xCoordinate = isLegends ? this.xScale(value) - this.xScale(0) - 5 : this.xScale(value) - this.xScale(0);
      return xCoordinate;
    }
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.story.resize();
        }
      }, 700)
    );
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  wrap() {
    var barWidth = 100,
      padding = 0;
    var self = d3.select(this),
      textLength = self.node().getComputedTextLength(),
      text = self.text();
    while (textLength > barWidth - 2 * padding && text.length > 0) {
      text = text.slice(0, -1);
      self.text(text + '...');
      textLength = self.node().getComputedTextLength();
    }
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
