import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpWrapperService } from '../http-wrapper.service';
import { SensitiveInfoService } from '../sensitive-info.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

// tslint:disable variable-name
export class UsersApiService {
  constructor(private _httpService: HttpWrapperService, private _sensitiveInfoService: SensitiveInfoService) {}

  getIdmUsersList(projectId, username): Observable<Response> {
    return this._httpService.get(`${environment.homeUrl}/users_in_project?project_id=${projectId}&application=ORCHESTRATOR&user_id=${username}`);
  }

  getUserSession() {
    return this._httpService.get(`${environment.api_url}/auth/user-session/`);
  }
}
