import { Injectable } from '@angular/core';
declare let fernet: any;

@Injectable({
  providedIn: 'root',
})

export class SensitiveInfoService {
  key = 'quK_aT7GbWH3Nx3hdf4-GxWfvO-J8NtsoXk4LIuovKI=';
  constructor() {}

  getSecret(key){
    return new fernet.Secret(key);
  }

  encrypt(data) {
    if(typeof data !== 'string'){
      data = JSON.stringify(data)
    }
    const token = new fernet.Token({
      secret: this.getSecret(this.key),
      time: (new Date).getTime(),
      iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    })

    return token.encode(data)
  }

  decrypt(cipherText) {
    const secret = this.getSecret(this.key)
    const token = new fernet.Token({
      secret: secret,
      token: cipherText,
      ttl: 0
    })
    return JSON.parse(token.decode())
  }
}
