/**
 * @module Field 
 * @author: Mikhail Vazhenin @ZS
 */

import ComponentMixin from '../smart/component.m.js';

class Base extends HTMLParagraphElement {
	constructor(...args) {
		super(...args);
	}
}

/**
 * Field component
 * @extends HTMLParagraphElement
 * @example <p is="zs-field" class="zs-field" label="Simple" type="text" clear></p>
 * @example let el = document.createElement('p', {is: 'zs-field'})
 */
export default class Field extends ComponentMixin(Base) {
	constructor(...args) {
		// Constructor caveat https://github.com/WebReflection/document-register-element/
		const _ = super(...args);
		return _;
	}

	/**
	 * Use it with or instead of constructor to avoid {@link https://github.com/ungap/custom-elements-builtin|constructor caveat}
	 */
	setup() {

		super.setup();

		this.on(['change', 'click', 'focus', 'blur', 'invalid', 'input'], undefined, undefined, false, true);
		this.syncProp('name');
		this.syncProp('invalid');
		this.syncProp('min');
		this.syncProp('max');
		this.syncProp('size');
		this.syncProp('value');
		this.syncProp('clear');
		this.syncProp('icon');
		this.syncProp('type');
		this.syncProp('label');
		this.syncProp('disabled');
		this.syncProp('placeholder');
		this.syncProp('zsForm');
		this.syncProp('list');
		this.syncProp('autofocus');
		this.syncProp('autocomplete');
		this.syncProp('readonly');
		this.syncProp('required');
		this.syncProp('zsTabindex'); // tabindex is a special case where we want it to be present only on input element and not on field, hence 'zs' prefix.
		this.syncProp('pattern');
		this.syncProp('minlength');
		this.syncProp('maxlength');
		this.syncProp('src');
		this.syncProp('step');
		this.syncProp('checked');
		this.syncProp('multiple');
		this.syncProp('alt');
		this.syncProp('height');
		this.syncProp('width');
		this.syncProp('indeterminate');
		this.syncProp('pristineCheckbox');
	}
	static get observedAttributes() {
		return super.observedAttributes.concat(['checked', 'name', 'value', 'placeholder', 'clear', 'icon', 'type', 'list', 'disabled', 'autofocus', 'autocomplete', 'readonly', 'required', 'zs-tabindex', 'pattern', 'label', 'min', 'max', 'size', 'minlength', 'maxlength', 'src', 'step', 'multiple', 'alt', 'height', 'width', 'zs-form', 'invalid', 'indeterminate', 'pristine-checkbox']);
	}

	getPropertyType(name) {
		switch (name) {
			case 'clear':
			case 'disabled':
			case 'readonly':
			case 'autofocus':
			case 'required':
			case 'checked':
			case 'multiple':
			case 'indeterminate':
			case 'invalid':
			case 'pristine-checkbox':
				return 'boolean';
			case 'min':
			case 'max':
				if (this.type == 'week' || this.type == 'time') {
					return 'string';
				}
			case 'zs-tabindex':
			case 'step':
			case 'size':
			case 'minlength':
			case 'maxlength':
				return 'number';
			default:
				return 'string';
		}
	}
	attributeChangedCallback(name, oldValue, newValue) {
		super.attributeChangedCallback(name, oldValue, newValue);

		this.syncAttr(name, newValue, this.getPropertyType(name));
	}

	propertyChangedCallback(name, oldValue, newValue) {
		if (!this.parentNode && !this.innerHTML) {
			return;
		}
		switch (name) {
			case 'invalid':
				break;
			case 'clear':
				this.clearIconElement && this.clearIconElement.toggleAttribute('hidden', !this.isClearVisible);
			case 'icon':
				if (this.fieldWrapperElement) {
					this.fieldWrapperElement.setAttribute('class', this.iconClass);
				}
				break;
			case 'label':
				if (this.labelElement) {
					this.labelElement.innerHTML = this.sanitizeInput(newValue);
				}
				break;
			case 'indeterminate':
				if (this.inputElement && this.type == 'checkbox') {
					this.inputElement.indeterminate = !!newValue;
				}
				break;
			case 'zsTabindex':
				if (this.inputElement) {
					if (newValue == null) {
						this.inputElement.removeAttribute('tabindex');
					} else {
						this.inputElement.setAttribute('tabindex', newValue);
					}
				}
				break;
			case 'zsForm':
				if (this.inputElement) {
					if (newValue == null) {
						this.inputElement.removeAttribute('tabindex');
					} else {
						this.inputElement.setAttribute('form', newValue);
					}
				}
				break;
			case 'checked':
				if (this.inputElement) {
					if (newValue == null || newValue === false) {
						this.inputElement.removeAttribute(name);
					} else if (newValue === true) {
						this.inputElement.toggleAttribute(name, true);
					} else {
						this.inputElement.setAttribute(name, newValue);
					}
					// ZSUI-1678: Checkbox and Radios don't sync their 'checked' attribute with properties natively.
					// Hence, we need to do it explicitly.
					this.inputElement[name] = newValue;

					if (this.type === 'checkbox' && this.pristineCheckbox) {
						this.value = '' + newValue;
					}
				}
				break;
			case 'type':
				if (this.inputElement && this.inputElement.type !== this.type) {
					if (this.type == 'radio' || this.type == 'checkbox') {
						if (this.checkRadioElement) {
							if (this.type == 'radio') {
								this.checkRadioElement.setAttribute('radio', '');
								this.checkRadioElement.removeAttribute('checkbox');
							} else {
								this.checkRadioElement.setAttribute('checkbox', '');
								this.checkRadioElement.removeAttribute('radio');
							}
						} else {
							var el = document.createElement('span');
							el.setAttribute(this.type, '');
							this.fieldWrapperElement.appendChild(el);
						}
					} else {
						this.checkRadioElement ? this.checkRadioElement.parentElement.removeChild(this.checkRadioElement) : null;
					}
				}
				this.handlePropertyChangeDefaultCase(name, oldValue, newValue);
				break;
			case 'value':
				this.clearIconElement && this.clearIconElement.toggleAttribute('hidden', !this.isClearVisible);
				if (this.inputElement) {
					if (this.inputElement.value != newValue) {
						this.inputElement.value = newValue;
						this.checkValidity();
					}

					if (this.type === 'checkbox' && this.pristineCheckbox) {
						if (newValue === null) {
							this.indeterminate = true;
						} else {
							this.indeterminate = false;
							this.checked = (newValue == 'true') ? true : false;
						}
					}
				}
				this.handlePropertyChangeDefaultCase(name, oldValue, newValue);
				break;
			default:
				this.handlePropertyChangeDefaultCase(name, oldValue, newValue);
		}
	}

	handlePropertyChangeDefaultCase(name, oldValue, newValue) {
		if (this.inputElement) {
			if (newValue == null || newValue === false) {
				this.inputElement.removeAttribute(name);
			} else if (newValue === true) {
				this.inputElement.toggleAttribute(name, true);
			} else {
				this.inputElement.setAttribute(name, newValue);
			}
		}
	}

	get isClearVisible() {
		return (this.clear && (this.value && this.value != ''))
	}
	get labelElement() {
		return this.root.querySelector('[label]');
	}
	get inputElement() {
		return this.root.querySelector('[input]');
	}
	get clearIconElement() {
		return this.root.querySelector('[clear]')
	}
	get fieldWrapperElement() {
		return this.root.querySelector('[field]');
	}
	get checkRadioElement() {
		return this.root.querySelector('[radio]') || this.root.querySelector('[checkbox]');
	}

	get iconClass() {
		return this.icon ? 'zs-input-icon ' + this.icon : (this.clear ? 'zs-input-icon' : '');
	}

	get form() {
		return this.inputElement ? this.inputElement.form : undefined;
	}
	oninput(event) {
		if (event.target == this.inputElement) {
			this.value = event.target.value;
			this.checkValidity();
			if (this.type == 'checkbox' || this.type == 'radio') {
				this.checked = this.inputElement.checked;
			}
		} else {
			console.log(event.target, this.value);
		}
	}

	onchange(event) {
		// ZSUI-1683: Only specific to IE11. Only oninput should suffice for modern browsers.
		if (!!window.MSInputMethodContext && !!document.documentMode && (this.type == 'checkbox' || this.type == 'radio')) {
			this.oninput(event);
		}
	}

	onclick(event) {
		if (event.target === this.clearIconElement) {
			this.value = '';
		}
	}
	get validity() {
		return this.inputElement.validity;
	}
	setCustomValidity(message) {
		this.inputElement.setCustomValidity(message);
	}
	get validationMessage() {
		return this.inputElement.validationMessage;
	}
	checkValidity() {
		this.invalid = (this.inputElement.checkValidity() === false);
		return !this.invalid;
	}
	reportValidity() {
		this.inputElement && this.inputElement.reportValidity();
	}
	sanitizeInput(str) {
		var temp = document.createElement('div');
		temp.textContent = str;
		return temp.innerHTML;
	}

	oninvalid(event) {
		this.invalid = true;
	}
	renderAttributes(names) {
		let self = this;
		return names.reduce((prev, current) => {
			if (self[current] != null && self[current] !== false) {
				if (current == 'zsTabindex') {
					return prev + ' ' + (`tabindex="${self['zsTabindex']}"`);
				}
				return prev + ' ' + (typeof self[current] == 'boolean' ? current : `${current}="${self[current]}"`);
			} else {
				return prev;
			}
		}, '');
	}
	select() {
		this.inputElement && this.inputElement.select();
	}
	focus() {
		this.inputElement && this.inputElement.focus();
	}
	setRangeText(...args) {
		this.inputElement && this.inputElement.setRangeText(...args);
	}
	setSelectionRange(...args) {
		this.inputElement && this.inputElement.setSelectionRange(...args);
	}
	blur() {
		this.inputElement && this.inputElement.blur();
	}
	template() {
		return `
			${this.shadowRoot ? this.styles() : ''}
			<label class="${this.shadowClass}" style="position: relative;">	
				<span label>${this.sanitizeInput(this.label) || ''}</span>
				<span field class="${this.iconClass}">
					<input input ${this.renderAttributes(['name', 'value', 'disabled', 'placeholder', 'type', 'form', 'list', 'autofocus', 'autocomplete', 'readonly', 'required', 'zsTabindex', 'pattern', 'min', 'max', 'step', 'checked', 'minlength', 'maxlength', 'size', 'multiple', 'alt', 'height', 'width'])}/>
					<a class="zs-icon zs-icon-close-circle-fill  zs-interactive-primary" tabindex="-1" clear href="#" onclick="return false;" ${!this.isClearVisible ? ` hidden` : ''}></a>
					${(this.type == 'radio' || this.type == 'checkbox') ? '<span ' + this.type + '></span>' : ''}
				</span>
			</label>
		`
	}
	get shadowClass() {
		return this.shadowRoot ? (Field.shadowClass || '') : '';
	}
	static get shadowClass() {
		return 'zs-field-shadow';
	}
	get root() {
		if (typeof this.attachShadow == 'function' && Field.useShadowDom) {
			if (!this.shadowRoot) {
				this.attachShadow({
					mode: 'open'
				});
			}
			return this.shadowRoot;
		} else {
			return this
		}
	}
	styles(...args) {
		return Field.styles(...args);
	}
	render() {
		let root = this.root;
		if (!root) {
			return;
		}
		let html = this.template();
		if (root.innerHTML != html) {
			root.innerHTML = html;

			// Bubble `blur`, `focus` events but not `change`, `input` or `invalid` 
			if (this.inputElement) {
				this.off(['blur', 'focus'], this, this.inputElement);
				this.on(['blur', 'focus'], this, this.inputElement);
				this.inputElement.field = this;
				if(this.type === 'checkbox'){
					this.inputElement.indeterminate = this.indeterminate;
				}
			}

			if (this.type === 'checkbox' && this.pristineCheckbox) {
				if (!this.value) {
					this.value = (this.checked == null) ? null : '' + this.checked;
				} else {
					this.checked = (this.value == 'true') ? true : false;
				}

				this.indeterminate = !this.checked && (this.value == null);
			}
			
		}
		this.fire('render', { bubbles: true });
	}
	connectedCallback() {
		super.connectedCallback();
		if (this.parentNode && !this.innerHTML) {
			this.render();
		}
	}

}

// Initial tag and is
Field.is = 'zs-field';
Field.tagName = 'p';

// ToggleAttribute polyfill for IE11
if (!Element.prototype.toggleAttribute) {
	Element.prototype.toggleAttribute = function (name, force) {
		if (force !== void 0) force = !!force

		if (this.hasAttribute(name)) {
			if (force) return true;

			this.removeAttribute(name);
			return false;
		}
		if (force === false) return false;

		this.setAttribute(name, "");
		return true;
	};
}
