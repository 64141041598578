import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Input,
  DoCheck,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import * as d3 from 'd3';

declare let D3Funnel: any;

@Component({
  selector: 'app-d3-funnel-chart',
  templateUrl: './d3-funnel-chart.component.html',
  styleUrls: ['./d3-funnel-chart.component.sass'],
})
export class D3FunnelChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  // @Input() data;
  funnelData: any[];
  public showFunnelChartAfterLoad = true;
  @Input() shouldListenToEvents;
  @Input() isClickEvent;
  @Input() commonContainer = {
    width: null,
    height: null,
    spaceSize: null,
    percentWidth: null,
    percentHeight: null,
    isResponsive: null,
  };
  @Input() set data(data) {
    this.funnelData = [...data];
    this.refreshChart();
  }
  @Output() funnelDetails = new EventEmitter<any>();

  private _unsubscribeResize = null;
  private story: any = {
    resize: Function,
  };

  private funnelHeight;

  @ViewChild('svgFunnelChartContainer', { static: true }) svgFunnelChartContainer: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.refreshChart();
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }

  ngOnChanges() {
    this.refreshChart();
  }

  renderStory() {
    this.commonContainer.width = this.commonContainer.width || 200;
    this.funnelHeight = this.commonContainer.height * this.funnelData.length || 200;
    this.commonContainer.spaceSize = this.commonContainer.spaceSize || 10;
    const options = {
      block: {
        // dynamicHeight: true,
        minHeight: 15,
      },
      chart: {
        width: this.commonContainer.width * 0.5, //60% of container width
        height: this.funnelHeight,
        // bottomPinch: 1,
        // animate: 100
      },
      label: {
        format: '{f}',
      },
    };

    const divContainer = d3.select(this.svgFunnelChartContainer.nativeElement);

    const svg = divContainer.select('svg').remove();
    this.svgFunnelChartContainer.nativeElement.style.opacity = 0;
    const chart = new D3Funnel(this.svgFunnelChartContainer.nativeElement);
    chart.draw(this.funnelData, options);
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 0);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  private renderSideSection() {
    const divContainer = d3.select(this.svgFunnelChartContainer.nativeElement);

    const svg = divContainer.select('svg');
    const sideSectionWidth = this.commonContainer.width * 0.45; // 40% of container width
    const initialFunnelPathWidth = this.commonContainer.width;
    svg.attr('width', this.commonContainer.width);

    const data = this.funnelData;
    const masterFunnelPathGroups = divContainer.select('svg g');

    masterFunnelPathGroups.style('transform', `translate(${sideSectionWidth}px, 0px)`);
    const shouldListenToEvents = this.shouldListenToEvents;
    setTimeout(() => {
      const funnelPaths = divContainer.selectAll('svg g g');

      svg.attr('height', +svg.attr('height') + funnelPaths._groups[0].length * this.commonContainer.spaceSize);

      svg.select('foreignObject').remove();

      let foreignObjectWidth = sideSectionWidth + (initialFunnelPathWidth - sideSectionWidth) / 2;

      const foreignObjectBlock = svg
        .insert('foreignObject', ':first-child')
        .attr('id', 'foreignObject-block')
        .attr('height', svg.attr('height'))
        .attr('width', foreignObjectWidth);

      const colorScale = d3.scaleLinear([0, this.funnelData.length - 1], ['#022D42', '#82DFE2']).interpolate(d3.interpolateRgb.gamma(1.9));

      const parentThis = this;
      funnelPaths.each(function (element, key) {
        let funnelPathGroup = d3
          .select(this)
          .style('transform', 'translate(' + 0 + 'px, ' + key * parentThis.commonContainer.spaceSize + 'px)');

        funnelPathGroup
          .select('path')
          // d3.select(this + " p")
          .attr('fill', colorScale(key));

        funnelPathGroup.select('text').attr('font-size', '12px');
        let activeClass;
        if (shouldListenToEvents) {
          activeClass = key == 0 ? 'funnel-content-wrapper-active' : '';
        }
        let funnelPathBound = d3.select(this).node().getBoundingClientRect();
        let div = foreignObjectBlock
          .append('xhtml:div')
          .attr('class', 'funnel-content-wrapper d-flex align-items-center ' + activeClass)
          .attr('id', 'funnel-content-wrapper-' + key);
        if (shouldListenToEvents) {
          div.on('click', () => {
            parentThis.funnelDetails.emit(data[key]);
            document.querySelector('.funnel-content-wrapper-active').classList.remove('funnel-content-wrapper-active');
            document.querySelector('#funnel-content-wrapper-' + key).classList.add('funnel-content-wrapper-active');
          });
        }
        div
          // .style('transform', 'translate(' + 0 + 'px, ' + key * parentThis.commonContainer.spaceSize + 'px)')
          .style('min-height', `${funnelPathBound.height}px`)
          .style('max-height', `${funnelPathBound.height}px`);

        if (key !== 0) {
          div.style('margin-top', parentThis.commonContainer.spaceSize + 'px');
        }

        const label = data[key].data;
        const marginTop = label.length >= 40 ? 'custom-margin-content' : '';
        div
          .append('xhtml:div')
          .attr('class', `content ${marginTop}`)
          .attr('title', `${label}`)
          .style('width', `${foreignObjectWidth - funnelPathBound.width / 2}px`)
          .style('display', `flex`)
          .style('align-items', `center`)
          .style('font-size', `12px`)
          .html(data[key].data);
      });

      // const { resize, heightAspect, widthAspect } = this._responsivefy(svg);

      // this.story.resize = resize;
      this.showFunnelChartAfterLoad = false;
      this.svgFunnelChartContainer.nativeElement.style.opacity = 1;
    }, 500);
  }

  refreshChart() {
    this.renderStory();
    this.renderSideSection();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.commonContainer.isResponsive) {
      this.commonContainer.width = Math.floor(event.target.innerWidth * this.commonContainer.percentWidth);
      this.commonContainer.height = Math.floor(event.target.innerHeight * this.commonContainer.percentHeight);
    }
    this.refreshChart();
  }
}
