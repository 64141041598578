import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PythonModuleService {
  private pythonFiles: object = {};
  private pythonModuleSubject = new Subject<any>();
  pythonModuleState = this.pythonModuleSubject.asObservable();

  constructor() {}

  dummyFunc() {
    /*
    {
      "Python Module_d1ce37aad8b3a": {
        "python_file" : {
          "file_name": '',
          "file_data": ""
        },
        "requirements_file" : {
          "file_name": '',
          "file_data": ""
        }
        "name": '' 
      }
    }
    */
  }

  savePythonFiles(uuid: string, pythonFileDetail: object) {
    if (uuid && pythonFileDetail) {
      this.pythonFiles[uuid] = pythonFileDetail;
      this.pythonModuleSubject.next({
        pythonModuleName: this.pythonFiles[uuid].name,
        pythonModuleUuid: uuid,
      } as any);
    }
  }

  initPythonFilesDetails(pythonFileDetail: {}) {
    this.pythonFiles = pythonFileDetail;
  }

  getPythonFiles(uuid: string = null) {
    if (uuid) {
      return this.pythonFiles[uuid];
    }
    return this.pythonFiles;
  }
}
