import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlowService } from 'src/app/_services/flow.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { FlowApiService } from 'src/app/_services/api/flow.api.service';
import { Router } from '@angular/router';
import { USER_ROLES } from 'src/app/app.constants';
import { SensitiveInfoService } from 'src/app/_services/sensitive-info.service';
import { UsersApiService } from '../../_services/api/users.api.service';

@Component({
  selector: 'app-flow-definition',
  templateUrl: './flow-definition.component.html',
  styleUrls: ['./flow-definition.component.less'],
})
export class FlowDefinitionComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FlowDefinitionComponent>,
    private notifyService: NotificationService,
    private _router: Router,
    private _flowService: FlowService,
    private _flowApiService: FlowApiService,
    private _sensitiveInfoService: SensitiveInfoService,
    private _usersApiService: UsersApiService
  ) {}

  public flowDetails = {
    flow_name: null,
    s3_location: null,
    data_source: null,
    therapy_area: null,
  };
  public collaborators = [];
  public users: any = [];
  public collaboratorsUsersListChanged: boolean = false;
  public hasUsersLoaded: boolean = false;
  public flowId: number;
  public isEditFlow: boolean;
  public userRoles = USER_ROLES;
  public disabled_username = '';

  ngOnInit() {
    this.getFlowMetaData();
    this.getLoggedInUserDetails();
    this.isEditFlow = this.flowId && this.flowId !== null;
  }

  getFlowMetaData() {
    this.flowId = this.data.flowId;
    this.hasUsersLoaded = this.data.hasUsersLoaded;
    this.flowDetails = this.data.flowDetails;
    this.users = this.data.users;
    this.collaborators = this.data.collaborators;
    this.collaboratorsUsersListChanged = this.data.collaboratorsUsersListChanged;
  }

  getLoggedInUserDetails() {
    this._usersApiService.getUserSession().subscribe((response: any) => {
      this.disabled_username = response.username;
    });
  }
  onChangeCollaborators(list = []) {
    this.collaborators = [...list];
    this.collaboratorsUsersListChanged = !this.collaboratorsUsersListChanged;
    this.hasUsersLoaded = true;
  }

  closeFlowDefinition(): void {
    this.dialogRef.close({ collaborators: this.collaborators, flowDetails: this.flowDetails });
  }

  public save = () => {
    if (this.isEditFlow) {
      this.updateFlowDetails();
    } else {
      this.saveFlowDetails();
    }
    this.closeFlowDefinition();
  };

  updateFlowDetails = () => {
    const flowDetails = {
      ...this.flowDetails,
      viewers: this._sensitiveInfoService.encrypt([]),
      collaborators: this._sensitiveInfoService.encrypt(this.collaborators),
    };
    this._flowApiService.updateFlow(flowDetails, this.flowId).subscribe(
      (response: any) => {
        if (response.status) {
          this._flowService.setFlowId(this.flowId);
          this.notifyService.showSuccess(response['message'], 'Notification');
        }
      },
      (err) => {
        this.notifyService.showError(err.error.message, 'Error');
      }
    );
  };

  saveFlowDetails = () => {
    const flowDetails = {
      ...this.flowDetails,
      viewers: this._sensitiveInfoService.encrypt([]),
      collaborators: this._sensitiveInfoService.encrypt(this.collaborators),
    };

    this._flowApiService.createFlow(flowDetails).subscribe(
      (response: any) => {
        if (response['flow_id']) {
          this.flowId = response['flow_id'];
          this._flowService.setFlowId(this.flowId);
          this.notifyService.showSuccess(response['message'], 'Notification');
          this._router.navigate([`/studies/${this.flowId}/edit/`]);
        }
      },
      (err) => {
        this.notifyService.showError(err.error.message, 'Error');
      }
    );
  };
}
