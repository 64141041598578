import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudiesComponent } from './studies/studies.component';
import { CreateflowComponent } from './createflow/createflow.component';

const routes: Routes = [
  {
    path: 'studies',
    children: [
      { path: '', component: StudiesComponent },
      { path: 'create', component: CreateflowComponent },
      { path: ':flowId/edit', component: CreateflowComponent },
    ],
  },
  { path: '**', redirectTo: '/studies', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
