import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FeatureModuleService {
  public featureModuleDetails: object = {};
  private featureModuleSubject = new Subject<any>();
  featureModuleState = this.featureModuleSubject.asObservable();

  constructor() {}

  saveFeatureDetails(uuid: string, details: object) {
    if (uuid && details) {
      this.featureModuleDetails[uuid] = details;
      this.featureModuleSubject.next({
        featureModuleName: this.featureModuleDetails[uuid].name,
        featureModuleUuid: uuid,
      } as any);
    }
  }

  initFeatureDetails(featureModuleDetails: {}) {
    this.featureModuleDetails = featureModuleDetails;
  }

  getFeatureDetails(uuid: string = null) {
    if (uuid) {
      return this.featureModuleDetails[uuid];
    }
    return this.featureModuleDetails;
  }

  removeNode(nodeName: string) {
    delete this.featureModuleDetails[nodeName];
  }
}
