import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';

@Injectable({
  providedIn: 'root',
})

// tslint:disable variable-name
export class FlowApiService {
  constructor(private _httpService: HttpWrapperService) {}

  public createFlow(payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/`, payload);
  }

  public getFlowDetails(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}`);
  }

  public getFlowExports(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/exports/`);
  }

  public getActionsList(): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/action/`);
  }

  validateBreakpoints(flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/validate-breakpoints/`, payload);
  }

  getFlowActionsForFlow(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/action/`);
  }

  saveFlowActionsForFlow(flowid, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowid}/action/`, payload);
  }

  downloadTemplates(templateName): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/template/download?template_name=${templateName}`);
  }

  uploadMappings(mappingName, flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/mapping/${flowId}/upload/${mappingName}/`, payload);
  }

  saveConfig(payload, flowId): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/config/`, payload);
  }

  getMappings(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/mapping/${flowId}/status/`);
  }

  getFlowActionConfigDetails(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/config/`);
  }

  uploadParameter(parameterName, flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/parameter/${flowId}/${parameterName}/`, payload);
  }

  getParameterOutputFormatCSV(parameterName): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/parameter/download?parameter_name=${parameterName}`);
  }

  deleteFlow(flowId): Observable<Response> {
    return this._httpService.delete(`${environment.api_url}/flow/${flowId}/`);
  }

  executeFlow(payload, flowId): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/execute/`, payload);
  }

  saveExportDetails(flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/execute/`, payload);
  }

  updateFlow(payload, flowId): Observable<Response> {
    return this._httpService.put(`${environment.api_url}/flow/${flowId}/`, payload);
  }

  getUserRole(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/user-role/`);
  }

  getS3Tables(payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/s3/tables/`, payload);
  }

  getFlowArchiveDetails(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/archives/`);
  }

  deleteFlowArchive(flowId, payload) {
    return this._httpService.patch(`${environment.api_url}/flow/${flowId}/archives/`, payload);
  }

  getStudiesFromPatientJourny(): Observable<Response> {
    return this._httpService.get(`${environment.patientJournyApiUrl}/studies/`);
  }

  getFlowExecutionStatus(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/execution-log/`);
  }

  reExecuteTheFlow(flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/re-execute/`, payload);
  }

  getOutputPath(flowId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/flow/${flowId}/output-location/`);
  }

  getJobLog(flowId, payload) {
    return this._httpService.post(`${environment.api_url}/jobs/${flowId}/log/`, payload);
  }

  terminate_the_flow(flowId, payload) {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/terminate-flow/`, payload);
  }

  schedule_flow(flowId, payload) {
    return this._httpService.post(`${environment.api_url}/schedule/${flowId}/`, payload);
  }

  delete_event(flowId) {
    return this._httpService.delete(`${environment.api_url}/schedule/${flowId}/remove-event/`);
  }

  reschedule_flow(flowId, payload) {
    return this._httpService.post(`${environment.api_url}/schedule/${flowId}/reschedule/`, payload);
  }

  get_schedule_details(flowId) {
    return this._httpService.get(`${environment.api_url}/schedule/${flowId}/`);
  }

  cloneStudy(flowId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/flow/${flowId}/clone/`, payload);
  }

  saveBGHDetails(flow_Id, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/bgh/${flow_Id}/`, payload);
  }

  getBGHDetails(flow_Id): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/bgh/${flow_Id}/`);
  }

  getAMCDetails(): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/bgh/amc/`);
  }

  getBGHVisualizations(flow_Id): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/bgh/${flow_Id}/visualization/`);
  }

  getRegion() {
    return this._httpService.get(`${environment.homeUrl}/fetch_region`);
  }

  getVisualizationKPIDetails() {
    return this._httpService.get(`${environment.patientJournyApiUrl}/visualizations/0/kpi`);
  }

  getProjectList() {
    return this._httpService.get(`${environment.homeUrl}/projects`);
    // return this._httpService.get(`${environment.dataExplorerUrl}/projects_list`);
  }

  getFlowLockStatus(flowId) {
    return this._httpService.get(`${environment.api_url}/activity/${flowId}/status/`);
  }

  updateActivity(flowId, payload) {
    return this._httpService.post(`${environment.api_url}/activity/${flowId}/status/`, payload);
  }

  getActionDefinition(flowId, uuid) {
    return this._httpService.get(`${environment.api_url}/orchestration/${flowId}/definition/?uuid=${uuid}`);
  }

  downloadFile(flowId, uuid, type) {
    return this._httpService.get(`${environment.api_url}/orchestration/${flowId}/download_python_component_files?uuid=${uuid}&file_type=${type}`);
  }

  saveActionDefinition(flowId, uuid, payload) {
    return this._httpService.post(`${environment.api_url}/orchestration/${flowId}/definition/?uuid=${uuid}`, payload);
  }
}
