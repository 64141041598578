import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  @Input() routeIsCreateFlow = true;
  constructor() {}

  ngOnInit() {}

  public getBackRoute = () => {};
  public onSave = () => {};
}
