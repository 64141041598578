import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { FlowService } from '../_services/flow.service';

// tslint:disable: directive-selector
// tslint:disable: variable-name
@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements AfterViewInit, OnDestroy {
  private roles = [];
  private currentRole;
  @Input() set disableControl(roles: []) {
    this.roles = roles;
  }

  constructor(private eleRef: ElementRef, private _renderer: Renderer2, private _flowService: FlowService) {}

  ngAfterViewInit() {
    this.currentRole = this._flowService.getUserRole();
    const isDisabled = this.eleRef.nativeElement.getAttribute('disabled');
    if (!isDisabled && this.roles.includes(this.currentRole)) {
      this._renderer.setAttribute(this.eleRef.nativeElement, 'disabled', 'true');
    }
  }

  ngOnDestroy() {
    this._renderer.removeAttribute(this.eleRef.nativeElement, 'disabled');
  }
}
