import { Component, OnInit, ViewEncapsulation, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { file, INPUT_VALIDATION, insightsMessage, common } from 'src/app/app.messages';
import { FlowService } from 'src/app/_services/flow.service';
import {
  ACTION_DEFINATION_CONFIG,
  AlignName,
  DIMENSION_MAPPING_NAMES,
  INCLUDED_FILTER_TYPE,
  MAXIMUM_CSV_FILE_SIZE,
  FILE_TYPES,
  BGH_STUDY_DESCRIPTION,
  PATTERNS
} from '../../app.constants';
import { CohortsApiService } from '../../_services/api/cohort.api.service';
import { saveAs } from 'file-saver';
import { FlowApiService } from 'src/app/_services/api/flow.api.service';
import { StudiesApiService } from 'src/app/_services/api/studies.api.service';
import { NotificationService } from '../../_services/notification.service';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';
import { CommonService } from 'src/app/_services/common.service';
import { CustomQueryService } from 'src/app/_services/custom-query.service';
import { PythonModuleService } from 'src/app/_services/python-module.service';
import { InvokeFlowService } from 'src/app/_services/invoke-flow.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FeatureCatalogApiService } from 'src/app/_services/api/feaure.api.service';
import { FeatureModuleService } from 'src/app/_services/feature-catalogue-module.service';
import { DataTransferService } from 'src/app/_services/data-transfer.service';

@Component({
  selector: 'app-modal-action-definition',
  templateUrl: './modal-action-definition.component.html',
  styleUrls: ['./modal-action-definition.component.less'],
  encapsulation: ViewEncapsulation.None,
})

// tslint:disable: variable-name
// tslint:disable: deprecation
// tslint:disable: no-string-literal
export class ModalActionDefinitionComponent implements OnInit {
  @Input() mappingDetailsOnInitComponenet;
  public actionName: string;
  public actionUUID: string;
  public flowId;
  public fileUrl;
  public mapping_details = {};
  public conceptList = [];
  public cohortList = [];
  selectedConcepts = [];
  selectedCohorts = [];
  selectedMGBCohort = [];
  filteredCohorts = [];
  isConceptListChanged = false;
  isCohortListChanged = false;
  disabled = true;

  public flowStatus = '';

  // bookmark : here are the lists that the zs-select takes items from
  public myFeatures = [];
  public masterFeatures = [];

  public allStudies = [];
  public allFlows = [];
  selectedMyFeatures = [];
  selectedMasterFeatures = [];
  public selectedUserStudies = [];
  public selectedUserFlows = null;
  public selectedAlertType = 1;
  public selectedTenants = ['1', '2'];
  public selectedConceptSets = [];
  public selectedStudyType = 1;
  public hasAMCChanged = false;

  public bgh_study_description = BGH_STUDY_DESCRIPTION;
  public selectedBGHCriteria = [];

  public bghHadSurgery = null;
  public bghHadMRI = null;
  public bghShowedMetastases = null;
  public bghHasPathologyResult = null;
  public bghHasGenomeData = null;
  public bghHasEGFRGeneMutation = null;

  isMyFeaturesChanged = false;
  isMasterFeaturesChanged = false;
  isUserStudyChanged = false;
  isUserFlowChanged = false;
  isAlertTypeChanged = false;
  isStudyTypeChanged = false;
  isLotOnEpisodes = false;

  public aggregatorLocation = '';

  public pythonFileDetail = {
    python_file: {
      file_name: '',
      file_data: '',
      file_type: 'python',
    },
    requirements_file: {
      file_name: '',
      file_data: '',
    },
    name: 'Python Module',
  };

  public fileType = 'python';
  // date range
  public minFromDate: any = '';
  public maxToDate: any = '';
  public fromDate: any = '';
  public toDate: any = '';
  public toDateEvent: string[] = [];
  public fromDateEvent: string[] = [];
  public today = new Date();
  public currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  public fileMessage = file;
  public errorMessage = '';
  public successMessage = '';
  public isActionDefinitionDataLoaded = true;

  public s3FileFormats = ['Parquet', 'CSV'];
  public selectedFileFormat = 'Parquet';
  public areS3TablesLoaded = false;
  public s3DataLocation: string;
  public csvDemlimiter: string;
  public s3Tables = [];
  public selectedS3Tables = [];
  public s3TextFilePath: string;
  public selectedTableNames = [];
  public customQuery;
  public customQueryName = 'Custom Query';
  public pythonModuleName = 'Python Module';
  public dataTransferModuleName = 'Data Transfer';
  public invokeFlowModuleName = 'Invoke Flow';
  public invokeFlowExportName;
  public invokeFlow;
  public insightList = [];
  public selectedInsightsKPI = [];
  public insightsCustomInput = {
    isCustomInput: false,
    customInput: [
      {
        name: 'cohort',
        prompt: 'Cohort S3 Path',
        s3_path: ''
      },
      {
        name: 'lot',
        prompt: 'LoT S3 Path',
        s3_path: ''
      },
      {
        name: 'episode_data',
        prompt: 'Episode Data S3 Path',
        s3_path: ''
      },
      {
        name: 'episode_map',
        prompt: 'Episode Map S3 Path',
        s3_path: ''
      },
      {
        name: 'gap_values',
        prompt: 'Gap Values S3 Path',
        s3_path: ''
      }
    ]
  };
  public s3Regex = new RegExp(PATTERNS.S3_URI)


  public dimensionMappingNames = DIMENSION_MAPPING_NAMES;
  public actionDefinsationConfiguration = ACTION_DEFINATION_CONFIG;

  public CustomQueryDisabledValue = false;
  public s3TextFilePathDisabledValue = false;
  public showPopover = false;

  public createLineOfTherapyValue = 1;
  public actionErrorIconConfig;
  public includedFilterTypeValue = [];
  public includedFilterType = INCLUDED_FILTER_TYPE;
  private downloadTemplateNames = {
    'Dimension Mapping': 'dimension-mapping',
    'Days of Supply Imputation': 'dos-config',
    'Grace Period Analysis': 'grace-config',
  };

  public alertEvents = [
    {
      alert_type: 'Line Progression',
      id: 1,
    },
    {
      alert_type: 'Event Occurrence',
      id: 2,
    },
  ];

  public insights = [
    {
      id: 1,
      name: 'Patient Funnel',
    },
    {
      id: 2,
      name: 'Cross Study Analytics',
    },
  ];

  public selectedInsights = [1, 2];

  public tenants = [];

  public studyType = [
    {
      study_type: 'Patient Finder',
      id: 1,
    },
    {
      study_type: 'Patient Cohort',
      id: 2,
    },
  ];

  dataTransferDetail = {
    name: 'Data Transfer',
    all_s3_tables: [],
    selected_tables: [],
    source_s3_location: '',
    s3_tables: [],
    target_s3_location: '',
  };

  alertDetail = {
    alert_type: 1,
    line_number: null,
    lookback_days: null,
    event_occurrence_type: null,
    event_lookback_days: null,
    event_type: null,
  };

  public dataTransferDetails: Array<any> = [JSON.parse(JSON.stringify(this.dataTransferDetail))];
  public alertDetails = { lot_path: '', data: [JSON.parse(JSON.stringify(this.alertDetail))] };

  actionDefinitionData = {
    days_of_supply: 28,
    grace_period: 30,
    regimen_drop_length: null,
    lot_rule: null,
    grace_period_s3_path: null,
    dos_imputation_s3_path: null,
    kpi_timeframe_end: null,
    kpi_timeframe_start: null,
    concepts: [],
    cohorts: [],
    study_id: null,
    claim_types: [],
  };

  studiesFCList = [];
  selectedFCStudies = [];
  selectionTypesList = ['features', 'study'];
  selectionType = this.selectionTypesList[0];

  constructor(
    private _cohortsApiService: CohortsApiService,
    private _flowService: FlowService,
    private _flowApiService: FlowApiService,
    private _studiesApiService: StudiesApiService,
    public dialogRef: MatDialogRef<ModalActionDefinitionComponent>,
    private notifyService: NotificationService,
    private _customQueryService: CustomQueryService,
    private _pythonModuleService: PythonModuleService,
    private _dataTransferService: DataTransferService,
    private _invokeFlowService: InvokeFlowService,
    private clipboardService: ClipboardService,
    public datePipe: DatePipe,
    public _commonService: CommonService,
    public _featureCatalogAPIService: FeatureCatalogApiService,
    public _featureCatalogueService: FeatureModuleService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  getFilePath(fileval) {
    if (fileval) {
      this.CustomQueryDisabledValue = true;
    }
  }

  getCustomQuery(queryval) {
    if (queryval) {
      this.s3TextFilePathDisabledValue = true;
    }
  }

  ngOnInit() {
    this.flowId = this._flowService.getFlowId();
    this.selectActionDefinition();
    this.actionErrorIconConfig = this._commonService.hideIconActionDefination;
    this.flowStatus = this._flowService.getFlowStatus();
  }

  parseDate(date) {
    return new FormControl(new Date(date * 1000));
  }

  getMappingsDetails() {
    this._flowApiService.getMappings(this.flowId).subscribe((response: any) => {
      const mappingDetailsResponse = response.mapping_list;
      for (const elm of this.dimensionMappingNames) {
        const el = elm.replace(/\s/g, '_').toLowerCase();
        this.mapping_details[elm] = mappingDetailsResponse[el];
        if (mappingDetailsResponse[el]) {
          this.actionErrorIconConfig['Dimension Mapping'].isErrorIconShow = false;
        }
      }
      this.getConfigDetails();
    });
  }

  getParameterCsv(parameter_name) {
    const parameter_file_name: string = this.actionDefinsationConfiguration[parameter_name].csvFileName;
    this._flowApiService.getParameterOutputFormatCSV(parameter_file_name).subscribe((response: any) => {
      if (response.status) {
        const blob = this.base64ToBlob(response.data, 'text/csv');
        saveAs(blob, `${parameter_file_name.replace(/-/g, '_')}.csv`);
      }
    });
    this.closeModal();
  }

  showToaster(message) {
    this.notifyService.showSuccess(message, 'Notification');
  }

  showErrorToaster(message) {
    this.notifyService.showError(message, 'Error');
  }

  changeFromDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minFromDate = new FormControl(new Date(event.value));
    this.fromDateEvent.push(`${type}: ${event.value}`);
    this.fromDate = new FormControl(new Date(event.value));
  }
  changeToDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.maxToDate = new FormControl(new Date(event.value));
    this.toDateEvent.push(`${type}: ${event.value}`);
    this.toDate = new FormControl(new Date(event.value));
  }

  addNewCopyPastePair() {
    this.dataTransferDetails.push(JSON.parse(JSON.stringify(this.dataTransferDetail)));
  }

  removeCopyPastePair(i) {
    this.dataTransferDetails.splice(i, 1);
    if (this.dataTransferDetails.length <= 0) {
      this.dataTransferDetails.push(JSON.parse(JSON.stringify(this.dataTransferDetail)));
    }
  }

  getConfigDetails() {
    this.isActionDefinitionDataLoaded = false;
    this.includedFilterTypeValue = [];
    this._flowApiService.getFlowActionConfigDetails(this.flowId).subscribe((response: any) => {
      const configResponse: object = response.config;
      Object.assign(this.actionDefinitionData, response.config);
      Object.keys(configResponse).forEach((key) => {
        if (configResponse[key] && (key === 'grace_period_s3_path' || key === 'dos_imputation_s3_path')) {
          this.actionDefinitionData[key] = configResponse[key].split('/').pop();
          if (key === 'dos_imputation_s3_path' && configResponse[key] != null) {
            this.actionErrorIconConfig[AlignName.days_of_supply].isErrorIconShow = false;
          }
          if (key === 'grace_period_s3_path' && configResponse[key] != null) {
            this.actionErrorIconConfig[AlignName.grace_period].isErrorIconShow = false;
          }
        }
        //this.actionErrorIconConfig[AlignName.dimension_mapping].isErrorIconShow = false;
        else if (
          !['days_of_supply', 'grace_period'].includes(key) &&
          configResponse[key] != null &&
          this.actionErrorIconConfig.hasOwnProperty(AlignName[key])
        ) {
          this.actionErrorIconConfig[AlignName[key]].isErrorIconShow = false;
        }
        if (key == 'is_lot_on_episodes') {
          this.isLotOnEpisodes = configResponse[key];
        }
        if (key == 'claim_types') {
          this.includedFilterTypeValue = configResponse[key] || [];
        }
        if (key == 'study_id') {
          this.isUserStudyChanged = true;
          this.selectedUserStudies =
            configResponse[key] != null || configResponse[key] != undefined
              ? configResponse[key]
              : this.allStudies.length
              ? [this.allStudies[0]['study_id']]
              : [];
        }
        if (key == 'alert_details') {
          if ((configResponse[key] != null || configResponse[key] != undefined) && configResponse[key]['data'].length > 0) {
            this.alertDetails = configResponse[key];
            this.actionErrorIconConfig[AlignName[key]].isErrorIconShow = false;
          }
        }
        if (key == 'insights') {
          this.selectedInsightsKPI = configResponse[key] ? configResponse[key] : [];
        }
        if (key == 'insights_config' && configResponse[key] != null && configResponse[key] != undefined) {
          this.insightsCustomInput = configResponse[key];
        } 
      });
      if (this.actionDefinitionData.kpi_timeframe_start && this.actionDefinitionData.kpi_timeframe_end) {
        this.fromDate = this.parseDate(this.actionDefinitionData.kpi_timeframe_start);
        this.toDate = this.parseDate(this.actionDefinitionData.kpi_timeframe_end);
      }
      this.isActionDefinitionDataLoaded = true;
    });
  }

  getConcepts() {
    this._cohortsApiService.getConcepts().subscribe((conceptResponse: any) => {
      this.conceptList = conceptResponse;

      this._cohortsApiService.getCohorts().subscribe((cohortResponse: any) => {
        this.cohortList = cohortResponse;
        this.filteredCohorts = cohortResponse;
        this._flowApiService.getFlowActionConfigDetails(this.flowId).subscribe((response: any) => {
          this.onChangeConcept(response.config.concepts || []);
          this.onChangeCohort(response.config.cohorts || []);
          this.isActionDefinitionDataLoaded = true;
        });
      });
    });
  }

  populateBGHData(criteria) {
    this.isAlertTypeChanged = true;
    Object.keys(criteria).forEach((ele) => {
      if (ele == 'TO_AGE') {
        this.bgh_study_description[2].criteria.age_to = criteria[ele];
        this.selectedBGHCriteria.push(3);
      }
      if (ele == 'FROM_AGE') {
        this.bgh_study_description[2].criteria.age_from = criteria[ele];
        this.selectedBGHCriteria.push(3);
      }
      if (ele == 'HAD_SURGERY') {
        this.bgh_study_description[3].criteria.had_surgery = criteria[ele].toString();
        this.selectedBGHCriteria.push(4);
      }
      if (ele == 'HAS_BRAIN_MRI') {
        this.bgh_study_description[4].criteria.had_mri = criteria[ele].toString();
        this.selectedBGHCriteria.push(5);
      }
      if (ele == 'HAS_GENOMIC_DATA') {
        this.bgh_study_description[0].criteria.has_genome_data = criteria[ele].toString();
        this.selectedBGHCriteria.push(1);
      }
      if (ele == 'HAS_PATHOLOGY_RESULTS') {
        this.bgh_study_description[1].criteria.has_pathology_result = criteria[ele].toString();
        this.selectedBGHCriteria.push(2);
      }
      if (ele == 'HAS_METASTASES') {
        this.bgh_study_description[4].criteria.showed_metastases = criteria[ele].toString();
        this.selectedBGHCriteria.push(5);
      }
      if (ele == 'HAS_EGFR_GENE_MUTATION') {
        this.bgh_study_description[0].criteria.has_egfr_gene_mutation = criteria[ele].toString();
        this.selectedBGHCriteria.push(1);
      }
    });
  }

  getBGHDetails(get_cohorts: boolean = false) {
    this._flowApiService.getBGHDetails(this.flowId).subscribe((res: any) => {
      var bghDetails = res['data'];
      this.selectedTenants = bghDetails && bghDetails.selected_amcs ? bghDetails.selected_amcs : [];
      this.hasAMCChanged = true;
      const criteria = bghDetails && bghDetails['selected_criterias'] ? bghDetails['selected_criterias'] : {};
      this.populateBGHData(criteria);
      this.selectedMGBCohort = bghDetails && bghDetails['cohort_id'] ? bghDetails['cohort_id'] : [];
      if (get_cohorts) {
        this._cohortsApiService.getCohorts().subscribe((cohortResponse: any) => {
          this.cohortList = cohortResponse;
          this.filteredCohorts = cohortResponse;
        });
      }
    });
  }

  getAMCDetails() {
    this._flowApiService.getAMCDetails().subscribe((res: any) => {
      this.tenants = res['data'].sort((a, b) => (a.amc_name > b.amc_name ? 1 : b.amc_name > a.amc_name ? -1 : 0));
    });
  }

  getSelectedCriteria() {
    var res = {};
    this.bgh_study_description.forEach((element) => {
      if (this.selectedBGHCriteria.includes(element.id)) {
        switch (element.name) {
          case 'Genome Data':
            if (element.criteria.has_genome_data != null) {
              res['HAS_GENOMIC_DATA'] = +element.criteria.has_genome_data;
            }
            if (element.criteria.has_egfr_gene_mutation != null && +element.criteria.has_genome_data) {
              res['HAS_EGFR_GENE_MUTATION'] = +element.criteria.has_egfr_gene_mutation;
            }
            break;

          case 'Pathology Result':
            if (element.criteria.has_pathology_result != null) {
              res['HAS_PATHOLOGY_RESULTS'] = +element.criteria.has_pathology_result;
            }
            break;

          case 'Age':
            if (element.criteria.age_from != null) {
              res['FROM_AGE'] = +element.criteria.age_from;
            }
            if (element.criteria.age_to != null) {
              res['TO_AGE'] = +element.criteria.age_to;
            }
            break;

          case 'Surgery':
            if (element.criteria.had_surgery != null) {
              res['HAD_SURGERY'] = +element.criteria.had_surgery;
            }
            break;

          case 'Brain MRI':
            if (element.criteria.had_mri != null) {
              res['HAS_BRAIN_MRI'] = +element.criteria.had_mri;
            }
            if (element.criteria.showed_metastases != null && +element.criteria.had_mri) {
              res['HAS_METASTASES'] = +element.criteria.showed_metastases;
            }
            break;

          default:
            break;
        }
      }
    });
    return res;
  }

  public onUplodPopup($event, id) {
    $event.cancelBubble = true;
    document.getElementById(id).click();
  }

  public downloadFile($event, type) {
    $event.cancelBubble = true;
    this._flowApiService.downloadFile(this.flowId, this.actionUUID, type).subscribe((response:any) => {
      console.log(response)
      if (response.status) {
      window.open(response.data);
      }
    });
  }

  fileToByteArray(_file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        const fileByteArray = [];
        reader.readAsArrayBuffer(_file);
        reader.onloadend = (evt: Event) => {
          if (reader.readyState === FileReader.DONE) {
            const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
            const array = new Uint8Array(arrayBuffer);
            array.forEach((byte) => fileByteArray.push(byte));
          }
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  uploadFile(event, action_name, action_type = null) {
    event.cancelBubble = true;
    try {
      if (!this.validateFileSize(event)) {
        this.actionDefinitionData.dos_imputation_s3_path = null;
        this.actionDefinitionData.grace_period_s3_path = null;
        return;
      }
      const _file: File = event.target.files[0];
      const fileName = _file.name;
      this.changeFile(_file).then((base64: string): any => {
        const base64File = base64.toString().split(',')[1];
        if (action_name === 'Python Module' && this.validateFileType(event, FILE_TYPES.PYTHON)) {
          this.pythonFileDetail[action_type] = {
            file_name: fileName,
            file_data: base64File,
          };
        } else if (!(action_name === 'Python Module') && this.validateFileType(event, FILE_TYPES.CSV)){
          const payload = {
            data: base64File,
          };
          action_type === 'mapping' ? this.uploadMapping(action_name, payload) : this.uploadParameter(action_name, payload);
        }
      });
    } catch (ex) {
      this.errorMessage = this.fileMessage.corruptedFile;
    }
  }

  uploadParameter(parameter_name: string, payload: object) {
    if (parameter_name === 'days-of-supply-config') {
      this.actionDefinitionData.days_of_supply = null;
    }
    if (parameter_name === 'grace-period-config') {
      this.actionDefinitionData.grace_period = null;
    }
    this._flowApiService.uploadParameter(parameter_name, this.flowId, payload).subscribe(
      (res: any) => {
        if (res.status) {
          this.successMessage = res.message;
          this.showToaster(this.successMessage);
        }
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.showErrorToaster(this.errorMessage);
        if (parameter_name === 'days-of-supply-config') {
          this.actionDefinitionData.dos_imputation_s3_path = null;
        }
        if (parameter_name === 'grace-period-config') {
          this.actionDefinitionData.grace_period_s3_path = null;
        }
      }
    );
  }

  uploadMapping(originalMappingName, payload) {
    const mappingName = originalMappingName.toLowerCase().replace(/\s/g, '-');
    this._flowApiService.uploadMappings(mappingName, this.flowId, payload).subscribe(
      (res: any) => {
        if (res.status) {
          this.mapping_details[originalMappingName] = true;
          this.showToaster(res.message);
          this.actionErrorIconConfig['Dimension Mapping'].isErrorIconShow = false;
        }
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.showErrorToaster(this.errorMessage);
      }
    );
  }

  changeFile(_file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  validateFileType(event, file_type) {
    if (!(file_type.includes(event.target.files[0].type) || file_type.includes(event.target.files[0].name.split('.').pop()))) {
      this.showErrorToaster(this.fileMessage.incorrectFile);
      return false;
    }
    return true;
  }

  validateFileSize(event) {
    if (event.target.files[0].size > MAXIMUM_CSV_FILE_SIZE || event.target.files[0].size === 0) {
      this.showErrorToaster(event.target.files[0].size === 0 ? this.fileMessage.corruptedFile : this.fileMessage.exceedSize);
      event.target.value = '';
      return false;
    }
    return true;
  }

  actionFunction() {
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  getPythonFileDetails() {
    this._flowApiService.getActionDefinition(this.flowId, this.actionUUID).subscribe((response:any) => {
      let pythonFileDetail = null;
      if (response.status) {
        pythonFileDetail = response[this.actionUUID];
        Object.assign(this.pythonFileDetail, pythonFileDetail);
        this.isActionDefinitionDataLoaded = true;
        this.fileType = this.pythonFileDetail.python_file.file_type;
      }
    });
  }

  getCustomQueryDetails() {
    this._flowApiService.getActionDefinition(this.flowId, this.actionUUID).subscribe((response:any) => {
      let customQueryDefinition = null
      if (response.status) {
        customQueryDefinition = response[this.actionUUID];
      }
      this.customQueryName = customQueryDefinition ? customQueryDefinition.name : 'Custom Query';
      this.customQuery = customQueryDefinition ? customQueryDefinition.query : '';
      this.selectedS3Tables = [...(customQueryDefinition ? customQueryDefinition.s3_tables : [])];
      this.s3TextFilePath =
        customQueryDefinition && customQueryDefinition.s3_sql_txt_file_path != 'None' ? customQueryDefinition.s3_sql_txt_file_path : '';
      this.isActionDefinitionDataLoaded = true;
    });
  }

  getDataTransferDetails() {
    this._flowApiService.getActionDefinition(this.flowId, this.actionUUID).subscribe((response:any) => {
      let dataTransferDefinition = null
      if (response.status) {
        dataTransferDefinition = response[this.actionUUID];
      }
      this.dataTransferModuleName = dataTransferDefinition ? dataTransferDefinition[0].name : 'Data Transfer';
      if (dataTransferDefinition) {
        this.dataTransferDetails = JSON.parse(JSON.stringify(dataTransferDefinition));
      }
      this.dataTransferDetails.forEach((detail) => {
        detail['selected_tables'] = detail.s3_tables.map((x) => x.source_s3_location);
      });
    });
  }

  getInvokeFlowDetails() {
    this._flowApiService.getActionDefinition(this.flowId, this.actionUUID).subscribe((response:any) => {
      let invokeFlowDefinition = null
      if (response.status) {
        invokeFlowDefinition = response[this.actionUUID];
      }
      this.invokeFlowModuleName = invokeFlowDefinition ? invokeFlowDefinition.name : 'Invoke Flow';
      this.selectedUserFlows = invokeFlowDefinition ? invokeFlowDefinition.flow_id : '';
      this.selectedUserFlows = Number(this.selectedUserFlows);
      this.invokeFlowExportName = invokeFlowDefinition ? invokeFlowDefinition.export_name : null;

      this._studiesApiService.getStudies().subscribe((response: any) => {
        if (response) {
          this.getInvokeFlowResponse(response);
        }
      });
    });
  }

  getInvokeFlowResponse(response) {
    this.allFlows = [...response['user_studies'], ...response['team_studies']].filter((obj) => obj.flow_id !== this.flowId);
    this.isActionDefinitionDataLoaded = true;
  }

  getFeatureEngineeringDetails() {
    // Bookmark : this function gets called for getting the saved details back from backend
    // this condition needs to be true in order to load the contents of the modal
    this._featureCatalogAPIService.getFeatureList().subscribe((response: any) => {
      if (response) {
        this.getFeatureResponse(response);
      }
    });

    this._featureCatalogAPIService.getFCStudiesList().subscribe((response: any) => {
      if (response) {
        this.studiesFCList = [...response.team_studies, ...response.user_studies];
      }
    });
    this.isActionDefinitionDataLoaded = true;
  }

  getFeatureResponse(response) {
    this.myFeatures = response['data']['user_features'];
    this.masterFeatures = response['data']['library_features'];
    this.actionErrorIconConfig['Feature Catalogue']['isErrorIconShow'] = false;
    this.getSelectedFeature();
  }

  getSelectedFeature() {
    const feat = this._featureCatalogueService.getFeatureDetails();
    if (feat) {
      if (feat.hasOwnProperty(this.actionUUID)) {
        const nodeDetails = feat[this.actionUUID];
        this.selectionType = nodeDetails.selection_type;
        if (this.selectionTypesList[0] === nodeDetails.selection_type) {
          const selectedFeatureids = nodeDetails.hasOwnProperty('feature_id') ? nodeDetails['feature_id'] : [];
          this.selectedMasterFeatures = this.masterFeatures.filter((x) => selectedFeatureids.includes(x.id));
          this.selectedMasterFeatures = this.selectedMasterFeatures.map((s) => s.id);

          this.selectedMyFeatures = this.myFeatures.filter((x) => selectedFeatureids.includes(x.id));
          this.selectedMyFeatures = this.selectedMyFeatures.map((s) => s.id);
        } else if (this.selectionTypesList[1] === nodeDetails.selection_type) {
          this.selectedFCStudies = nodeDetails.fc_study_ids;
        }
      }
    }
  }

  getRegimenMergeDetails() {
    this._flowApiService.getStudiesFromPatientJourny().subscribe((response: any) => {
      if (response) {
        this.getRegemenMergeResponse(response);
      }
    });
    this.isActionDefinitionDataLoaded = true;
  }

  getRegemenMergeResponse(response) {
    this.allStudies = [...response['user_studies'], ...response['team_studies']];
    this.getConfigDetails();
  }

  getClaimFilterDetails() {
    this.isActionDefinitionDataLoaded = true;
  }

  fetchS3TablesDT(i: number) {
    const s3SourceLocation = this.dataTransferDetails[i].source_s3_location.endsWith('/')
      ? this.dataTransferDetails[i].source_s3_location
      : this.dataTransferDetails[i].source_s3_location + '/';
    const file_types: Array<string> = ['parquet', 'csv'];
    const payload = {
      s3_location: s3SourceLocation,
      file_type: null,
    };
    var areAllTablesPresent = false;
    this.areS3TablesLoaded = false;
    this.dataTransferDetails[i].all_s3_tables = [];

    file_types.forEach((typ) => {
      payload.file_type = typ;
      this._flowApiService.getS3Tables(payload).subscribe(
        (response: any) => {
          response.tables_list.forEach((tables) => {
            this.dataTransferDetails[i].all_s3_tables.push({
              file_type: typ,
              location: `${this.dataTransferDetails[i].source_s3_location}${tables}`,
              table: tables,
            });
          });
          if (areAllTablesPresent) {
            this.areS3TablesLoaded = true;
          } else {
            areAllTablesPresent = true;
          }
        },
        (err) => {
          this.areS3TablesLoaded = true;
        }
      );
    });
  }

  fetchS3Tables() {
    this.s3DataLocation = this.s3DataLocation.endsWith('/') ? this.s3DataLocation : this.s3DataLocation + '/';
    const payload = {
      s3_location: this.s3DataLocation,
      file_type: this.selectedFileFormat.toLowerCase(),
    };

    this.areS3TablesLoaded = false;
    this._flowApiService.getS3Tables(payload).subscribe(
      (response: any) => {
        this.s3Tables = [];
        response.tables_list.sort().forEach((table) => {
          this.s3Tables.push({ location: `${this.s3DataLocation}${table}`, table: table });
        });
        const existingSelectedTableNames = this.selectedS3Tables.filter((x) => x.s3_location.split(x.table_name)[0] == this.s3DataLocation);
        this.selectedTableNames = existingSelectedTableNames.map((x) => x.s3_location);
        this.areS3TablesLoaded = true;
      },
      (err) => {
        this.s3Tables = [];
        this.selectedTableNames = [];
        this.areS3TablesLoaded = true;
      }
    );
  }

  onChangeFileFormat($event) {
    this.selectedFileFormat = $event;
  }

  onDTChangeSelectedTables($event: string[], i) {
    $event = $event || [];
    this.dataTransferDetails[i].selected_tables = $event;
    var selectedTables = JSON.parse(
      JSON.stringify(
        this.dataTransferDetails[i].all_s3_tables.filter((x) => this.dataTransferDetails[i].selected_tables.includes(x.location))
      )
    );
    selectedTables.forEach((ele) => {
      delete Object.assign(ele, { ['source_s3_location']: ele['location'] })['location'];
      delete Object.assign(ele, { ['table_name']: ele['table'] })['table'];
    });
    this.dataTransferDetails[i].s3_tables = selectedTables;
  }

  onChangeSelectedTables($event: string[]) {
    $event = $event || [];
    this.selectedTableNames = $event;
    const selectedTables = this.selectedTableNames.map((x) => x.split('/').pop());

    var selectedTableNames = this.selectedS3Tables;
    const tablesToAdd: string[] = $event.filter((tableName: string) => !selectedTableNames.find((x) => x.s3_location == tableName));
    var tablesToRemove = selectedTableNames.filter(
      (tableName) =>
        (!$event.includes(tableName.s3_location) &&
          this.s3DataLocation == tableName.s3_location.split(tableName.table_name)[0] &&
          this.selectedFileFormat.toLowerCase() == tableName.file_type) ||
        (selectedTables.includes(tableName.table_name) && this.s3DataLocation !== tableName.s3_location.split(tableName.table_name)[0])
    );

    // Map variables to required format
    selectedTableNames = selectedTableNames.map((table) => table.s3_location);
    tablesToRemove = tablesToRemove.map((table) => table.s3_location);

    if (tablesToAdd.length) {
      tablesToAdd.forEach((table) => {
        this.selectedS3Tables.push({
          s3_location: table,
          table_name: table.split('/').pop(),
          file_type: this.selectedFileFormat.toLowerCase(),
          delimiter: this.selectedFileFormat.toLowerCase() === 'csv' ? this.csvDemlimiter : null,
        });
      });
    }

    if (tablesToRemove.length) {
      tablesToRemove.forEach((table) => {
        const foundIndex = this.selectedS3Tables.findIndex((selectedTable) => selectedTable.s3_location === table);

        if (foundIndex !== -1) {
          this.selectedS3Tables.splice(foundIndex, 1);
          this.selectedS3Tables = [...this.selectedS3Tables];
        }
      });
    }

    // Sort selected tables based on table name
    this.selectedS3Tables = this.selectedS3Tables.sort((a, b) => (a.table_name > b.table_name ? 1 : b.table_name > a.table_name ? -1 : 0));
  }

  removeDTSelectedTable(parentIndex: number, index: number) {
    const removedTable = this.dataTransferDetails[parentIndex].s3_tables[index].source_s3_location;
    this.dataTransferDetails[parentIndex].s3_tables.splice(index, 1);
    this.areS3TablesLoaded = false;
    setTimeout(() => {
      const foundIndex = this.dataTransferDetails[parentIndex].selected_tables.findIndex((tableName) => tableName === removedTable);
      if (foundIndex !== -1) {
        this.dataTransferDetails[parentIndex].selected_tables.splice(foundIndex, 1);
        this.dataTransferDetails[parentIndex].selected_tables = [...this.dataTransferDetails[parentIndex].selected_tables];
        this.areS3TablesLoaded = true;
      } else {
        this.areS3TablesLoaded = true;
      }
    }, 0);
  }

  onInsightInputPathChange($event) {
    if ($event.target.checked) {
      this.insightsCustomInput.isCustomInput = true;
    } else {
      this.insightsCustomInput.isCustomInput = false;
    }
  }

  removeSelectedTable(index: number) {
    const removedTable = this.selectedS3Tables[index].table_name;
    this.selectedS3Tables.splice(index, 1);
    this.selectedS3Tables = [...this.selectedS3Tables];
    this.areS3TablesLoaded = false;
    setTimeout(() => {
      const foundIndex = this.selectedTableNames.findIndex((tableName) => tableName === removedTable);
      if (foundIndex !== -1) {
        this.selectedTableNames.splice(foundIndex, 1);
        this.selectedTableNames = [...this.selectedTableNames];
        this.areS3TablesLoaded = true;
      } else {
        this.areS3TablesLoaded = true;
      }
    }, 0);
  }

  isDisabledViewTablesButton() {
    if (!this.s3DataLocation) {
      return true;
    } else {
      if (this.selectedFileFormat === 'CSV' && !this.csvDemlimiter) {
        return true;
      }
    }
    return false;
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFileTemplate($event, actionName) {
    $event.cancelBubble = true;
    this._flowApiService.downloadTemplates(this.downloadTemplateNames[actionName]).subscribe((res: any) => {
      const contentType = actionName === 'Dimension Mapping' ? 'application/zip' : 'text/csv';
      const _file = this.downloadTemplateNames[actionName].replace('-', '_');
      const file_name = actionName === 'Dimension Mapping' ? `${_file}.zip` : `${_file}.csv`;
      if (res.data) {
        const blob = this.base64ToBlob(res.data, contentType);
        saveAs(blob, file_name);
      }
    });
  }

  epochDateFormat(date) {
    return date / 1000;
  }

  submitActionDefinition() {
    // bookmark : here you can create a function which calls api on pressing the submit button
    if (this.actionName === 'Custom Query') {
      const customQueryDefinition = {
        name: this.customQueryName ? this.customQueryName : 'Custom Query',
        query: this.customQuery,
        s3_tables: this.selectedS3Tables,
        s3_sql_txt_file_path: this.s3TextFilePath ? this.s3TextFilePath : '',
      };
      this._customQueryService.saveCustomQueryDetails(this.actionUUID, customQueryDefinition);
      this._flowApiService.saveActionDefinition(this.flowId, this.actionUUID, customQueryDefinition).subscribe((response:any) => {
        if (response.status) {
          this.notifyService.showSuccess(common.detailSaved, 'Success');
        } else {
          this.notifyService.showError(response.message ? response.message : common.detailSaveFailed, 'Error');
        }
      });
      this.closeModal();
    } else if (this.actionName === 'Data Transfer') {
      this.dataTransferDetails.forEach((ele) => {
        ele.name = this.dataTransferModuleName;
        delete ele['selected_tables'];
      });
      this._dataTransferService.saveDataTransferDetails(this.actionUUID, this.dataTransferDetails);
      this._flowApiService.saveActionDefinition(this.flowId, this.actionUUID, this.dataTransferDetails).subscribe((response:any) => {
        if (response.status) {
          this.notifyService.showSuccess(common.detailSaved, 'Success');
        } else {
          this.notifyService.showError(response.message ? response.message : common.detailSaveFailed, 'Error');
        }
      });
      this.closeModal();
    } else if (this.actionName === 'Invoke Flow') {
      const invokeFlowDefinition = {
        name: this.invokeFlowModuleName ? this.invokeFlowModuleName : 'Invoke Flow',
        flow_id: this.selectedUserFlows,
        export_name: this.invokeFlowExportName,
      };
      this._invokeFlowService.saveInvokeFlowDetails(this.actionUUID, invokeFlowDefinition);
      this._flowApiService.saveActionDefinition(this.flowId, this.actionUUID, invokeFlowDefinition).subscribe((response:any) => {
        if (response.status) {
          this.notifyService.showSuccess(common.detailSaved, 'Success');
        } else {
          this.notifyService.showError(response.message ? response.message : common.detailSaveFailed, 'Error');
        }
      });
      this.closeModal();
    } else if (this.actionName === 'Python Module') {
      this.pythonFileDetail.name = this.pythonFileDetail.name ? this.pythonFileDetail.name : 'Python Module';
      this.pythonFileDetail.python_file.file_type = this.fileType;
      this._pythonModuleService.savePythonFiles(this.actionUUID, this.pythonFileDetail);
      this._flowApiService.saveActionDefinition(this.flowId, this.actionUUID, this.pythonFileDetail).subscribe((response:any) => {
        if (response.status) {
          this.notifyService.showSuccess(common.detailSaved, 'Success');
        } else {
          this.notifyService.showError(response.message ? response.message : common.detailSaveFailed, 'Error');
        }
      });
      this.closeModal();
    } else if (this.actionName === 'Feature Catalogue') {
      // TODO Added custom path temparory
      const allFeature = [...this.selectedMyFeatures, ...this.selectedMasterFeatures];
      const allUniqFeatures = [...new Set(allFeature)];
      const feature_payload = {
        s3_location: '',
        feature_id: allUniqFeatures,
        s3_write_location: '',
        is_flow_orchestration: true,
        name: 'Feature Catalogue',
        selection_type: this.selectionType,
        fc_study_ids: this.selectedFCStudies,
      };
      this._featureCatalogueService.saveFeatureDetails(this.actionUUID, feature_payload);
      this.closeModal();
    } else if (this.actionName === 'Distributed Cohort') {
      this.closeModal();

      const payload = { cohort_id: this.selectedMGBCohort };
      this._flowApiService.saveBGHDetails(this.flowId, payload).subscribe(
        (res: any) => {
          if (res['status'] === 'SUCCESS') {
          } else {
            this.showErrorToaster('Something went wrong!');
          }
          this.closeModal();
        },
        (err) => {
          this.showErrorToaster('Something went wrong!');
          this.closeModal();
        }
      );
    } else if (this.actionName === 'Cohort Descriptive Study') {
      const payload = { selected_criterias: this.getSelectedCriteria() };
      this._flowApiService.saveBGHDetails(this.flowId, payload).subscribe(
        (res: any) => {
          if (res['status'] === 'SUCCESS') {
          } else {
            this.showErrorToaster('Something went wrong!');
          }
          this.closeModal();
        },
        (err) => {
          this.showErrorToaster('Something went wrong!');
          this.closeModal();
        }
      );
    } else if (this.actionName === 'Study Executor') {
      var result = this.selectedTenants.map(function (x) {
        return parseInt(x, 10);
      });
      const payload = { selected_amcs: result };
      this._flowApiService.saveBGHDetails(this.flowId, payload).subscribe(
        (res: any) => {
          if (res['status'] === 'SUCCESS') {
          } else {
            this.showErrorToaster('Something went wrong!');
          }
          this.closeModal();
        },
        (err) => {
          this.showErrorToaster('Something went wrong!');
          this.closeModal();
        }
      );
    } else {
      const payload = {};
      const key = this.actionDefinsationConfiguration[this.data.nodeName].payloadName;


      if (key === 'insights') {
        if (this.insightsCustomInput.isCustomInput){
          for (let custInput of this.insightsCustomInput.customInput) {
            if (custInput['s3_path'] === '' || custInput['s3_path'] == null) {
              return this.showErrorToaster(`${custInput['prompt']} should not be empty`);
            }

            else if (!this.s3Regex.test(custInput['s3_path'])) {
                return this.showErrorToaster(`Enter correct ${custInput['prompt']}`);
            }
            else{
              let s3_path = custInput['s3_path'].endsWith('/') ? custInput['s3_path'] : custInput['s3_path'] + '/';
              custInput['s3_path'] = s3_path;
            }

          }

          console.log(this.insightsCustomInput.customInput)
        }

        payload['insights'] = this.selectedInsightsKPI;
        payload['kpi_timeframe_start'] = this.epochDateFormat(this.fromDate.value.getTime()) - (60*this.fromDate.value.getTimezoneOffset());
        payload['kpi_timeframe_end'] = this.epochDateFormat(this.toDate.value.getTime()) - (60*this.fromDate.value.getTimezoneOffset());
        payload['insights_config'] = this.insightsCustomInput;
      } else if (key === 'dimension_mapping') {
        this.showToaster(INPUT_VALIDATION.SUCCESS.DIMENSION_MAPPING);
        this.actionErrorIconConfig[this.data.nodeName].isErrorIconShow = false;
        this.closeModal();
      } else if (key === 'concept & cohort') {
        payload['cohorts'] = this.selectedCohorts;
        payload['concepts'] = this.selectedConcepts;
      } else if (key === 'days_of_supply' || key === 'grace_period') {
        if (this.actionDefinitionData[key] < 0 || this.actionDefinitionData[key] % 1) {
          return this.showErrorToaster(INPUT_VALIDATION.FAILED.INVALID_INPUT.replace('{node_name}', this.actionName));
        }
        payload[key] = this.actionDefinitionData[key];
      } else if (key === 'claim_types') {
        payload['claim_types'] = this.includedFilterTypeValue.length !== 0 ? this.includedFilterTypeValue.sort() : [];
      } else if (key == 'study_id') {
        payload['study_id'] =
          this.actionDefinitionData[key] != null || this.actionDefinitionData[key] != undefined
            ? this.actionDefinitionData[key]
            : this.allStudies[0]['study_id'];
      } else if (key == 'lot_rule') {
        payload['lot_rule'] = this.actionDefinitionData[key];
        payload['is_lot_on_episodes'] = this.isLotOnEpisodes;
      } else if (key == 'alert_details') {
        if (this.alertDetails.lot_path === '') {
          payload['alert_details'] = this.alertDetails;
        } else {
          let lot_regex = new RegExp('^s3://.+/.+/$');
          if (lot_regex.test(this.alertDetails.lot_path)) {
            payload['alert_details'] = this.alertDetails;
          } else {
            return this.showErrorToaster('Enter correct S3 Bucket path');
          }
        }
      } else {
        payload[key] = this.actionDefinitionData[key];
      }
      this.actionErrorIconConfig[this.data.nodeName].isErrorIconShow = false;
      this.saveConfig(payload);
    }
  }

  onInsightsSelection($event) {
    let value = Number($event.target.value)
    if ($event.target.checked && !this.selectedInsightsKPI.includes(value)) {
      this.selectedInsightsKPI.push(value);
    } else if (!$event.target.checked && this.selectedInsightsKPI.includes(value)) {
      this.selectedInsightsKPI.splice(this.selectedInsightsKPI.indexOf(value), 1);
    }
  }

  getVisualizationKPI() {
    this._flowApiService.getVisualizationKPIDetails().subscribe(
      (res: any) => {
        this.insightList = res.all;
        res.all.forEach(ele => {
          let kpi_names = [];
          for (let ele_data of ele.data) {
            kpi_names.push(ele_data.name);
          }
          ele.kpi_names = kpi_names.join(', ');
        });
      },
      (err: any) => {
        console.log(err);
        this.showErrorToaster(insightsMessage.getDataFailed);
      }
    );
    this.getConfigDetails();
  }

  saveConfig(payload) {
    if (JSON.stringify(payload) !== '{}') {
      this._flowApiService.saveConfig(payload, this.flowId).subscribe(
        (res: any) => {
          if (res.status) {
            this.successMessage = res.message;
            this.showToaster(res.message);
            this.closeModal();
          }
        },
        (err) => {
          this.showErrorToaster('Something went wrong!');
          this.closeModal();
        }
      );
    } else {
      this.closeModal();
    }
  }
  onLineOfTherapy(event) {
    this.actionDefinitionData.lot_rule = event.target.value;
    this.createLineOfTherapyValue = event.target.value;
  }

  onChangeCriteria(event) {
    this.bgh_study_description.forEach((ele) => {
      if (ele.name == event.name) {
        ele.criteria[event.attributes.input.nodeValue] = event.value;
      }
    });
  }

  onLineOfTherapyOnEpisodes(event: any) {
    if (event.target.checked) {
      this.isLotOnEpisodes = true;
      this.actionDefinitionData.lot_rule = event.target.value;
      this.createLineOfTherapyValue = event.target.value;
    } else {
      this.isLotOnEpisodes = false;
    }
  }

  trigger($event) {
    const element = document.getElementById('upload_file') as HTMLInputElement;
    element.click();
  }

  onChangeCohort($event = []) {
    if ($event && $event.length) {
      this.selectedCohorts = $event.map((cohort) => +cohort);
    }
  }

  onChangeConcept($event = []) {
    if ($event && $event.length) {
      this.selectedConcepts = $event.map((concept) => +concept);
      this.filteredCohorts = this.cohortList.filter((cohort: any) => this.selectedConcepts.includes(cohort.glbl_fltr_con_set_id));
      this.isConceptListChanged = !this.isConceptListChanged;
      this.isCohortListChanged = !this.isCohortListChanged;
    }
  }
  onChangeMyFeatures($event = []) {
    if ($event && $event.length) {
      this.selectedMyFeatures = $event.map((myFeatures) => +myFeatures);
      //   this.isMyFeaturesChanged = !this.isMyFeaturesChanged;
    }
  }

  onChangeMasterFeatures($event = []) {
    if ($event && $event.length) {
      this.selectedMasterFeatures = $event.map((masterFeatures) => +masterFeatures);
      //   this.isMasterFeaturesChanged = !this.isMasterFeaturesChanged;
    }
  }

  onChangeUserStudy($event) {
    this.actionDefinitionData['study_id'] = $event;
  }

  onChangeUserFlow($event = []) {
    if ($event && $event.length) {
      this.selectedUserFlows = $event;
    }
  }

  onClaimFilterSelect(event) {
    // Description: Event based function which is called when claim type is changed in Claim Filter component
    // Arguments: - event : a DOM event
    console.log(event);
    const filterValue = this.includedFilterType[event.target.value];
    console.log(filterValue);
    var elementPosition = -1;
    console.log(this.includedFilterTypeValue);
    if (this.includedFilterTypeValue) {
      elementPosition = this.includedFilterTypeValue.indexOf(filterValue);
      console.log(elementPosition);
    }
    if (event.target.checked) {
      if (elementPosition === -1) {
        console.log(1);
        this.includedFilterTypeValue.push(filterValue);
      }
    } else {
      if (elementPosition !== -1) {
        console.log(2);
        this.includedFilterTypeValue.splice(elementPosition, 1);
      }
    }
  }

  selectActionDefinition() {
    switch (this.data.nodeName) {
      case 'Concept & Cohort': {
        this.actionName = 'Concept & Cohort';
        this.getConcepts();
        break;
      }
      case 'Dimension Mapping': {
        this.actionName = 'Dimension Mapping';
        this.getMappingsDetails();
        break;
      }
      case 'Days of Supply Imputation': {
        this.actionName = 'Days of Supply Imputation';
        this.getConfigDetails();
        break;
      }
      case 'Grace Period Analysis': {
        this.actionName = 'Grace Period Analysis';
        this.getConfigDetails();
        break;
      }
      case 'Regimen Refinement': {
        this.actionName = 'Regimen Refinement';
        this.getRegimenMergeDetails();
        break;
      }
      case 'Line of Therapy': {
        this.actionName = 'Line of Therapy';
        this.getConfigDetails();
        break;
      }
      case 'Custom Query': {
        this.actionName = 'Custom Query';
        this.actionUUID = this.data.nodeUUID;
        this.getCustomQueryDetails();
        break;
      }
      case 'Python Module': {
        this.actionName = 'Python Module';
        this.actionUUID = this.data.nodeUUID;
        this.getPythonFileDetails();
        break;
      }
      case 'Feature Catalogue': {
        // bookmark : this is what gets executed on double click of a node
        this.actionName = 'Feature Catalogue';
        this.actionUUID = this.data.nodeUUID;
        this.getFeatureEngineeringDetails();
        break;
      }
      case 'Regimen Merge': {
        this.actionName = 'Regimen Merge';
        this.getRegimenMergeDetails();

        break;
      }
      case 'Claim Filter': {
        this.actionName = 'Claim Filter';
        this.getConfigDetails();
        break;
      }
      case 'Stage Identification': {
        this.actionName = 'Stage Identification';
        this.getRegimenMergeDetails();
        break;
      }
      case 'Data Transfer': {
        this.actionName = 'Data Transfer';
        this.actionUUID = this.data.nodeUUID;
        this.getDataTransferDetails();
        break;
      }
      case 'Invoke Flow': {
        this.actionName = 'Invoke Flow';
        this.actionUUID = this.data.nodeUUID;
        this.getInvokeFlowDetails();
        break;
      }
      case 'Generate Alert': {
        this.actionName = 'Generate Alert';
        this.getConfigDetails();
        break;
      }
      case 'Distributed Cohort': {
        this.actionName = 'Distributed Cohort';
        this.getBGHDetails(true);
        break;
      }
      case 'Cohort Descriptive Study': {
        this.actionName = 'Cohort Descriptive Study';
        this.getBGHDetails();
        break;
      }
      case 'Study Executor': {
        this.actionName = 'Study Executor';
        this.getBGHDetails();
        this.getAMCDetails();
        break;
      }
      case 'Study Insights': {
        this.actionName = 'Study Insights';
        this.getBGHDetails();
        break;
      }
      case 'Primary Physician Assignment': {
        this.actionName = 'Primary Physician Assignment';
        this.getRegimenMergeDetails();
        break;
      }
      case 'Patient Journey Insights': {
        this.actionName = 'Patient Journey Insights';
        this.getVisualizationKPI();
        break;
      }
      default: {
        break;
      }
    }
  }

  onChangeBGHCohort($event = null) {
    if ($event) {
      this.selectedMGBCohort = $event;
    }
  }

  onChangeBGHCriteria($event) {
    if ($event && $event.length) {
      this.selectedBGHCriteria = $event.map(function (x) {
        return +x;
      });
    } else {
      this.selectedBGHCriteria = [];
    }
    // this.selectedBGHCriteria.splice(i, 0, this.bgh_study_description.filter(x=>x.id==$event)[0])
  }

  onChangeStudyType($event) {
    if ($event && $event.length) {
      this.selectedStudyType = $event;
    }
  }

  onChangeFileType(event) {
    if (event.target.checked) {
      this.fileType = 'pyspark';
    } else {
      this.fileType = 'python';
    }
  }

  onGenderChange($event) {
    console.log($event);
  }

  onChangeTenants($event) {
    console.log($event);
    this.selectedTenants = $event;
  }

  onChangeConceptSet($event) {
    console.log($event);
  }

  onChangeAlertType($event, i) {
    if ($event && $event.length) {
      this.alertDetails[i]['alert_type'] = $event;
    }
  }

  removeAlert(i) {
    this.alertDetails['data'].splice(i, 1);
    if (this.alertDetails['data'].length <= 0) {
      this.alertDetails['data'].push(JSON.parse(JSON.stringify(this.alertDetail)));
    }
  }

  addNewAlert() {
    this.alertDetails['data'].push(JSON.parse(JSON.stringify(this.alertDetail)));
  }

  onFCToggleChange($event) {
    this.selectionType = $event.checked ? this.selectionTypesList[1] : this.selectionTypesList[0];
    if (this.selectionType === this.selectionTypesList[0]) {
      this.selectedFCStudies.length = 0;
    } else {
      this.selectedMyFeatures.length = 0;
      this.selectedMasterFeatures.length = 0;
    }
  }

  onChangeFCStudies(studyIds) {
    this.selectedFCStudies = studyIds.map((id) => +id);
  }

  copyClipboard(copiedText: string) {
    this.clipboardService.copyFromContent(copiedText);
  }
}
