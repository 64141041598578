import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StudiesApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getStudies(): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/studies/`);
  }
}
