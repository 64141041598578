import ComponentMixin from '../smart/component.m.js';

// We need a Base class for composition 
class Base extends HTMLElement {
    constructor(...args) {
        const _ = super(...args);
        return _;
    }
}

/**
 * Loader ESM component
 * @extends {HTMLElement}
 */
export default class Loader extends ComponentMixin(Base) {
    constructor(...args) {
        // Constructor caveat https://github.com/WebReflection/document-register-element/
        const _ = super(...args);
        return _;
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    render() {
        let html = this.template();
        if (this.innerHTML != html) this.innerHTML = html;
    }

    show() {
        this.style.display = '';
    }

    hide() {
        this.style.display = 'none';
    }

    template() {
        return `
            <span loader>
                <span></span>
            </span>
        `
    }
}

Loader.is = 'zs-loader';