import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, OnChanges } from '@angular/core';
import Select from '../../../../zsui/src/select/select.m.js';
Select.register('zs-select', 'p');

@Component({
  selector: 'app-zs-select',
  templateUrl: './zs-select.component.html',
  styleUrls: ['./zs-select.component.less'],
})

// tslint:disable: variable-name
export class ZsSelectComponent implements OnChanges {
  @Input() items = [];
  @Input() bindValue;
  @Input() bindLabel;
  @Input() checkBox = false;
  @Input() selectAll = false;
  @Input() multiple = false;
  @Input() multipleSelect = false;
  @Input() showOptionTitle = false;
  @Input() hasChanged;
  @Input() selectedList = [];
  @Input() selectLabel = 'Select option';
  @Input() isFullWidthOfParent = false;
  @Input() disabledOptions = [];
  @ViewChild('zsSelectDropDown', { static: true }) zsSelectDropDown: ElementRef;
  @Output() selectValueChange = new EventEmitter<any>();
  private options: Array<any> = [];
  public selectValue = '';
  public dropdownElement;
  constructor(private _renderer: Renderer2) {}
  public isListChanged;
  public isElementRender = false;

  ngOnChanges() {
    if (this.isListChanged !== this.hasChanged) {
      this.isListChanged = this.hasChanged;
      if (this.dropdownElement) {
        this._renderer.removeChild(this.zsSelectDropDown.nativeElement, this.dropdownElement);
      }
      this.createSelectOptions();
    }
  }

  createSelectOptions() {
    this.options = [];
    this.items.map((elem) => {
      this.options.push({
        label: elem[this.bindLabel] || elem,
        value: elem[this.bindValue] || elem,
      });
    }, {});
    this.createOptions();
  }

  createOptions() {
    let selectOptions = '';
    let isOptionsListEmpty = false;
    if (this.items.length > 0) {
      selectOptions = this.getOptionsString();
    } else {
      selectOptions = `<option value="" disabled>No records found</option>`;
      isOptionsListEmpty = true;
    }
    this.createDropdownElement(selectOptions, isOptionsListEmpty);
  }

  getOptionsString() {
    let options = '';
    for (const option of this.options) {
      const title = this.showOptionTitle ? `title="${option.value}"` : '';
      options += `<option ${this.selectedList.includes(option.value) ? 'selected' : ''}
								value="${option.value}"  ${this.disabledOptions.includes(option.value) ? 'disabled' : ''} ${title} title="${option.label}">
								${option.label}
						</option>`;
    }
    return options;
  }

  createDropdownElement(options, isOptionsListEmpty = false) {
    const selectAll = !isOptionsListEmpty && this.selectAll;
    const multiple = selectAll ? 'multiple' : null;
    const maxOptionVisible = this.items.length > 0 ? this.items.length : 1;
    const dropdown = document.createElement('p', { is: 'zs-select' });
    this.dropdownElement = dropdown;
    if (selectAll) {
      dropdown.setAttribute('add-checkboxes', `${this.checkBox}`);
      dropdown.setAttribute('add-select-all', `${selectAll}`);
    }
    dropdown.setAttribute('max-options-visible', `${maxOptionVisible}`);
    dropdown.setAttribute('empty-text', `${this.selectLabel}`);
    dropdown.innerHTML = `<select ${multiple} >${options}</select>`;
    dropdown.classList.add('zs-action-field');
    dropdown.classList.add('zs-select');
    const zsSelectClass = this.isFullWidthOfParent ? 'zs-select-full-width' : 'zs-select-custom';
    dropdown.classList.add(zsSelectClass);
    this.zsSelectDropDown.nativeElement.append(dropdown);
    dropdown.addEventListener('change', (element) => {
      if (element && !element.isTrusted) {
        const dropdownValue = (element.target as any).getValue();
        this.selectValue = dropdownValue;
        this.selectValueChange.emit(this.selectValue);
      }
    });
  }
}
