import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FlowApiService } from 'src/app/_services/api/flow.api.service';
import { FlowService } from 'src/app/_services/flow.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ClipboardService } from 'ngx-clipboard';
import { DOCUMENT } from '@angular/common';
import { common } from 'src/app/app.messages';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from 'src/app/_services/common.service';
import Tooltip from 'zsui/src/tooltip/tooltip.m.js'; 
import { saveAs } from 'file-saver';
import { SensitiveInfoService } from 'src/app/_services/sensitive-info.service';
import { MatDatepicker} from '@angular/material/datepicker';
import { COLOR_CODE_1, COLOR_CODE_2, COLOR_CODE_3 } from 'src/app/app.constants'
import 'moment/locale/pt-br';

import * as Highcharts from 'highcharts';
import Funnel from 'highcharts/modules/funnel';
import { from } from 'rxjs';
Funnel(Highcharts);

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
});

Tooltip.register(); 

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: deprecation
// tslint:disable: no-string-literal
export class ModalComponent implements OnInit {
  private flowId;
  public flowExports = [];
  public exportName;
  public archiveData;
  public cohortArchiveData;
  public commonMessage = common;
  public componentList = []
  public schedule_type = ['Weekly', 'Monthly', 'Daily', 'Custom']
  public weekly_day = {'MON':'Monday', 'TUE': 'Tuesday', 'WED': 'Wednesday', 'THU': 'Thursday', 'FRI':'Friday', 'SAT':'Saturday', 'SUN':'Sunday'}
  public schedule_expression = {};
  public schedule_payload = {'selectedtype': '', 'selectedDayOfWeek': [], 'time': {'hour':Number('HH'), 'minute':Number('MM')}, 'SelectedDayOfMonth': [], 'month':''}
  public toDate: any = '';
  public today = new Date();
  public set_of_month;
  public custom_month_value;
  public isScheduleResponse = {isTimeSelected: false, isTypeSelected: false, isWeekSelected:false}
  public defaultTime = {'hour':'HH', 'minute':'MM'};
  public localTimeZone = 'GMT+0530';
  public UTCTimeZone = 'GMT+0000';
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  public model = [];
  public isFlowScheduled = false;
  public selectedDates = [];
  public daysSelected = [];
  public selectedTime;
  public isFlowReSchedule = false;
  public flow_schedule_message = ''
  public final_schedule=[];
  public studyTitle: string;
  public isCloneDisabled = true;
  public selectedArchives = [];
  public selectedCohortArchives = [];
  public selectedFailedNodes = [];
  public isCohortTab = false;
  public isFlowReexecution = false;
  public isResponseLoaded = false;
  public funnelData = {};
  public bghData = {};
  public funnelSettings: any = {
    width: 400,
    height: 30,
    spaceSize: 5,
    isResponsive: true,
    percentWidth: 0.34,
    percentHeight: 0.06,
  };
  public bghVisualizationCategories = [
    {
      'name': 'Disease Progression',
      'id': 1
    },
    {
      'name': 'Risk Factors',
      'id': 2
    },
    {
      'name': 'Biomarkers',
      'id': 3
    },
  ];
  public selectedCategory = 1;
  public diseaseResponse = [];
  public tumorGrade = [];
  public smokingHistory = [];
  public alcoholHistory = [];
  public egfrStatus = [];
  public krasStatus = [];
  public dominantHistologicalSubtype =[];
  public amcDistribution = [];
  public tumorHeterogeneity = {
    horizontalBarData: [],
  };
  public ajccPathologicalStage = {
    horizontalBarData: [],
  };
  public tmb = {
    horizontalBarData: [],
  };
  public coarseTextureStrength = {
    horizontalBarData: [],
  };
  public color_code_1 = COLOR_CODE_1;
  public color_code_2 = COLOR_CODE_2;
  public color_code_3 = COLOR_CODE_3;

  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;

  
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    private _flowApiService: FlowApiService,
    private _flowService: FlowService,
    private notifyService: NotificationService,
    private _router: Router,
    private clipboardService: ClipboardService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    private _commonService: CommonService,
    private _sensitiveInfoService: SensitiveInfoService
  ) {}

  ngOnInit() {
    this.flowId = this._flowService.getFlowId();
    if (this.data.id === 'modal-component') {
      this.getFlowExports();
    }
    if (this.data.id === 'archive-data-modal') {
      this.getArchiveData();
    }
    if (this.data.id === 'output-path-modal-component') {
      this.getComponenetOutputPath();
    }
    if (this.data.id === 'schedule-modal-content-wrapper') {
      this.getScheduleDetails();
    }
    if (this.data.id === 'visualization-modal-component') {
      this.getVisualizationDetails();
      this.adjustCharts();
    }
    
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }


  public remove(date: Date): void {
    const index = this._findDate(date);
    this.model.splice(index, 1)
  }

  private _findDate(date: Date): number {
    return this.model.map((m) => +m).indexOf(+date);
  }


  getFlowExports() {
    this.flowId = this.data.flowId;
    this._flowApiService.getFlowExports(this.flowId).subscribe((response: any) => {
      this.flowExports = response.exports;

      this.flowExports.forEach(element => {
        const s3_location = this._sensitiveInfoService.decrypt(element.s3_location)
        element['s3_location'] = s3_location;
          
      });
      this.flowExports.sort((a, b) => {
        return new Date(b['created_on']).getTime() - new Date(a['created_on']).getTime();
      });
    });
  }

  populateChartData(data, amc_data, skip = []) {

    if (!skip.length) {
      this.amcDistribution = [{
        data: amc_data,
        outerRadius: 50,
        radius: 35,
        height: 140,
        heightProportinalityToRadius: 2,
      }];
    }

    if (!skip.includes(0)) {
      this.diseaseResponse = [{
        data: data['Disease Response'] != null ? data['Disease Response'].sort(
          (p1, p2) => (p1.value < p2.value) ? 1 : (p1.value > p2.value) ? -1 : 0) : [],
        outerRadius: 80,
        radius: 1,
        height: 190,
        heightProportinalityToRadius: 2,
      },];
    }

    this.tumorGrade = [{
      data: data['Tumor_Grade'] != null ? data['Tumor_Grade'].sort(
        (p1, p2) => (p1.value < p2.value) ? 1 : (p1.value > p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.smokingHistory = [{
      data: data['Smoking History'] != null ? data['Smoking History'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.alcoholHistory = [{
      data: data['Alcohol History'] != null ? data['Alcohol History'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.egfrStatus = [{
      data: data['EGFR Status'] != null ? data['EGFR Status'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.krasStatus = [{
      data: data['KRAS status'] != null ? data['KRAS status'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.dominantHistologicalSubtype = [{
      data: data['Dominant_Histological_Subtype'] != null ? data['Dominant_Histological_Subtype'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
      outerRadius: 80,
      radius: 55,
      height: 180,
      heightProportinalityToRadius: 2,
    },];

    this.tumorHeterogeneity = {
      horizontalBarData: data['Tumor Heterogeneity (GLCM)'] != null ? data['Tumor Heterogeneity (GLCM)'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
    };

    this.tmb = {
      horizontalBarData: data['TMB'] != null ? data['TMB'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
    };

    this.ajccPathologicalStage = {
      horizontalBarData: data['AJCC Pathalogical stage'] != null ? data['AJCC Pathalogical stage'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
    };

    this.coarseTextureStrength = {
      horizontalBarData: data['Coarse Texture Strength (ngtdm)'] != null ? data['Coarse Texture Strength (ngtdm)'].sort(
        (p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0) : [],
    };
    
    this.isResponseLoaded = true;
  }

  getVisualizationDetails() {
    this.flowId = this.data.flowId;
    this._flowApiService.getBGHDetails(this.flowId).subscribe((response: any) => {
      this.bghData = response;
      this._flowApiService.getBGHVisualizations(this.flowId).subscribe((res: any) => {
        if (res.status === 'SUCCESS' && res.data.funnel_data != null) {
          this.funnelData = res.data.funnel_data.sort(
            (p1, p2) => (p1.value < p2.value) ? 1 : (p1.value > p2.value) ? -1 : 0);
          this.populateChartData(res.data.funnel_data[0].funnel_definition, res.data.funnel_data[0].amc_data);
        }
      });
    });
  }

  adjustCharts() {
    if (this.funnelSettings.isResponsive) {
      this.funnelSettings.width = Math.floor(
        window.innerWidth * this.funnelSettings.percentWidth
      );
      this.funnelSettings.height = Math.floor(
        window.innerHeight * this.funnelSettings.percentHeight
      );
    }
  }

  getDetails(event) {
    console.log(event.funnel_definition);
    this.populateChartData(event.funnel_definition, event.amc_data)
  }

  closeModal(isRefreshPage = false) {
    this.dialogRef.close(isRefreshPage);
  }

  copyClipboard(copiedText: string) {
    this.clipboardService.copyFromContent(copiedText);
  }

  deleteFlow() {
    // this.notifyService.showSuccess('Flow has been deleted successfully', 'Notification');
    this.closeModal(true);
  }

  changeArchiveTab(isCohortTab: boolean) {
    this.isCohortTab = isCohortTab
  }

  saveExportName() {
    this.flowId = this.data.flowId;
    const payload = { name: this.exportName };
    this.closeModal(true);
    this._flowApiService.saveExportDetails(this.flowId, payload).subscribe((response: any) => {
      if (response) {
        this.notifyService.showSuccess(response['message'], 'Notification');
        this.closeModal(true);
        this.updateConfigForBreakPoint()
      }
    }, (err) => {
      this.notifyService.showError(err.error.message, "Error");
    });
  }

  updateConfigForBreakPoint() {
    if(this._commonService.isBreakpointActive == true) {
      this._commonService.breakPointNodes = [];
      this._commonService.selectedBreakPointNodes = [];
      this._commonService.isTwoComponentOnly = false;
      this._commonService.isBreakpointActive = false;
      // this._flowApiService.saveConfig({'is_breakpoint': false}, this.flowId).subscribe(
      //   (res: any) => {
      //     this._commonService.breakPointNodes = [];
      //     this._commonService.selectedBreakPointNodes = [];
      //     this._commonService.isTwoComponentOnly = false;
      //     this._commonService.isBreakpointActive = false;
      //   }
      // );
    }
    
  }

  getArchiveData() {
    this.flowId = this.data.flowId;
    this._flowApiService.getFlowArchiveDetails(this.flowId).subscribe((response: any) => {
      if (response.status) {
        this.archiveData = response['archive_data'];
        this.cohortArchiveData = response['cohort_data'];
      }
    });
  }

  executeTheFlow() {
    this.closeModal(false);
  }

  reExecuteFlow(reExecute:boolean) {
    this.isFlowReexecution = reExecute;
  }

  reExecuteTheFlow() {
    const payload = {'nodes':this.selectedFailedNodes}
    this._flowApiService.reExecuteTheFlow(this.flowId, payload).subscribe((response: any) => {
      if (response) {
        this.notifyService.showSuccess(response['message'], 'Notification');
      }
    });
    this.closeModal(true);
  }

  getComponenetOutputPath() {
    this._flowApiService.getOutputPath(this.flowId).subscribe((response: any) => {
      if (response) {
        if(response) {
          this.getComponentList(response)
        }
      }
    });
  }

  getComponentList(res) {
    this.componentList = res;
    //this.componentList = [{'name':'ABC', 's3_location':'https://abc.com'}]

  }

  downloadLog(export_detail) {
    const payload = {'flow_execution_id': export_detail['flow_execution_id']}
    this._flowApiService.getJobLog(this.flowId, payload).subscribe((response: any) => {
      if (response) {
        if(response.status == 200) {
          const blob = this.base64ToBlob(response.data, 'text/plain');
          saveAs(blob, `log_${response.cluster_id}_${response.step_id}.txt`);
        } 
        else {
          this.notifyService.showinfo(response.message, "Notification");
        }
      }
    });

  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public onChangeType(type) {
    this.schedule_payload.selectedtype = type;
    this.isScheduleResponse.isTypeSelected = true;
  }

  
  public onChangeDayOfWeek(day_of_week) {
    if(this.schedule_payload.selectedDayOfWeek.includes(day_of_week)) {
      this.schedule_payload.selectedDayOfWeek.splice(this.schedule_payload.selectedDayOfWeek.indexOf(day_of_week), 1);
    } else {
      this.schedule_payload.selectedDayOfWeek.push(day_of_week);
    }
    this.isScheduleResponse.isWeekSelected = true;
  }

  public convert_time(schedule_time, utcToLocal: boolean) {
    var timeZone = utcToLocal? this.UTCTimeZone : this.localTimeZone
    var custom_date = `Sat Apr 23 2022 ${schedule_time['hour']}:${schedule_time['minute']} ${timeZone}`
    return new Date(custom_date)
  }

  public get_month(month: number) {
    return ('0' + (month+1)).slice(-2)
  }

  public get_date(date: number){
    return ('0' + (date)).slice(-2)
  }

  public get_converted_date(dates, time, utcToLocal: boolean) {
    const new_dates = [];
    var timeZone = utcToLocal? this.UTCTimeZone : this.localTimeZone
    dates.forEach(date => {
      var formattedDateTime = new Date(`${date} ${time.hour}:${time.minute} ${timeZone}`);
      if (utcToLocal){
        new_dates.push(`${formattedDateTime.getFullYear()}-${this.get_month(formattedDateTime.getMonth())}-${this.get_date(formattedDateTime.getDate())}`)
      } else {
        new_dates.push(`${formattedDateTime.getUTCFullYear()}-${this.get_month(formattedDateTime.getUTCMonth())}-${this.get_date(formattedDateTime.getUTCDate())}`)
      }
    });
    return new_dates
  }

  public get_adjusted_day_of_week(arr, time, utcToLocal: boolean) {
    time = this.convert_time(time, utcToLocal);
    var new_arr = []
    if (time.getHours() < time.getUTCHours()) {
      var days = Object.keys(this.weekly_day)
      arr.forEach(day => {
        new_arr.push(utcToLocal ? (days[(days.indexOf(day)+1)%7]) : (days[days.indexOf(day) ? days.indexOf(day)-1 : days.length-1]));
      })
      return new_arr
    }
    return arr
  }

  public saveScheduleDetails() {
    // var utcStart = moment( this.schedule_payload.time['hour']+":"+ this.schedule_payload.time['minute'], "HH:mm").utc();
    // var utcTime = utcStart.format().split("T")[1].split(":")
    var arr = this.schedule_payload.selectedDayOfWeek.filter(e =>  e);
    this.schedule_payload.selectedDayOfWeek = arr;
    if(this.validate_schedule_payload(this.schedule_payload)) {
      var newDate = this.convert_time(this.schedule_payload.time, false);
      arr = this.get_adjusted_day_of_week(arr, this.schedule_payload.time, false);
      const month = [];
      var selectedDays = [];
      if((this.schedule_payload.selectedtype.toLowerCase() == 'custom') || (this.schedule_payload.selectedtype.toLowerCase() == 'monthly')) {
        selectedDays = this.get_converted_date(this.daysSelected, this.schedule_payload.time, false)
        selectedDays.forEach(element => {
          var da = new Date(element)
          month.push(da.toLocaleString('default', { month: 'short' }));
        });
        
        this.set_of_month = new Set(month);
        this.custom_month_value =  [...this.set_of_month];
      }
      this.schedule_expression = {
        'day_of_week': arr.join(','),
        'type': this.schedule_payload.selectedtype.toLowerCase(),
        'day_of_month': this.daysSelected.length>0? selectedDays:[],
        'hour': newDate.getUTCHours(),
        'minute':newDate.getUTCMinutes(),
        'month': this.custom_month_value
      }
      this.isFlowScheduled=this.isFlowReSchedule?false:true;
      this.apiCallToScheduleTheFlow(this.schedule_expression)
  }
}

  public validate_schedule_payload(payload) {
    if(!payload.selectedtype || payload.selectedtype == null || payload.selectedtype == undefined) {
      this.notifyService.showError('Please select type', 'Error')
      return false;
    }
    if (!payload.time || payload.time.hour == null || payload.time.hour == undefined || payload.time.minute == null || payload.time.minute == undefined) {
      this.notifyService.showError('Please select time', 'Error')
      return false;
    }
    if (!payload.time || (isNaN(payload.time.hour)) || (isNaN(payload.time.minute)) ) {
      this.notifyService.showError('Please select time', 'Error')
      return false;
    }
    if(payload.selectedtype.toLowerCase() == 'daily' && !payload.time) {
      this.notifyService.showError('Please enter time to schedule flow for daily basis', 'Error')
      return false;
    }
    if(payload.selectedtype.toLowerCase() == 'weekly' && payload.selectedDayOfWeek.length==0) {
      this.notifyService.showError('Please select day/days to schedule flow for weekly basis', 'Error')
      return false;
    }
    if(payload.selectedtype.toLowerCase() == 'monthly' && this.daysSelected.length==0) {
      this.notifyService.showError('Please enter date and time to schedule flow for monthly basis', 'Error')
      return false;
    }
    if(payload.selectedtype.toLowerCase() == 'custom' && this.daysSelected.length==0) {
      this.notifyService.showError('Please enter date and time to schedule flow for custom day', 'Error')
      return false;
    }
    return true;
  }
  public to_reschedule() {
    // this.schedule_payload.selectedDayOfWeek = [];
    // this.schedule_payload.selectedtype = '';
    // this.schedule_payload.SelectedDayOfMonth = [];
    // this.schedule_payload.time = {'hour':0, 'minute':0}
    // this.daysSelected = [];
    this.isFlowScheduled = false;
    this.isFlowReSchedule = true;
  }

  public apiCallToScheduleTheFlow(data) {
    data['status'] = 'enabled'
    
    if(this.isFlowReSchedule) {
      this.reschedule(data);
    } 
    if (this.isFlowScheduled) {
      this.schedule(data);
    }
  }
  
  public schedule(data) {
    this._flowApiService.schedule_flow(this.flowId, data).subscribe((response: any) => {
      if (response) {
        this.notifyService.showSuccess(response['message'], 'Notification')
      }
    });
    this.closeModal(true);
  }

  public reschedule(data){
    this._flowApiService.reschedule_flow(this.flowId, data).subscribe((response: any) => {
      if (response) {
        this.notifyService.showSuccess(response['message'], 'Notification')
        this.closeModal(true);

      }
    });
  }
  

  public delete_schedule() {
    this._flowApiService.delete_event(this.flowId).subscribe((response: any) => {
      if (response) {
        this.notifyService.showSuccess(response['message'], 'Notification');
        this.closeModal(true);
      }
    });
  }

  public create_message(schedule_payload) {
    if(schedule_payload.selectedtype == 'weekly') {
      this.flow_schedule_message = "Weekly basis for "+ schedule_payload.selectedDayOfWeek + " at " + schedule_payload.time['hour'] + ":" + schedule_payload.time['minute'] 
    }
    if(schedule_payload.selectedtype == 'monthly') {
      this.flow_schedule_message = "Monthly basis for date "+ this.daysSelected + " at " + schedule_payload.time['hour'] + ":" + schedule_payload.time['minute']
    }
    if(schedule_payload.selectedtype == 'daily') {
      this.flow_schedule_message = "Daily at "+ schedule_payload.time['hour'] + ":" + schedule_payload.time['minute']
    }
    if(schedule_payload.selectedtype == 'custom') {
      this.flow_schedule_message = "Custom for date "+ this.daysSelected + " at "+ schedule_payload.time['hour'] + ":" + schedule_payload.time['minute']
    }
  }
  public getScheduleDetails() {
    this._flowApiService.get_schedule_details(this.flowId).subscribe((response: any) => {
      if(response && response['schedule_details']) {
        this.isFlowScheduled = true;
      }
      if (response) {
        if (response['schedule_details'] && response['schedule_details']['expression']) {
          this.schedule_payload.time['hour'] = response['schedule_details']['expression']['hour'];
          this.schedule_payload.time['minute'] = response['schedule_details']['expression']['minute'];
          this.daysSelected = response['schedule_details']['expression']['day_of_month']?this.get_converted_date(response['schedule_details']['expression']['day_of_month'], this.schedule_payload.time, true):[];
          this.schedule_payload.selectedtype = response['schedule_details']['type'];
          this.schedule_payload.selectedDayOfWeek = this.get_adjusted_day_of_week(response['schedule_details']['expression']['day_of_week'].split(","), this.schedule_payload.time, true)
          this.isScheduleResponse.isTypeSelected = true;
          this.isScheduleResponse.isWeekSelected = true;
          if (this.schedule_payload.time) {
            var converted_time = this.convert_time(this.schedule_payload.time, true)
            this.isScheduleResponse.isTimeSelected = true;
            this.schedule_payload.time['hour'] = converted_time.getHours()
            this.schedule_payload.time['minute'] = converted_time.getMinutes()
          }
        }
      }
      this.create_message(this.schedule_payload)
    });
    
  }

  isSelected = (event: any) => {
    this.schedule_payload.month = event.getMonth()
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
      
      //console.log(this.name,'---',this.daysSelected)
      //this.name=this.daysSelected[0]+','+this.daysSelected[1]
      this.final_schedule = Array.from(this.daysSelected)
      //for (let entry of this.daysSelected) {
        //this.name = [] + entry; 
        //}
    return this.daysSelected.find(x => x == date) ? "selected" : null;
  };

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex(x => x == date);
    //console.log(" ** ",this.daysSelected)
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    calendar.updateTodaysDate();
    //console.log(this.daysSelected)
  }

  onNewStudyTitle() {
    const isSameStudyTitle = this.data.title.toLocaleLowerCase().trim() !== this.studyTitle.toLocaleLowerCase().trim();
    this.isCloneDisabled = this.studyTitle.trim() && isSameStudyTitle ? false : true;
  }
  
  cloneStudy() {
    const payload = {
      title: this.studyTitle
    };

    this._flowApiService.cloneStudy(this.data.flowId, payload).subscribe((response: any) => {
      if (response.status) {
        this.notifyService.showSuccess(response['message'], 'Notification');
        this.closeModal(true);
      } else {
        this.notifyService.showError(response['message'], 'Error')
        this.notifyService.showError('Try giving new title', 'Error')
      }
    },
      (err) => {
        this.notifyService.showError(err.error['message'], 'Error');
      }
    );
  }

  onSelectFailedNodes(event:any, data:any) {
    if (event.target.checked) {
      this.selectedFailedNodes.push(data)
    } else {
      this.selectedFailedNodes = this.selectedFailedNodes.filter((x) => x!==data);
    }
  }

  onSelectArchive(event:any, data:any) {
    if (this.isCohortTab) {
      if (event.target.checked) {
        if (Array.isArray(data)) {
          this.selectedCohortArchives = [...this.cohortArchiveData]
        } else {
          this.selectedCohortArchives.push(data);
        }
      } else {
        if (Array.isArray(data)) {
          this.selectedCohortArchives = [];
        } else {
          this.selectedCohortArchives.splice(this.selectedCohortArchives.indexOf(data),1);
        }
      }
    } else {
      if (event.target.checked) {
        if (Array.isArray(data)) {
          this.selectedArchives = [...this.archiveData]
        } else {
          this.selectedArchives.push(data);
        }
      } else {
        if (Array.isArray(data)) {
          this.selectedArchives = [];
        } else {
          this.selectedArchives.splice(this.selectedArchives.indexOf(data),1);
        }
      }
    }
  }

  onDeleteArchive() {
    const flowId = this.flowId
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'export-modal-content-wrapper';
    dialogConfig.height = '200px';
    dialogConfig.width = '520px';
    dialogConfig.data = { flowId, id: 'delete-archive-modal-content-wrapper' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteArchive()
      }
    });
  }

  deleteArchive() {
    const payload = {
      archiveLocations: this.selectedArchives,
      cohortArchives: this.selectedCohortArchives
    };
    this._flowApiService.deleteFlowArchive(this.flowId, payload).subscribe((response: any) => {
      if (response.status) {
        this.notifyService.showSuccess(response['message'], 'Notification');
        this.selectedArchives.forEach((data)=>{
          this.archiveData.splice(this.archiveData.indexOf(data), 1);
        });
        this.closeModal();
      } else {
        this.notifyService.showError(response['message'], 'Error');
      }
    }, 
    (err) => {
      this.notifyService.showError(err.error['message'], 'Error');
    });
  }

  onCategoryChange($event) {
    this.selectedCategory = $event;
  }

  updateCharts($event) {
    this.populateChartData($event.distribution, [], [0])
    console.log($event)
  }
}
