import { Component, OnInit, ViewChild } from '@angular/core';
// import UserBar from "@zsui/userBar/userBar.m.js"; Replace this when ZSUI is upgraded to 5.3
import UserBar from '../../../../zsui-config/userBar.m.js';
import { IdmApiService } from 'src/app/_services/api/idm.api.service';
UserBar.register();

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: deprecation
export class UserBarComponent implements OnInit {
  @ViewChild('appUserBar', { static: true }) appUserBar;

  constructor(private _idmApiService: IdmApiService) {}

  ngOnInit() {
    this._idmApiService.getIdmLinks().subscribe((response) => {
      this.renderUserBar(response);
    });
  }

  renderUserBar(link) {
    const userbar = this.appUserBar.nativeElement;
    userbar.render({
      profile: {
        account: {
          label: 'My Account',
          url: link.my_account_url,
        },
      },
      sign_out: {
        label: 'Sign Out',
        url: link.logout_url,
      },
      switch_account: {
        label: 'Switch Account',
        url: '',
      },
      apps: {
        label: 'Applications',
        links: link.app_links,
      },
      theme: 'master',
    });
  }
}
