import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { ERROR_DISAPPEAR_TIMEOUT } from "../_helpers/app.constants";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  private _messages: BehaviorSubject<Array<String>> = new BehaviorSubject([]);

  public readonly messages: Observable<
    Array<String>
  > = this._messages.asObservable();

  constructor() {}

  setDisappearingMessages(messages: Array<String>) {
    this._messages.next(messages);
    setTimeout(() => {
      this.removeMessages();
    }, ERROR_DISAPPEAR_TIMEOUT);
  }

  removeMessages() {
    this._messages.next([]);
  }

  getMessages() {
    return this.messages;
  }
}
