// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const cohortApiBaseUrl = 'https://pac-cpt-chrt-builder.dev.zsservices.com';
const featureCatalogApiBaseUrl = 'https://fgn-rwd.dev.zsservices.com/';
const patientJournyApiUrl = 'https://ptj.dev.zsservices.com';
const flowBuilderUrl = 'https://pac-flwb.dev.zsservices.com';
const dataExplorerUrl = 'https://data-explorer.dev.zsservices.com';
const homeUrl = 'https://pac.dev.zsservices.com';
export const environment = {
  production: false,
  api_url: 'https://api.pac-flwb.dev.zsservices.com',
  idm_logout_url: 'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx?ReturnUrl=',
  cohortApiBaseUrl,
  featureCatalogApiBaseUrl,
  patientJournyApiUrl,
  flowBuilderUrl,
  dataExplorerUrl,
  homeUrl,
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: flowBuilderUrl,
    clientId: 'mfa.cdldev',
    responseType: 'code',
    clientSecret:
      'qWU979R6btW0LseA76UTpZiAMtulu0ESeqK801U3rpXf4yq3GF0MtIiDGXxZlhWx',
    cookieDomain: 'zsservices.com',
  },
  environment: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
