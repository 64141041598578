import { Title } from '@angular/platform-browser';
import { CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Field from '../../../zsui/src/field/field.m.js';
import { FlowNodeService } from '../_services/flow-node.service';
import { FlowApiService } from '../_services/api/flow.api.service';
import { FlowService } from '../_services/flow.service';
import { NotificationService } from '../_services/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlignName, USER_ROLES, JOB_TYPE_ID, FLOW_ACTIVITY_TYPE } from '../app.constants';
import { ModalComponent } from '../_components/modal/modal.component';
import { CommonService } from '../_services/common.service';
import { UsersApiService } from '../_services/api/users.api.service';
import { CustomQueryService } from '../_services/custom-query.service';
import { PythonModuleService } from '../_services/python-module.service';
import { DataTransferService } from '../_services/data-transfer.service';
import { InvokeFlowService } from '../_services/invoke-flow.service';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { FeatureModuleService } from '../_services/feature-catalogue-module.service';
import { FlowDefinitionComponent } from '../_components/flow-definition/flow-definition.component';
import { Breakpoints } from '@angular/cdk/layout';
import { element } from 'protractor';
import { SensitiveInfoService } from '../_services/sensitive-info.service';
import { FLOW_ACTIVITY } from '../app.messages';
import { CookieService } from 'src/app/_services/cookie.service';

Field.register();

@Component({
  selector: 'app-createflow',
  templateUrl: './createflow.component.html',
  styleUrls: ['./createflow.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: semicolon
// tslint:disable: no-string-literal
// tslint:disable: deprecation
export class CreateflowComponent implements OnInit {
  @Input() currentRoute;

  public flowId: number;
  username = '';
  public isEditFlow = false;
  flowExecutionResponse: {};
  executionLogs = [];
  executionStatus = '';
  componentStatus = {};
  nodes = [];
  connections = [];
  nodesDetails = {};
  flow_created_by = '';
  public actionErrorIconConfig;

  public isFlowBranched = false;

  public panelOpenState = false;
  collaborators = [];
  public users: any = [];
  collaboratorsUsersListChanged = false;
  public isBreakPointActive = true;
  isZenMode: boolean = false;
  public flowActivityStatus = FLOW_ACTIVITY_TYPE.idle;

  userRoles = USER_ROLES;
  hasUsersLoaded = false;
  flowExecutionstatus = true;
  successMessage;
  errorMessage;

  @ViewChild('dragFlowContainer', { read: ElementRef, static: true })
  dragFlowContainer: ElementRef;

  _currentIndex;
  _currentField;
  _pointerPosition;

  private orderedNodes = [];

  flowDetails = {
    flow_name: null,
    s3_location: null,
    data_source: null,
    therapy_area: null,
    created_by: null,
  };
  failedJobConfig = [];
  public componentList = [
    { name: 'Days Of Supply', s3_location: 's3://path' },
    { name: 'Grace Period', s3_location: 's3://path' },
  ];
  constructor(
    private nodeService: FlowNodeService,
    private _router: Router,
    private _flowApiService: FlowApiService,
    private _usersApiService: UsersApiService,
    private _flowService: FlowService,
    private notifyService: NotificationService,
    private _customQueryService: CustomQueryService,
    private _pythonModuleService: PythonModuleService,
    private _dataTransferService: DataTransferService,
    private _invokeFlowService: InvokeFlowService,
    private _commonService: CommonService,
    private _featureModuleService: FeatureModuleService,
    private titleService: Title,
    public dialogAnimation: NgDialogAnimationService,
    public matDialog: MatDialog,
    private _sensitiveInfoService: SensitiveInfoService,
    private cookieService: CookieService
  ) {}

  public allActions = [];
  public mappingDetailsOnInitComponenet;
  public breakPointNodes = [];
  public selectedBreakPointNodes = [];
  public isTwoComponentOnly = false;
  public isIntelligentBreakpointSelectionActive: boolean = true;

  ngOnInit() {
    this.getUsers();
    this.getActions();
    this.actionErrorIconConfig = this._commonService.hideIconActionDefination;
    this.componentStatus = this._commonService.componentStatus;
    this.breakPointNodes = this._commonService.breakPointNodes;
    this.selectedBreakPointNodes = this._commonService.selectedBreakPointNodes;
    this.isTwoComponentOnly = this._commonService.isTwoComponentOnly;
    this.flowId = this._flowService.getFlowId();
    this.isEditFlow = this.flowId && this.flowId !== null;
    this.flowActivityStatus = FLOW_ACTIVITY_TYPE.edit;

    if (this.flowId && this.isEditFlow) {
      this.getFlowDetails();
      this.getConfigDetails();
      this.getMappingDetails();
      this.updateFlowActivity();
    }

    this.nodeService.getRemovedNode().subscribe((node: any) => {
      this.updateNodesAfterRemovalofNode(node.removed_node);
      // To disable componenet on basis of selected componenet
      // This is for future purpose

      // if(node.removed_node!=undefined){
      //   this.hideActionsFromSelectedNode(node.removed_node.split("_")[0]);
      // }
    });
  }

  public updateFlowActivity() {
    if (this.flowId && this.flowId !== null) {
      this._flowApiService.updateActivity(this.flowId, { activity_type: this.flowActivityStatus }).subscribe(
        (response: any) => {
          if (response.status) {
            const message = this.flowActivityStatus === FLOW_ACTIVITY_TYPE.edit ? FLOW_ACTIVITY.flowLocked : FLOW_ACTIVITY.flowUnlocked;
            this.notifyService.showinfo(message, 'Notification');
            this.flowActivityStatus = FLOW_ACTIVITY_TYPE.idle;
          } else {
            this.notifyService.showError(FLOW_ACTIVITY.flowLockFail, 'Error');
          }
        },
        (error: any) => {
          this.notifyService.showError(FLOW_ACTIVITY.flowLockFail, 'Error');
        }
      );
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  getUsers() {
    const userRole = this._flowService.getUserRole();
    const project_id = this.cookieService.get('project_id')

    this._usersApiService.getUserSession().subscribe((data: any) => {
      this._usersApiService.getIdmUsersList(project_id, data.username).subscribe((response: any) => {
        this.users = response.data.map((u) => ({
          email: u.user_email,
          name: u.user_name,
          username: u.user_id,
        }));
        console.log(response);
        this.addNameToUsers();
        if (this.flowId && userRole !== this.userRoles.OWNER) {
          this.onChangeCollaborators(this.collaborators);
          // this.getLoggedInUserDetails()
        } else {
          this.getLoggedInUserDetails();
        }
      });
    });
  }

  addNameToUsers() {
    this.users.map((user) => {
      user.name = user.email
        .split('@')[0]
        .split('.')
        .reduce((acc, item) => `${acc} ${this.titleCaseWord(item)}`, '')
        .trim();
    });
  }

  titleCaseWord(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  onChangeCollaborators(list = []) {
    this.collaborators = [...list];
    this.collaboratorsUsersListChanged = !this.collaboratorsUsersListChanged;
    this.hasUsersLoaded = true;
  }

  getLoggedInUserDetails() {
    this._usersApiService.getUserSession().subscribe((data: any) => {
      this.username = data.username;
      this.removeLoggedInUserFromUsersList();
      if (!this.flowId) {
        this.openFlowDefinition();
      }
    });
  }

  toggleZenMode() {
    this.isZenMode = !this.isZenMode;
    const headers = document.getElementsByTagName('header');
    const main = document.getElementsByTagName('main');
    const viewSection = document.getElementById('view-port');
    const footer = Array.from(document.getElementsByClassName('footer-wrap') as HTMLCollectionOf<HTMLElement>);
    const workingArea = Array.from(document.getElementsByClassName('min-height-85vh') as HTMLCollectionOf<HTMLElement>);
    const cardList = Array.from(document.getElementsByClassName('card-list-container') as HTMLCollectionOf<HTMLElement>);
    for (let i = 0; i < headers.length; i++) {
      headers[i].style.display = this.isZenMode ? 'none' : 'block';
    }
    footer[0].style.display = this.isZenMode ? 'none' : 'flex';
    main[0].style.padding = this.isZenMode ? '0' : '0 0 0 70px';
    viewSection.setAttribute(
      'style',
      this.isZenMode
        ? 'padding-top: 0 !important;height: 100vh; margin: 0 0 0 0;'
        : 'padding-top: 1em !important;height: auto; margin: 0em 1em 5em 1em'
    );
    workingArea[0].style.height = this.isZenMode ? '100%' : '85vh';
    cardList[0].style.maxHeight = this.isZenMode ? '100%' : '78vh';
  }

  isZenModeActive() {
    return this.isZenMode;
  }

  toggleBreakPoint() {
    this.isBreakPointActive = !this.isBreakPointActive;
    this.saveConfig({ is_breakpoint: this.isBreakPointActive });
  }

  toggleIntelligentBreakpointSelectionMode() {
    this.isIntelligentBreakpointSelectionActive = !this.isIntelligentBreakpointSelectionActive;
    if (!this.isIntelligentBreakpointSelectionActive) {
      this.notifyService.showWarning('Please make sure to select all dependent actions!', 'Warning');
    }
    this.saveConfig({ is_breakpoint_intelligent: this.isIntelligentBreakpointSelectionActive });
  }

  saveConfig(params) {
    this._flowApiService.saveConfig(params, this.flowId).subscribe(
      (res: any) => {
        if (res.status) {
          // this.redirectToStudiesEdit()
          // this._router.navigate([`/studies/${this.flowId}/edit/`]);
          this.setBreakPointValues();
        }
      },
      (err) => {}
    );
  }

  getBreakPointValue(res) {
    this.isBreakPointActive = res['is_breakpoint'];
    this._commonService.isBreakpointActive = res['is_breakpoint'];
    this.isIntelligentBreakpointSelectionActive = res['is_breakpoint_intelligent'];
    this.setBreakPointValues();
  }

  setBreakPointValues() {
    if (this.isBreakPointActive == false) {
      this._commonService.selectedBreakPointNodes = [];
      this._commonService.breakPointNodes = [];
      this._commonService.isBreakpointActive = false;
    }
    if (this.isBreakPointActive == true) {
      this._commonService.isBreakpointActive = true;
    }
  }

  getBreakingPointDetails(nodes) {
    if (this.isBreakPointActive == true) {
      nodes.forEach((element) => {
        if (element['is_active'] == true && this._commonService.breakPointNodes.indexOf(element['uuid']) === -1) {
          this._commonService.breakPointNodes.push(element['uuid']);
          this._commonService.selectedBreakPointNodes.push(element);
          this._commonService.isTwoComponentOnly = true;
        }
      });
    }
  }

  getExecutionlog() {
    if (this.flowId) {
      this.executionLogs = [];
      this._flowApiService.getFlowExecutionStatus(this.flowId).subscribe(
        (response) => {
          this.flowExecutionResponse = response;
          this.assignStatus();
        },
        (err) => {}
      );
    }
  }

  assignStatus() {
    this.executionLogs = this.flowExecutionResponse['logs'];
    console.log(this.executionLogs);
    this.executionStatus = this.flowExecutionResponse['status'];
    this._flowService.setFlowStatus(this.executionStatus);
    this.failedJobConfig = [];
    console.log(this.executionLogs);
    for (const elm in this.executionLogs) {
      console.log(elm);
      this.componentStatus[elm] = this.executionLogs[elm]['status'];
      if (this.executionLogs[elm]['status'] == 'failed') {
        this.failedJobConfig.push(this.executionLogs[elm]);
      }
      if (this.executionLogs[elm]['status'] == null) {
        this.componentStatus[elm] = 'ideal';
      }
    }
    this.failedJobConfig.forEach((nodes) => {
      if (nodes.action_name === 'Custom Query') {
        let node = this._customQueryService.getCustomQueryDetails(nodes.uuid);
        nodes['node_name'] = node ? node.name : nodes.action_name;
      } else if (nodes.action_name === 'Python Module') {
        let node = this._pythonModuleService.getPythonFiles(nodes.uuid);
        nodes['node_name'] = node ? node.name : nodes.action_name;
      } else if (nodes.action_name === 'Data Transfer') {
        let node = this._dataTransferService.getDataTransferDetails(nodes.uuid);
        nodes['node_name'] = node ? node.name : nodes.action_name;
      } else if (nodes.action_name === 'Invoke Flow') {
        let node = this._invokeFlowService.getInvokeFlowDetails(nodes.uuid);
        nodes['node_name'] = node ? node.name : nodes.action_name;
      } else {
        nodes['node_name'] = nodes.action_name;
      }
    });
    this.getBreakingPointDetails(this.nodes);
    console.log(this.failedJobConfig);
  }

  getConfigDetails() {
    if (this.flowId) {
      this._flowApiService.getFlowActionConfigDetails(this.flowId).subscribe(
        (response) => {
          this.errorIconFunctionality(response['config']);
          this.getExecutionlog();
          this.getBreakPointValue(response['config']);
        },
        (err) => {}
      );
    }
  }

  getMappingDetails() {
    this._flowApiService.getMappings(this.flowId).subscribe((response) => {
      this.mappingDetailsOnInitComponenet = response;
      const res = response['mapping_list'];
      for (const elm in res) {
        if (res[elm]) {
          this.actionErrorIconConfig['Dimension Mapping']['isErrorIconShow'] = false;
          break;
        }
      }
    });
  }

  removeLoggedInUserFromUsersList() {
    this.users = this.users.filter((user) => this.username !== user.username);
    this.hasUsersLoaded = true;
  }

  errorIconFunctionality(res) {
    for (const elm in res) {
      const key =
        elm == 'kpi_timeframe_end' || elm == 'kpi_timeframe_start' || elm == 'insights'
          ? 'insights'
          : elm == 'concepts' || elm == 'cohorts'
          ? 'concept_cohort'
          : elm;
      if (((key == 'concept_cohort') != null && res['concepts'] != null) || res['cohorts'] != null) {
        this.actionErrorIconConfig[AlignName['concept_cohort']]['isErrorIconShow'] = false;
      }

      if (res[key] != null || res[key] != undefined) {
        if (key == 'dos_imputation_s3_path' && res[key] != null) {
          this.actionErrorIconConfig[AlignName['days_of_supply']]['isErrorIconShow'] = false;
        }
        if (key == 'grace_period_s3_path' && res[key] != null) {
          this.actionErrorIconConfig[AlignName['grace_period']]['isErrorIconShow'] = false;
        }
        if ((key == 'regimen_drop_length' || key == 'study_id') && res[key] != null) {
          this.actionErrorIconConfig[AlignName['regimen_drop_length']]['isErrorIconShow'] = false;
        }
        if (key == 'lot_rule' && res[key] != null) {
          this.actionErrorIconConfig[AlignName['lot_rule']]['isErrorIconShow'] = false;
        }
        if (key == 'alert_details' && res[key] != null) {
          this.actionErrorIconConfig[AlignName['alert_details']]['isErrorIconShow'] = false;
        }
        if (key == 'insights' && res['kpi_timeframe_end'] != null && res['kpi_timeframe_start'] != null && res['insights'] != null) {
          this.actionErrorIconConfig[AlignName['insights']]['isErrorIconShow'] = false;
        }
      } else {
        if (res[key] != null || (res[key] != undefined && AlignName.hasOwnProperty(key))) {
          this.actionErrorIconConfig[AlignName[key]]['isErrorIconShow'] = true;
        }
      }
    }
  }

  getFlowDetails() {
    this._flowApiService.getFlowDetails(this.flowId).subscribe((response: any) => {
      Object.assign(this.flowDetails, response.flow_details);
      this.collaborators = this._sensitiveInfoService.decrypt(response.flow_details.collaborators);
      this.getUsers();
    });
    this._flowApiService.getFlowActionsForFlow(this.flowId).subscribe((response: any) => {
      this.getFlowMetaData();
      this.nodes = response.nodes;
      this.connections = response.connections;
      this._customQueryService.initCustomQueryDetails(response.custom_queries);
      this._pythonModuleService.initPythonFilesDetails(response.python_files);
      this._featureModuleService.initFeatureDetails(response.feature_module);
      this._dataTransferService.initDataTransferDetails(response.data_transfer);
      this._invokeFlowService.initInvokeFlowDetails(response.invoke_flow);
      this.getComponentStatus(this.nodes);
      this.hideComponentIcon(this.nodes, response.feature_module);
    });
  }

  getFlowMetaData() {
    this._flowApiService.getFlowDetails(this.flowId).subscribe((response: any) => {
      Object.assign(this.flowDetails, response.flow_details);
      this.collaborators = this._sensitiveInfoService.decrypt(response.flow_details.collaborators);
      this.setTitle(this.flowDetails.flow_name);
      this.flow_created_by = response.flow_details.created_by;
    });
  }

  getComponentStatus(nodes) {
    nodes.forEach((node, idx) => (this.componentStatus[node['uuid']] = 'ideal'));
  }

  hideComponentIcon(nodes, res) {
    nodes.forEach((node) => {
      if (node['name'] == 'Feature Catalogue') {
        if (res[node['uuid']] && res[node['uuid']]['feature_id']) {
          this.actionErrorIconConfig['Feature Catalogue']['isErrorIconShow'] = false;
        }
      }
    });
  }
  // To hide perticular componenet
  // public hideActionsFromSelectedNode(node) {
  //   for(const elm in this.toHideActions) {
  //     if(node == elm) { break; }
  //     this.toHideActions[elm]=false;
  //   }
  // }

  public zsCardMoved = (event: CdkDragMove) => {
    this._pointerPosition = event.pointerPosition;
  };

  itemDropped(event: CdkDragDrop<any[]>) {
    // this.actionErrorIconConfig[event.item.data.name]['status'] = 'ideal';

    if (event.previousContainer === event.container) {
      moveItemInArray(this.nodes, event.previousIndex, event.currentIndex);
    } else {
      const node = event.item.data;
      node.uuid = `${node.name}_${Math.random().toString(16).slice(2)}`;
      node.top = this._pointerPosition.y - this.dragFlowContainer.nativeElement.getBoundingClientRect().top;
      node.left = this._pointerPosition.x - this.dragFlowContainer.nativeElement.getBoundingClientRect().left;
      this.updateNode({ ...node }, event.currentIndex);
      // To disable componenet on basis of selected componenet
      // This is for future purpose
      // this.hideActionsFromSelectedNode(event.item.data.name)
    }
  }

  updateNode(node: Node, index: number) {
    const flowActionDetails = this.getFlowActionDetails();
    this.nodes = flowActionDetails.nodes;
    this.connections = flowActionDetails.connections;
    this.nodes.splice(index, 0, node);
    this.nodes = [...this.nodes];
  }

  updateNodesAfterRemovalofNode(uuid: string) {
    const foundIndex = this.nodes.findIndex((node) => node.uuid === uuid);
    if (foundIndex !== -1) {
      this.nodes.splice(foundIndex, 1);
    }
    this.nodes = [...this.nodes];
  }

  public onSaveAndExit = () => {
    this.saveActionFlowDetails(true);
  };

  public cancel = () => {
    this.goToStudiesPage();
  };

  public getActions = () => {
    this._flowApiService.getActionsList().subscribe((res: any) => {
      this.allActions = res;
    });
  };

  isCyclicHelper(connections, node, visited, rec): boolean {
    if (rec[node]) {
      return true;
    }
    if (visited[node]) {
      return false;
    }
    visited[node] = true;
    rec[node] = true;
    let children = connections[node];
    if (children) {
      for (let cnode of children) {
        if (this.isCyclicHelper(connections, cnode, visited, rec)) {
          return true;
        }
      }
    }
    rec[node] = false;
    return false;
  }

  isCyclic(connections): boolean {
    let visited = {};
    let rec = {};
    Object.keys(connections).forEach((node) => {
      visited[node] = false;
      rec[node] = false;
    });
    for (let node of Object.keys(connections)) {
      if (this.isCyclicHelper(connections, node, visited, rec)) {
        return true;
      }
    }
    return false;
  }

  isMultipleFlows(connections, nodes): boolean {
    let node_list = [];
    nodes.forEach((node) => {
      node_list.push(node['uuid']);
    });
    let visited = [];
    let curr_visited = [node_list[0]];
    while (curr_visited.length) {
      let curr_node = curr_visited.pop();
      if (!visited.includes(curr_node)) {
        visited.push(curr_node);
        if (connections[curr_node]) {
          curr_visited = curr_visited.concat(connections[curr_node]);
        }
      }
    }
    for (let node of node_list) {
      if (!visited.includes(node)) {
        return true;
      }
    }
    return false;
  }

  isBranched(connections): boolean {
    for (let node of Object.keys(connections)) {
      if (connections[node].length > 1) {
        return true;
      }
    }
    return false;
  }

  validateFlow(flowActionDetails): boolean {
    var connections = {};
    var undirected_connections = {};
    flowActionDetails.connections.forEach((con) => {
      const pnode = con['uuids'][0].split('_').slice(0, -1).join('_');
      const cnode = con['uuids'][1].split('_').slice(0, -1).join('_');
      if (Object.keys(connections).includes(pnode)) {
        connections[pnode].push(cnode);
      } else {
        connections[pnode] = [cnode];
      }
      if (Object.keys(undirected_connections).includes(pnode)) {
        undirected_connections[pnode].push(cnode);
      } else {
        undirected_connections[pnode] = [cnode];
      }
      if (Object.keys(undirected_connections).includes(cnode)) {
        undirected_connections[cnode].push(pnode);
      } else {
        undirected_connections[cnode] = [pnode];
      }
    });
    if (this.isCyclic(connections)) {
      this.errorMessage = 'Cyclic flows cannot be saved';
      return false;
    }
    if (this.isMultipleFlows(undirected_connections, flowActionDetails.nodes)) {
      this.errorMessage = 'Multiple flows cannot be saved';
      return false;
    }
    if (this.isBranched(connections)) {
      this.isFlowBranched = true;
    }
    return true;
  }

  saveActionFlowDetailsHelper(flowActionDetails, isSaveAndExit) {
    const nodesList = flowActionDetails['nodes'].map((d) => d.name);
    if (nodesList.includes('Regimen Refinement' || 'Regimen Merge')) {
      if (!nodesList.includes('Regimen Creation')) {
        this.notifyService.showinfo('Regimen Creation Componenet is missing!', 'Info');
        return;
      }
    }

    flowActionDetails['custom_queries'] = this._customQueryService.getCustomQueryDetails();
    flowActionDetails['data_transfer'] = this._dataTransferService.getDataTransferDetails();
    flowActionDetails['invoke_flow'] = this._invokeFlowService.getInvokeFlowDetails();

    for (let val in flowActionDetails['custom_queries']) {
      if (!flowActionDetails['custom_queries'][val].query && !flowActionDetails['custom_queries'][val].s3_sql_txt_file_path) {
        //if no value given in both query and s3path for text file,below error throws
        this.notifyService.showinfo('Invalid custom query,Please enter either Query or S3 path for Query file', 'Info');
        return;
      }
      // if (flowActionDetails['custom_queries'][val].query && flowActionDetails['custom_queries'][val].s3_sql_txt_file_path){
      //   this.notifyService.showinfo('Invalid custom query,Entering both Query and S3 path for Query file is not allowed', 'Info');
      //   return;
      // }
    }

    flowActionDetails['python_files'] = this._pythonModuleService.getPythonFiles();

    flowActionDetails['feature_catalogue'] = this._featureModuleService.getFeatureDetails();

    flowActionDetails.nodes.forEach((node) => {
      delete node.job_type_id;
    });

    this.errorMessage = '';
    this._flowApiService.saveFlowActionsForFlow(this.flowId, flowActionDetails).subscribe((response: any) => {
      if (response.status == 'success') {
        this.notifyService.showSuccess(response.message, 'Notification');
        if (!isSaveAndExit) {
          this.saveExportDetail();
        }
      } else {
        this.errorMessage = response.message;
        return;
      }
    });
  }

  saveActionFlowDetails = (isSaveAndExit = false) => {
    const flowActionDetails = this.getFlowActionDetails();

    if (!this.validateFlow(flowActionDetails)) {
      return;
    }

    if (this.isBreakPointActive == true) {
      flowActionDetails['breakpoints'] = this._commonService.selectedBreakPointNodes;
      this._flowApiService.validateBreakpoints(this.flowId, flowActionDetails).subscribe((response: any) => {
        if (response.status === false) {
          this.errorMessage = response.message;
        } else {
          flowActionDetails.nodes.forEach((node) => {
            if (response.nodes.includes(node['uuid'])) {
              node['is_active'] = true;
            } else {
              node['is_active'] = false;
            }
          });
          if (this.isIntelligentBreakpointSelectionActive && !this.isFlowBranched) {
            const selectedPatientJourneyComponents = flowActionDetails.nodes.filter(
              (node) => Object.values(JOB_TYPE_ID['Patient Journey']).includes(node.job_type_id) && node.is_active
            );
            flowActionDetails.nodes.forEach((node, idx) => {
              if (Object.values(JOB_TYPE_ID['Patient Journey']).includes(node.job_type_id)) {
                node.is_active = true;
              }
            });

            flowActionDetails.nodes.forEach((node, idx) => {
              if (
                !node.is_active &&
                idx &&
                idx < flowActionDetails.nodes.length - 1 &&
                flowActionDetails.nodes[idx - 1].is_active &&
                flowActionDetails.nodes[idx + 1].is_active
              ) {
                node.is_active = true;
              }
            });
          }
          delete flowActionDetails['breakpoints'];
          this.saveActionFlowDetailsHelper(flowActionDetails, isSaveAndExit);
        }
      });
    } else {
      this.saveActionFlowDetailsHelper(flowActionDetails, isSaveAndExit);
    }
    this.flowActivityStatus = FLOW_ACTIVITY_TYPE.save;
  };

  public getSelectedComponents(nodesList) {
    var selectedBreakPointNodes = this._commonService.selectedBreakPointNodes;
    var sortedNodeList = selectedBreakPointNodes.sort((a, b) => (a.order_id > b.order_id ? 1 : -1)).map((node) => node.uuid);
    var selectedNodes = JSON.parse(JSON.stringify(nodesList));
    var startIndex = selectedNodes.findIndex((item) => item.uuid === sortedNodeList[0]);
    var endIndex = selectedNodes.findIndex((item) => item.uuid === sortedNodeList.slice(-1)[0]);
    var selectedComponents = selectedNodes.slice(
      startIndex < endIndex ? startIndex : endIndex,
      endIndex > startIndex ? endIndex + 1 : startIndex + 1
    );
    var FinalList = selectedComponents.sort((a, b) => (a.order > b.order ? 1 : -1)).map((node) => node.uuid);
    return FinalList;
  }

  public onSubmit = () => {
    if (!this.flowId) {
      this.notifyService.showError('Flow does not exists', 'Error');
      return;
    }
    this.saveActionFlowDetails();
  };

  public goToStudiesPage = (activity_type: string = FLOW_ACTIVITY_TYPE.idle) => {
    this.flowActivityStatus = activity_type;
    this._router.navigate([`/studies`]);
  };

  getFlowActionDetails() {
    const allnodelist = [];
    const nodes = Array.from(this.dragFlowContainer.nativeElement.querySelectorAll('.node')).map((node: HTMLDivElement) => {
      allnodelist.push(node.id);
      const actionName = node.id.split('_')[0];
      const actionDetails = this.allActions.find((action) => action.name === actionName);
      return {
        name: actionName,
        action_id: actionDetails ? actionDetails.action_id : null,
        uuid: node.id,
        top: node.offsetTop,
        left: node.offsetLeft,
        is_active: true,
        job_type_id: actionDetails.job_type_id,
      };
    });
    const connIds = (this.nodeService.jsPlumbInstance.getAllConnections() as any[]).map((conn) => [conn.sourceId, conn.targetId]);
    const connections = (this.nodeService.jsPlumbInstance.getAllConnections() as any[]).map((conn) => ({ uuids: conn.getUuids() }));

    const final_sorted_list = this.getOrderofNodes(connections, allnodelist);
    nodes.forEach((node, idx) => (node['order'] = final_sorted_list.indexOf(node.uuid + '_left') + 1));
    return { nodes, connections };
  }

  getOrderofNodes(connections, allnodelist) {
    const nodes_array = [];
    var firstnode = '';
    const final_sorted_list = [];
    connections.forEach((element) => {
      nodes_array.push(element.uuids);
    });

    var flatArray = Array.prototype.concat.apply([], nodes_array);
    allnodelist.forEach((elm) => {
      if (!flatArray.includes(elm + '_left')) {
        final_sorted_list.push(elm + '_left');
        firstnode = elm + '_right';
      }
    });

    allnodelist.forEach((no) => {
      nodes_array.forEach((el) => {
        if (el.includes(firstnode)) {
          final_sorted_list.push(el[1]);
          const v = el[1].replace('left', 'right');
          firstnode = v;
        }
      });
    });

    return final_sorted_list;
  }

  getOrderofNodes_old(nodes, connections) {
    const nodeIds = nodes.map((node) => node.uuid);
    nodeIds.forEach((nodeId) => {
      const elementfoundAtSrcIdx = connections.findIndex((conn) => conn[0] === nodeId);
      const elementfoundAtTgtIdx = connections.findIndex((conn) => conn[1] === nodeId);

      const elementFoundOnlyAtSrc = elementfoundAtSrcIdx !== -1 && elementfoundAtTgtIdx === -1 ? true : false;
      const elementFoundOnlyAtTgt = elementfoundAtSrcIdx === -1 && elementfoundAtTgtIdx !== -1 ? true : false;

      if (!this.orderedNodes.includes(nodeId)) {
        if (elementFoundOnlyAtSrc) {
          this.orderedNodes.splice(0, 0, connections[elementfoundAtSrcIdx][0]);
        } else if (elementFoundOnlyAtTgt) {
          this.orderedNodes.push(connections[elementfoundAtTgtIdx][1]);
        } else {
          if (connections[elementfoundAtSrcIdx]) {
            if (this.orderedNodes.length) {
              const foundIdx = this.orderedNodes.indexOf(nodeId);
              if (foundIdx !== -1) {
                this.orderedNodes.splice(foundIdx + 1, 0, connections[elementfoundAtSrcIdx][1]);
              } else {
                const foundTgtIdx = this.orderedNodes.indexOf(connections[elementfoundAtSrcIdx][1]);
                if (foundTgtIdx !== -1) {
                  this.orderedNodes.splice(foundTgtIdx, 0, connections[elementfoundAtSrcIdx][0]);
                }
              }
            } else {
              this.orderedNodes = this.orderedNodes.concat(connections[elementfoundAtSrcIdx]);
            }
          }
        }
      }
    });
    if (this.orderedNodes.length) {
      nodes.sort((a, b) => this.orderedNodes.indexOf(a.uuid) - this.orderedNodes.indexOf(b.uuid));
    }
  }

  saveExportDetail() {
    if (!this.flowId) {
      this.notifyService.showError('Flow does not exists', 'Error');
      return;
    }
    if (this.flowId && this.failedJobConfig.length !== 0 && !this._commonService.isBreakpointActive) {
      this.reExecuteTheFlow();
    } else {
      this.executeTheFlow();
    }
  }

  reExecuteTheFlow() {
    this._flowService.setFlowId(this.flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 're-execute-modal-content-wrapper'; // 'export-modal-content-wrapper';
    dialogConfig.height = 'auto';
    dialogConfig.width = '500px';
    dialogConfig.data = { flowId: this.flowId, id: 're-execute-modal-content-wrapper', payload: this.failedJobConfig };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result == false) {
        this.executeTheFlow();
      }
    });
  }

  executeTheFlow() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'export-modal-content-wrapper-submit';
    dialogConfig.height = '250px';
    dialogConfig.width = '500px';
    dialogConfig.data = { flowId: this.flowId, id: 'export-modal-content-wrapper-submit' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      this.executionStatus = '';
      if (result) {
        this.executionStatus = 'running';
        this.goToStudiesPage(FLOW_ACTIVITY_TYPE.save);
      } else {
        for (var elm in this.actionErrorIconConfig) {
          this.actionErrorIconConfig[elm]['status'] = 'ideal';
        }
      }
    });
  }

  getFlowOutputPath() {
    var flowId = this.flowId;
    this._flowService.setFlowId(this.flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'output-path-modal-component';
    dialogConfig.height = '500px';
    dialogConfig.width = '600px';
    dialogConfig.data = { flowId, id: 'output-path-modal-component' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  isDisabledIcon(isFlowDefinitionIcon: boolean = false) {
    if (isFlowDefinitionIcon && ['running', 'pending'].includes(this.executionStatus)) {
      return true;
    } else if (!isFlowDefinitionIcon && (!this.flowId || ['running', 'pending'].includes(this.executionStatus))) {
      return true;
    }
  }

  disabledIconForTermination() {
    if (!['running', 'pending'].includes(this.executionStatus)) {
      return true;
    }
  }

  terminationColor() {
    if (['running', 'pending'].includes(this.executionStatus)) {
      return true;
    }
  }
  enabledPinnedIcon() {
    if (this._commonService.isBreakpointActive == true) {
      return true;
    }
  }
  isDisabledRefreshIcon() {
    if (!this.flowId) {
      return true;
    }
  }

  openFlowDefinition() {
    const dialogRef = this.dialogAnimation.open(FlowDefinitionComponent, {
      height: '100%',
      width: '300px',
      data: {
        flowId: this.flowId,
        users: this.users,
        flowDetails: this.flowDetails,
        hasUsersLoaded: this.hasUsersLoaded,
        collaborators: this.collaborators,
        collaboratorsUsersListChanged: this.collaboratorsUsersListChanged,
      },
      animation: {
        incomingOptions: {
          keyframes: [{ transform: 'translate(300px)' }, { transform: 'translate(0px)' }],
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframes: [{ transform: 'translate(0px)' }, { transform: 'translate(300px)' }],
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
      position: { right: '0' },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      this.flowDetails = response.flowDetails;
      this.collaborators = response.collaborators;
      this.setTitle(this.flowDetails.flow_name ? this.flowDetails.flow_name : 'Orchestrator');
    });
  }

  terminateFlow() {
    var flowId = this.flowId;
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'terminate-flow-modal-content-wrapper';
    dialogConfig.height = '200px';
    dialogConfig.width = '500px';
    dialogConfig.data = { flowId, id: 'terminate-flow-modal-content-wrapper' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this._flowApiService.terminate_the_flow(flowId, { status: 'failed' }).subscribe(
          (response: any) => {
            if (response.status) {
              this.notifyService.showSuccess('The flow Terminated successfully', 'Notification');
              this.getExecutionlog();
            }
          },
          (err) => {
            this.notifyService.showError(err.error['message'], 'Error');
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.updateFlowActivity();
  }
}
