import { Component, OnInit } from '@angular/core';
import Loader from '../../../../zsui/src/loader/loader.m.js';
Loader.register();

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
})
export class LoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
