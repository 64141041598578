import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  private dataTransferDetails: object = {};
  private dataTransferSubject = new Subject<any>();
  dataTransferState = this.dataTransferSubject.asObservable();

  constructor() {}

  saveDataTransferDetails(uuid: string, dataTransfer: object) {
    if (uuid && dataTransfer) {
      this.dataTransferDetails[uuid] = dataTransfer;
      this.dataTransferSubject.next({
        dataTransferName: this.dataTransferDetails[uuid][0].name,
        dataTransferUuid: uuid,
      } as any);
    }
  }

  initDataTransferDetails(dataTransferDetails: {}) {
    this.dataTransferDetails = dataTransferDetails;
  }

  getDataTransferDetails(uuid: string = null) {
    if (uuid) {
      return this.dataTransferDetails[uuid];
    }
    return this.dataTransferDetails;
  }

  removeNode(nodeName: string) {
    delete this.dataTransferDetails[nodeName];
  }
}
