import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { any } from './loader';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderSubject = new Subject<any>();
  loaderState = this.loaderSubject.asObservable();
  constructor() {}
  show() {
    this.loaderSubject.next({ loaded: true } as any);
  }
  hide() {
    this.loaderSubject.next({ loaded: false } as any);
  }
}
