import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorService } from "../_services/error.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from '../_services/authentication.service';
import { CookieService } from "../_services/cookie.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  OAuthHost = environment.authConfig.issuer;

  constructor(
    private authService: AuthenticationService,
    private _errorService: ErrorService,
    private cookieService: CookieService
    ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const req = request.clone({
      withCredentials: true,
    });
    return next.handle(this.custom_request(request)).pipe(
      catchError((error) => {
        // comment
        // if (error.status === 401) {
        //   window.location.href = environment.idm_logout_url + location.href;
        // }
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\n Message: ${error.message}`;
        }
        this._errorService.setDisappearingMessages([errorMessage]);
        return throwError(error);
      })
    );
  }

  
  custom_request(request: any) {let url = request.url;
    if (url.includes(this.OAuthHost)) {
      const reqClone = request.clone({});
      return reqClone;
    } else {
      let headers = request.headers;
      if (environment.authEnabled) {
        headers = headers
          .set(
            'Authorization',
            `Bearer ${
              this.cookieService.get(
                this.authService.accessTokenKey
              ) || ''
            }`
          )
          .set(
            'X-ID-Token',
            `Bearer ${
              this.cookieService.get(this.authService.idTokenKey) ||
              ''
            }`
          );
      }
      const reqClone = request.clone({
        withCredentials: true,
        headers: headers,
      });
      return reqClone;
    }
  }
}
