import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  flowId: number;
  flowName: string;
  userRole: string;
  flowStatus: string;

  constructor() {}

  getFlowId() {
    return this.flowId;
  }

  setFlowId(flowId: number) {
    this.flowId = +flowId;
  }

  setUserRole(role: string) {
    this.userRole = role;
  }

  getUserRole() {
    return this.userRole;
  }

  setFlowName(name: string) {
    this.flowName = name;
  }

  getFlowName() {
    return this.flowName;
  }

  resetFlowDetails() {
    this.flowId = null;
    this.flowName = null;
    this.userRole = null;
  }

  setFlowStatus(status: string) {
    this.flowStatus = status;
  }

  getFlowStatus() {
    return this.flowStatus ;
  }
}
