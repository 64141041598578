import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from './_services/error.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FlowService } from './_services/flow.service';
import { Subscription } from 'rxjs';
import { CanvasModuleService } from './_services/canvas-module.service';
import { LoaderService } from './_services/loader.service';
import { FlowApiService } from './_services/api/flow.api.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CookieService } from 'src/app/_services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: deprecation
// tslint:disable: forin
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  errorMessages;
  private flowId;

  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  authServiceSubs: any;

  public httpErrorMessage = '';
  public errorMsgLog = [];
  public isLoading = true;
  public globalLoader = false;
  private _loaderSubscription: Subscription;
  private routerSubscription: Subscription;
  public httpRequestComplete = false;
  public showInfoMessage = false;
  public infoMessage = null;
  public selectedProject = 'No Project Selected'

  environment = environment.environment;
  selectedRegion = 'US';

  constructor(
    private _errorService: ErrorService,
    private _router: Router,
    private _loaderService: LoaderService,
    public _canvasService: CanvasModuleService,
    private _activatedRoute: ActivatedRoute,
    private _flowService: FlowService,
    private _flowApiService: FlowApiService,
    private authService: AuthenticationService,
    private cookieService: CookieService
  ) {}

  private parentRoutes = ['/studies', '/studies/create'];
  public topNavStates = {
    studies: false,
    create: false,
    edit: false,
  };

  ngOnInit() {
    if (environment.authEnabled) {
      this.authenticateUser();
      if (this.cookieService.get(this.authService.accessTokenKey)) {
        this.showErrorMessage = false;
      } else {
        const code = this.getUrlParam('code');
        if (code === 'undefined' || !code) {
            this.authService.login();
        } else if (code) {
            this._router.navigate([], {
            queryParams: {
                yourParamName: null,
                youCanRemoveMultiple: null,
            },
            queryParamsHandling: 'merge',
            });
            this.authService.getAccessToken(code);
        }
      }
    } else {
      this.showErrorMessage = false;
    }
    this.errorMessages = this._errorService.getMessages();
    if(this.environment === 'demo'){
    this.getRegion();
    }
    this.createLoaderSubscription();
    this.showErrorMessages();
    this.handleRouteEvents();
    this.getProjectName();
  }

  private getProjectName() {
    const PROJECT_ID = this.cookieService.get('project_id');
    console.log({PROJECT_ID})
    if (environment.homeUrl) {
      console.log('Getting Project Name')
      this._flowApiService.getProjectList().subscribe((res: any) => {
        res.data.forEach(projects => {
          if (projects.project_id == PROJECT_ID) {
            console.log(projects)
            this.selectedProject = projects.project_name;
          }
        });
      });
    }
  }

  private createLoaderSubscription() {
    this._loaderSubscription = this._loaderService.loaderState.subscribe((state: any) => {
      this.globalLoader = state.loaded;
    });
  }

  private addErrorMessage(eventData: any, id: number) {
    const existingError = this.errorMsgLog.find((errorMessage) => errorMessage === eventData.errorMessage);
    if (this.errorMsgLog.length && !existingError) {
      return;
    }
    const errorList = {
      error: eventData.errorMessage,
      errorId: id,
    };
    this.errorMsgLog.push(errorList);
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  handleRouteEvents() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoading = true;
        this.handleParentRoutes(event);
        this.decideViewportHeight(event);
        this.setTopNavStates(event);
        this.flowId = this.getFlowIdFromRouteParams();
        if (this.flowId) {
          this._flowService.setFlowId(this.flowId);
          this.setUserRole();
        }
      }
    });
  }

  private handleParentRoutes(event: NavigationEnd) {
    if (this.parentRoutes.includes(event.urlAfterRedirects)) {
      this.resetFlowDetails();
    }
  }

  resetFlowDetails() {
    this._flowService.resetFlowDetails();
    this.isLoading = false;
  }

  private getFlowIdFromRouteParams() {
    return (
      (this._activatedRoute.firstChild &&
        this._activatedRoute.firstChild.firstChild &&
        this._activatedRoute.firstChild.firstChild.snapshot &&
        this._activatedRoute.firstChild.firstChild.snapshot.params &&
        this._activatedRoute.firstChild.firstChild.snapshot.params.flowId) ||
      null
    );
  }

  private decideViewportHeight(event: NavigationEnd) {
    const viewSection = document.getElementById('view-port');
    viewSection.style.margin = event.urlAfterRedirects === '/studies' ? '0em 1em 1em 1em' : '0em 1em 5em 1em';
  }

  private setTopNavStates(event: NavigationEnd) {
    const url = event.url;
    if (url === '/') {
      this.topNavStates.studies = true;
      return;
    }
    const activeUrl = url.split('/').pop();
    for (const key in this.topNavStates) {
      this.topNavStates[key] = activeUrl === key;
    }
  }

  private isParentRoute(event: NavigationEnd) {
    return this.parentRoutes.includes(event.urlAfterRedirects);
  }

  private getUniqueId() {
    const uniqueId: any = new Date(new Date());
    return uniqueId / 1000 + Math.random();
  }

  private setUserRole() {
    this._flowApiService.getUserRole(this.flowId).subscribe(
      (response: any) => {
        this._flowService.setUserRole(response.data.role);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private getRegion() {
    this._flowApiService.getRegion().subscribe((res:any) => {
      if (res) {
        this.selectedRegion = res.data['region'];
      }
    });
  }

  private removeErrorMessageAfterTenSeconds(id: number) {
    setTimeout(() => {
      if (document.getElementById(`${id}`)) {
        document.getElementById(`${id}`).remove();
      }
    }, 10000);
  }

  showErrorMessages() {
    this._canvasService.httpRequestEvent.subscribe((eventData) => {
      if (eventData.type === 'httpErrorMessage') {
        const id = this.getUniqueId();
        this.addErrorMessage(eventData, id);
        this.removeErrorMessageAfterTenSeconds(id);
      } else if (eventData.type === 'requestCounter') {
        this.httpRequestComplete = eventData.value;
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this._loaderSubscription.unsubscribe();
  }

  ngAfterContentChecked() {
    if (this.globalLoader !== this.httpRequestComplete) {
      setTimeout(() => {
        this.globalLoader = this.httpRequestComplete;
      });
    }
  }
}
