import * as events from './events.m.js';
import { mix } from './mixin.m.js';
import syncProps from './syncProps.m.js';

/**
 * BaseElement would extend HTMLElement interface to make it compatible with Babel
 * @param {HTMLElement} Base 
 */
function BaseElementMixin(Base) {

	if (Base.__isBase) {
		return Base;
	}

	return class BaseElement extends Base {
		constructor(...args) {
			const _ = super(...args);
			return _;
		}

		static get __isBase() {return true;} 
	}
}

/**
 * Extends any base class as a Web component and applies best practices. 
 * @param {Instance of HTMLElement} Base - Class to extend. Expected to be an instance of HTMLElement. 
 * @example let MyComponent = ComponentMixin(HTMLElement);
 */
export default function ComponentMixin(Base) {
	class Component extends BaseElementMixin(Base) {
		/**
		 * Create an instance. Custom Elements API requirements.
		 * @param  {...any} args 
		 */
		constructor(...args) {			
			const _ = super(...args);
			_._init();
			return _;
		}

		/**
		 * Helps to normalize `is` attribute value across browsers.
		 * @private 
		 */
		_patchIs() {
			if (this.parentNode && this._is && this._tagName) { // Keep in mind that having a parentNode doesn't mean being connected to the DOM (isConnected)
				if (this.getAttribute('is') != this._is) {
					this.setAttribute('is', this._is);
				}
			}
		}

		/**
		 * Called in the constructor and  [constructor caveat](https://github.com/ungap/custom-elements-builtin)
		 * @returns {Instance of HTMLElement} - Itself to enable chaining
		 * @example let el = document.createElement('my-element')._init();
		 * @private
		 */
		_init() {
			// Patch is first
			this._patchIs();
			

			if (this._isCreated) {
				return this;
			} else {
				this._defineProperties();
				this.subscribe();
				this.setup();
				this._isCreated = true;
				
			}
			return this;
		}

		/**
		 * Setup is called once after element is created or attached to the DOM. Lifecycle.
		 */
		setup() {			
		}

		/**
		 * Add event listeners. Lifecycle
		 */
		subscribe() {
		}

		/**
		 * Remove event listeners
		 */
		unsubscribe() {
		}

		/**
		 * Property descriptors of an instance
		 */
		get properties() {
			if (this._properties) {return this._properties}
			
			var Cls = window.customElements.get(this._is);
			this._properties = Cls.properties  || undefined;
			
			// Normalize properties
			Object.keys(this._properties || []).forEach(key => {
				if (typeof this._properties[key] != 'object') {
					this._properties[key] = {type: this._properties[key]};
				}
				
				if (typeof this._properties[key].type == 'function') {
					// IE11 doesn't support `Function.name` or `Object.name`
					switch (this._properties[key].type) {
						case Object: 
							this._properties[key].type = 'object';
							break;
						case Function:
							this._properties[key].type = 'function';
							break;
						case Boolean: 
							this._properties[key].type = 'boolean';
							break;
						case Number: 	
							this._properties[key].type = 'number';
							break;	
						case Array:
							this._properties[key].type = 'array';
							break;
						case String:
							this._properties[key].type = 'string';
							break;
					}				
				}

				this._properties[key].type = this._properties[key].type || 'string'; // Default is string
				
				this._properties[key].reflect = this._properties[key].reflect || false; // Reflect is false by default

				if (this._properties[key].type  == 'object') { // Can't reflect complex types
					this._properties[key].reflect = false;
				}

			});

			return this._properties;
		}

		/**
		 * Descriptors to define properties and observed attributes with an option to synchronize values of attributes and properties and observe changes
		 * @type Object
		 * @example {type: String, default: 'my value', reflect: true, onChange: console.log}
		 */
		static get properties() {
			return undefined;
		}

			

		/**
		 * Define properties 
		 * @type {Object} - Properties descriptors
		 * @private
		 */
		_defineProperties(props) {
			props = props || this.properties;			

			Object.keys(props || []).forEach(name => {
				let prop = props[name];

				this.syncProp(name, prop.reflect === true, prop.default);
			});
		}

		/**
		 *	Return an array of observed attributes and enable composition. Custom Elements API.
		 *  @type {Array}
		 */
		static get observedAttributes() {
			let props = this.properties;
			let arr = super.observedAttributes || [];
			let result = Object.keys(props || []).reduce((results, name) => {
				let prop = props[name];
				if (prop && prop.reflect && prop.type ) {
					results.push(syncProps.propToAttrName(name));
				}
				return results;
			}, []);
			return arr.concat(result);
		}

		/** @type {String}  */
		static get tagName() {
			return this.prototype._tagName;
		}
		static set tagName(newValue) {
			this.prototype._tagName = newValue;
		}

		/** @type {String}  */
		static get is() {
			return this.prototype._is;
		}
		static set is(newValue) {
			this.prototype._is = newValue;
		}

		/** @type {Boolean} */
		static get isRegistered() {
			return window.customElements.get(this.is) != null;
		}

		/** Constructor of the instance */
		static get() {
			return window.customElements.get(this.is);
		}

		/**
		 * Triggered when observed attribute was changed. Custom Elements API.
		 * @param {String} name Name of the changed attribute in lower dash case format `my-attribute`
		 * @param {String} oldValue Previous value before the change
		 * @param {String} newValue New value after the change
		 */
		attributeChangedCallback(name, oldValue, newValue) {
			if (typeof super.attributeChangedCallback == 'function') {
				super.attributeChangedCallback(name, oldValue, newValue);
			}
			this._init(); // Ensure to call init first before using any of the properties.

			// Detect the property type
			var props = this.properties;
			if (!props) { return; }

			let propName = this.attrToPropName(name);
			let prop = props[propName];
			if (!prop) { return; }
			let propType = null;
			if (typeof prop == 'function') {
				propType = prop.name.toLowerCase();
			} else if (typeof prop == 'object') {
				propType = typeof prop.type == 'function' ? prop.type.name.toLowerCase() : prop.type;
			}

			this.syncAttr(name, newValue, propType);
		}

		/**
		 * Register a custom element. A shortcut to customElement.define API. Helps to keep consistent behavior of custom elements in browsers.
		 * @param {?String} is Name of the new custom element `<my-element>` or `<div is="my-element">`
		 * @param {?String} tagName Name of the tag use only when customizing built-ins e.g. `<div is="my-element">`
		 */
		static register(is, tagName) {
			let Cls = this;
			
			is = is || Cls.is;
			
			tagName === undefined ? tagName = Cls.tagName : undefined;
			if (tagName) {
				Cls.is = is;
				Cls.tagName = tagName;
			} else {
				Cls.is = is;
				Cls.tagName = null;
			}
			if (Cls.isRegistered) {throw `Custom element  ${Cls.is} is already registered`;}			
			return customElements.define(Cls.is, Cls, Cls.tagName ? { extends: Cls.tagName } : undefined);
		}

		/**
		 * Create an instance of the component. Since tag name can be set externally we need a way to create an instance.
		 * @example MyComponent.register('my-element'); let el = MyComponent.create();
		 */
		static create() {
			let Cls = this;
			if (!Cls.isRegistered) {throw 'Custom element ' + Cls.is + ' is not registered';}
			if (!Cls.is) {throw 'Name is undefined';}
			let el = document.createElement(
				Cls.tagName ? Cls.tagName : Cls.is,
				Cls.tagName ? { is: Cls.is } : undefined
			);
			el._init(); 
			return el;
		}
		
		/**
		 * Placeholder for the component root node. Required to support ShadowDom.
		 */
		get root() {
			return this;
		}

		/**
		 * Destroy an instance of the component. Detach it from the DOM. Remove all event listeners. Helps to prevent memory leaks.
		 * @example el.destroy(); el = null;
		 */
		destroy() {
			this.unsubscribe();
			this.root.parentElement && this.root.parentElement.removeChild(this);
		}

		/**
		 * Triggered when an element is attached to the DOM. Custom Elements API. 
		 */
		connectedCallback() {			
			if (typeof super.connectedCallback == 'function') {
				super.connectedCallback();
			}
			this._init();
		}
		
		/**
		 * Sanitize the DOM. We use a sanitizer registered on the Class.
		 * @param {String} dirtyString - String to sanitize 
		 * @params {?Object} options - Optional configuration.
		 * @return {String} - Safe string
		 */
		sanitize(dirtyString, options) {
			var Cls = window.customElements.get(this._is);
			if (typeof Cls.sanitize == 'function') {
				return Cls.sanitize(dirtyString, options);
			} else {
				return dirtyString;
			}
		}

		/**
		 * Placeholder to remove unsafe content from the string before using it in the DOM. Bypass the string by default. Register your custom sanitizer here.
		 * @param {String} dirtyString - String to sanitize 
		 * @params {?Object} options - Optional configuration.
		 * @return {String} - Safe string
		 */
		static sanitize(dirtyString, options) {
			options; // ESLint
			return dirtyString;
		}

		/**
		 * Triggered when property or attribute is changed
		 * @param {string} name Name of the changed property in lower camel case format
		 * @param {any} oldValue Previous value before the change
		 * @param {any} newValue New value after the change
		 */
		propertyChangedCallback(name, oldValue, newValue) { 
			/* ESlint compliance */ name; oldValue; newValue; 
		}

	}

	delete syncProps.sanitize; // !

	// Mix in extensions using Object composition
	mix(Component.prototype, events, syncProps);

	return Component;
}
