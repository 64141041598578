import ComponentMixin from '../smart/component.m.js';

// We need a Base class for composition 
class Base extends HTMLTableElement {
	constructor(...args) {
		super(...args);
	}
}


/**
 * Table component
 * @extends {HTMLTableElement}
 * @example let table = document.createElement('table', {is: 'zs-table'});
 */
export default class Table extends ComponentMixin(Base) {
	constructor(...args) {
		// Constructor caveat https://github.com/WebReflection/document-register-element/
		const _ = super(...args);
		return _;
	}

	/**
	 * Use this method with or instead of the constructor to normalize the flow across browsers. 
	 * Call it in the `constructor`, in the `attributeChangeCallback` and in the `connectedCallback` before any other methods or immediately after `document.createElement`
	 */
	setup() {
		super.setup();


		/** @type {object} columns - Get settings for columns of the table. Enables formatting, labeling and sorting of columns. Supports column groups. Set it using column groups configurations. */
		this._columns = undefined;

		/** @type {string} selectionMode - Turn on cells selection. Accepts values `cell`, `row`, `column`, `multi`. */
		this.syncProp('selectionMode');

		/** @type {string} sortColumn - Set or get a column name the table is sorted by  */
		this.syncProp('sortColumn');

		/** @type {string} sortOrder - Set or get a current sort order. Possible values include `asc`, `desc` and empty string. */
		this.syncProp('sortOrder');

		// Double click is not supported on iOS.
		var ua = window.navigator.userAgent;
		var isIpad = (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i)) && !!ua.match(/WebKit/i);
		if (isIpad) {
			this._dblTapDelay = 500;
		} else {
			this._dblTapDelay = 0;
		}

		this.on(['sort', 'connect', 'click', 'select', 'render', 'focus', 'blur', 'keydown', 'mouseover', 'mouseout', 'dblclick', 'touchstart'], this);
		return this;
	}

	/**
	 * @type {array} observedAttributes - Observed attributes. Custom Elements API.
	 */
	static get observedAttributes() {
		let result = super.observedAttributes || [];
		result = result.concat(['onconnect', 'selection-mode', 'onrender', 'onselect', 'onsort', 'sort-column', 'sort-order']);
		return result; 
	}


	/**
	 * Click event handler. 
	 * @todo implement double tap as double click
	 * @param {MouseEvent} event 
	 */
	onclick(event) {
		if (event.target.tagName == 'A' && event.target.getAttribute('sort') != null) { // sort anchor clicked
			let a = event.target;
			let columnName = a.parentElement.getAttribute('key');
			let order = a.getAttribute('sort');
			order = order == 'asc' ? 'desc' : 'asc'; // toggle order
			if (columnName != this.sortColumn || order != this.sortOrder) {
				this.sortColumn = columnName;
				this.sortOrder = order;
				this.fire('sort', { bubbles: true, detail: { columnName: this.sortColumn, order: this.sortOrder } });
			}
		}
		if (event.target.tagName != 'TD' && event.target.tagName != 'TH') { return; }
		if (this.selectionMode && event.target != document.activeElement) {
			event.target.focus(); // focus on touch
			this.fire('focus', { bubbles: true, cancelable: true }, event.target);
		}
		if (this.selectionMode) { // select
			this.applySelection(event.target);
		}
	}

	ontouchstart(event) {
		// mimic dbl click when not supported on iOS devices
		if (event.target._possibleDblTap) {
			let delta = performance.now() - event.target._possibleDblTap;
			if (delta > this._dblTapDelay) {
				event.target._possibleDblTap = null;
			} else {
				event.target._possibleDblTap = null;
				event.target.dispatchEvent(new MouseEvent('dblclick', {
					'view': window,
					'bubbles': true,
					'cancelable': true
				}));
			}
		} else if (this._dblTapDelay) {
			event.target._possibleDblTap = performance.now();
		}
	}

	/**
	 * Decide how to perform selection based on the mode and targeted table cell. Part of the selection feature.
	 * @param {HTMLTableCellElement|HTMLTableHeaderCellElement} cell Target cell
	 */
	applySelection(cell) {
		if (!cell) { return; }
		if (cell.tagName != 'TD' && cell.tagName != 'TH') { return; }
		let columnName = cell.getAttribute('key');
		let rowIndex = cell.parentElement.getAttribute('key');
		let mode = this._selectionModeObject;
		let isSelected = cell.getAttribute('mark') != null;
		if (!mode.multi) { this.clearSelection(); }
		if (mode.row) {
			this.select(~~rowIndex, undefined, isSelected);
		} else if (mode.cell) {
			this.select(~~rowIndex, columnName, isSelected);
		} else if (mode.column) {
			this.select(undefined, columnName, isSelected)
		}
	}

	/**
	 * Find a cell by column name
	 * @param {HTMLTableCellElement} startCell Starting cell
	 * @param {string} columnName Name of the column to look for
	 * @param {?boolean} backward Look backward
	 * @retuns {HTMLTableCellElement} Found cell or undefined
	 */
	findCell(startCell, columnName, backward) {
		let found = null, end = false;
		let cell = startCell;
		if (!columnName) { return }
		while (!found && !end) {
			if (backward) { cell = this.findPrev(cell); } else { cell = this.findNext(cell); }
			if (!cell) { return; }
			if (cell.getAttribute('key') == columnName) { return cell; }
		}
		return found;
	}

	/**
	 * Find previous cell
	 * @param {HTMLTableCellElement} cell Current cell to start looking from
	 * @retuns {HTMLTableCellElement} Found cell or undefined
	 */
	findPrev(cell) {
		if (cell.previousElementSibling) { return cell.previousElementSibling };
		if (!cell.parentElement.previousElementSibling) { return; } // last row
		return cell.parentElement.previousElementSibling.lastElementChild;
	}

	/**
	 * Find next cell
	 * @param {HTMLTableCellElement} cell Current cell to start looking from
	 * @retuns {HTMLTableCellElement} Found cell or undefined
	 */
	findNext(cell) {
		if (cell.nextElementSibling) { return cell.nextElementSibling };
		if (!cell.parentElement.nextElementSibling) { return; } // last row
		return cell.parentElement.nextElementSibling.firstElementChild;
	}

	/**
	 * Keydown event handler to navigate the cells and enter or select a cell using keyboard
	 * @param {KeyboardEvent} event 
	 */
	onkeydown(event) {
		if (event.target.tagName != 'TD' && event.target.tagName != 'TH') { return; }

		// Navigate cells 
		if (event.target == document.activeElement) {
			var nextCell;
			if (event.keyCode == 9 && event.shiftKey || event.keyCode == 37) { // Left
				nextCell = this.findPrev(event.target);
				event.preventDefault();
				event.stopPropagation();
			} else if (event.keyCode == 9 || event.keyCode == 39) {// Right
				event.preventDefault();
				event.stopPropagation();
				nextCell = this.findNext(event.target);
			} else if (event.keyCode == 38) { // Up
				nextCell = this.findCell(event.target, event.target.getAttribute('key'), true);
			} else if (event.keyCode == 40) { // Down
				nextCell = this.findCell(event.target, event.target.getAttribute('key'));
			} else if (event.keyCode == 27) { // ESC
				event.target.blur();
				this.fire('blur', { bubbles: true, cancelable: true }, event.target);
			} else if (event.keyCode == 13) { // Enter
				// Double click
				let event = new MouseEvent('dblclick', {
					'view': window,
					'bubbles': true,
					'cancelable': true
				});
				document.activeElement.dispatchEvent(event);
			} else if (event.keyCode == 32) { // Space
				// Click
				event.target.click();
			}

			// Found next cell
			if (nextCell) {
				nextCell.focus();
				this.fire('focus', { bubbles: true, cancelable: true }, nextCell);
			}
		}
	}

	/**
	 * Mouse over event handler. Helps to give focus to cells.
	 * @param {MouseEvent} event 
	 */
	onmouseover(event) {

		if (event.target.tagName != 'TD' && event.target.tagName != 'TH') { return; }
		if (this.selectionMode && event.target.tabIndex == -1 && event.target != document.activeElement) {
			event.target.focus();
			this.fire('focus', { bubbles: true, cancelable: true }, event.target);
		}
	}

	/**
	 * Mouse out event handler. Helps to remove focus from cells.
	 * @param {MouseEvent} event 
	 */
	onmouseout(event) {
		if (document.activeElement &&
			(document.activeElement.tagName == 'TD' || document.activeElement.tagName == 'TH') && this.contains(document.activeElement)) {
			event.target.blur();
			this.fire('blur', { bubbles: true, cancelable: true }, event.target); // Manual blur doesn't fire on cells
		}
	}

	/**
	 * Callback triggered when synchronized property or attribute was changed.
	 * @param {string} name Property name in lower camel case e.g. `myProperty`
	 * @param {number|boolean|string} oldValue Old value of the property. 
	 * @param {number|boolean|string} newValue New value of the property.
	 */
	propertyChangedCallback(name, oldValue, newValue) {
		var sortColumn, sortOrder;
		switch (name) {
			case 'sortColumn':
				sortColumn = newValue;
			case 'sortOrder':
				if (name == 'sortOrder') {
					sortOrder = newValue;
				}

				// Don't have sortColumn defined yet
				if (!(this.sortColumn || sortColumn)) { break; }

				// Update sort indicators
				this.querySelectorAll('a[sort]').forEach(el => {
					let columnName = el.parentElement.getAttribute('key');
					if (columnName == (sortColumn || this.sortColumn)) {
						el.setAttribute('sort', this.sortOrder || sortOrder || '');
					} else {
						el.setAttribute('sort', '');
					}
				});
				break;
			case 'selectionMode':
				console.log('selectionMode', newValue);
				this._selectionModeObject = {
					row: newValue && newValue.indexOf('row') != -1,
					column: newValue && newValue.indexOf('column') != -1,
					cell: newValue && newValue.indexOf('cell') != -1,
					multi: newValue && newValue.indexOf('multi') != -1
				}
				if (newValue == '' || newValue == null) {
					this.root.querySelectorAll('[tabindex]').forEach(el => el.tabIndex = null);
				} else {
					this.tbody.querySelectorAll('td[key]').forEach(el => el.tabIndex = -1)
				}
		}
	}

	/**
	 * Callback triggered when an observed attributed was changed. Custom Elements API.	 
	 * @param {string} name Attribute name in dash lower case e.g. `my-attribute`
	 * @param {string} oldValue Old value of the attribute
	 * @param {string} newValue New value of the attribute
	 */
	attributeChangedCallback(name, oldValue, newValue) {
		super.attributeChangedCallback(name, oldValue, newValue);
		switch (name) {
			case 'sort-column':
			case 'sort-order':
			case 'selection-mode':
				this.syncAttr(name, newValue);
				break;
			case 'onconnect':
			case 'onrender':
			case 'onselect':
			case 'onsort':
				let _onabort = this.onabort;
				let _onabortAttr = this.getAttribute('onabort');
				this.setAttribute('onabort', newValue);
				this[name] = this.onabort;
				this.onabort = _onabort;
				if (_onabortAttr == null) {
					this.removeAttribute('onabort');
				} else {
					this.setAttribute('onabort', _onabortAttr);
				}
				break;
		}
	}

	/**
	 * Callback triggered when this element is attached to the DOM. Can be called multiple times. Custom Elements API.
	 */
	connectedCallback() {
		super.connectedCallback();
		this.fire('connect');
	}


	/**
	 * Root element of the table
	 * @type {HTMLTableElement}
	 */
	get root() {
		return this;
	}

	/**
	 * Creates  a table row and adds cells to it using data and columns configuration.
	 * @param {number} rowIndex Index of the row.
	 * @param {(Array|object)} rowData Data required to render the row.
	 * @param {object} columns Hash-table of columns properties
	 * @returns {HTMLElement} Table row.
	 */
	createRow(rowIndex, rowData, columns) {
		var tr = document.createElement('tr');
		tr.setAttribute('key', rowIndex);
		if (!columns || typeof columns != 'object') { throw 'Invalid columns'; }
		for (name in columns) {
			let column = columns[name];
			let data = rowData[name];
			tr.appendChild(this.createCell(rowIndex, name, data, column));
		};
		return tr;
	}

	/**
	 * Creates and renders the table header row.
	 * @returns {HTMLElement} Returns HTML tr element.
	 */
	createHeadRow(columns) {
		if (!columns || typeof columns != 'object') { return tr; }
		var tr = document.createElement('tr');

		for (let key in columns) {
			tr.appendChild(this.createHeadCell(columns[key]));
		}
		return tr;
	}

	/**
	 * Creates and renders the header cell.
	 * @param {number} rowIndex Index of the row.
	 * @param {number} colIndex Index of the column.
	 * @param {any} data Cell data based on the table data array.
	 * @returns {HTMLElement} Returns HTML td element.
	 */
	createHeadCell(column) {
		if (!column || typeof column != 'object') { throw 'Invalid column'; }
		var th = document.createElement('td');
		th.setAttribute('key', column.name);
		if (column.colspan) {
			th.setAttribute('colspan', column.colspan);
		}
		if (column.scope) {
			th.setAttribute('scope', column.scope);
		}
		let label = column.label || column.name;
		if (column.sortOrder !== undefined) {
			label = `<a href="#" onclick="return false;" sort="${column.sortOrder || ''}">${label}</a>`;
		}
		th.innerHTML = label;
		return th;
	}

	/**
	 * Create a table body cell
	 * @param {number} rowIndex Index of the row.
	 * @param {number} colIndex Index of the column.
	 * @param {any} data Cell data based on the table data array.
	 * @column {?object} column Optional column definition object
	 * @returns {HTMLTableCellElement} Table cell element.
	 */
	createCell(rowIndex, colIndex, data, column) {
		var td = document.createElement('td');
		this.updateCell(td, data, column);
		return td;
	}

	/**
	 * Update contents of a table row
	 * @param {HTMLTableRowElement} tr Row to update
	 * @param {object} rowData Record with data
	 * @param {object} columns Hash-table with columns configurations
	 */
	updateRow(tr, rowData, columns) {
		if (!tr || tr instanceof HTMLTableRowElement == false) { return; }
		if (!rowData || typeof rowData != 'object') { return; }
		if (!columns || typeof columns != 'object') { throw 'Invalid columns'; }

		for (let name in rowData) {
			let column = columns[name];
			let data = rowData[name];
			if (column) {
				let td = tr.querySelector('[key="' + name + '"]');
				if (td) {
					this.updateCell(td, data, column);
				};
			}
		};
	}

	/**
	 * Update table or its part with new contents
	 * @param {array} newData Record set with data
	 * @param {?number} rowIndex Optional starting row index.  
	 */
	update(newData, rowIndex) {
		if (!Array.isArray(newData)) { return; }
		if (!this.tbody || !this.tbody.rows.length) { return; }
		for (let i = 0; i < newData.length; i++) {
			let tr = this.tbody.rows[(rowIndex || 0) + i];
			let rowData = newData[i];
			if (tr && rowData) {
				this.updateRow(tr, rowData, this.columns);
			}
		}
	}

	/**
	 * Update a cell content
	 * @param {HTMLTableCellElement} td Table cell to update
	 * @param {any} data Contents. 
	 * @param {object} column Column configuration object
	 */
	updateCell(td, data, column) {
		if (column && typeof column.format == 'function') {
			data = column.format(data);
		}
		if (column && column.scope) {
			td.setAttribute('scope', column.scope);
		}
		if (column && column.name) {
			td.setAttribute('key', column.name);
		}
		if (this.selectionMode != '' && this.selectionMode != null) {
			td.tabIndex = -1;
		}
		td.innerHTML = data == null ? '' : data;
	}

	/**
	 * Clear table
	 */
	clear() {
		this.columns = null;
		this.root.innerHTML = '';
	}

	/**
	 * Table head section
	 * @type {HTMLTableSectionElement}
	 */
	get thead() {
		return this.createTHead();
	}

	/**
	 * Table body section
	 * @type {HTMLTableSectionElement}
	 */
	get tbody() {
		if (this.tBodies[0]) { return this.tBodies[0]; }
		return this.createTBody();
	}

	/**
	 * Table foot section
	 * @type {HTMLTableSectionElement}
	 */
	get tfoot() {
		if (this.tfoot) { return this.tfoot; }
		else {
			return this.createFoot();
		}
	}

	/**
	 * Create table foot section
	 */
	createFoot() {
		return this.createTFoot();
	}

	/**
	 * Create a table column `<col>` element to support grouping and style cells of the column.
	 * @param {object} columnDefinition Column definition object
	 */
	createCol(columnDefinition) {
		let el = document.createElement('col');
		for (let key in columnDefinition) {
			el.setAttribute(this.propToAttrName(key), columnDefinition[key]);
		}
		return el;
	}

	/**
	 * Columns configuration. Creates a table head and column groups when changed. 
	 * @property
	 * @name columns
	 * @type {object} Hash-table with configurations by column name
	 */
	get columns() {
		return this._columns;
	}
	set columns(columns) {

		// Wrap in a group
		var groups = columns;


		// Remove <colgroups> or <cols>
		this._columns = {};
		while (this.querySelector('colgroup,col,thead')) {
			let el = this.querySelector('colgroup,col,thead');
			el.parentElement.removeChild(el);
		}

		if (!Array.isArray(groups)) { return; }

		let table = this;
		groups.forEach(group => {
			let groupEl = document.createElement('colgroup');
			for (let key in group) {
				if (key == 'cols') { continue; }
				groupEl.setAttribute(key, group[key]);
			}
			if (Array.isArray(group.cols)) {
				group.cols.forEach((col) => {
					table._columns[col.name] = col;
					groupEl.appendChild(table.createCol(col));
				})
			}
			table.appendChild(groupEl);
		});

		this.thead.appendChild(this.createHeadRow(this._columns));
	}

	/**
	 * Create a default columns definition from the data when not provided. Data like this `[{test:1}]` can be reflected as `[{cols: [{name: 'test'}]}]`
	 * @param {array} data Record set with data
	 * @returns {array} Columns definition
	 */
	generateColumns(data) {
		if (!data[0]) { return; }
		let group = { cols: [] };
		for (let key in data[0]) {
			group.cols.push({
				name: key
			});
		}
		return [group];
	}

	/**
	 * Render a table based on provided data considering columns configuration. 
	 * @param {array} data Recordset with data `[{id:1, last: 'Smith'}, {id:2, last: 'Lincoln'}]`
	 * @param {array} columns Columns definition `[{cols: [{name: 'last'}]}]` 
	 */
	render(data, columnConfig) {
		this.clear();
		this.columns = columnConfig || this.generateColumns(data);
		this.renderBody(data, this.columns);
		this.fire('render', { bubbles: true });
	}

	/**
	 * Render a table contents from data and columns definitions
	 * @param {array} data Recordset with data
	 * @param {object} columns Hash-table with columns configuration by name
	 */
	renderBody(data, columns) {
		for (var i = 0; i < data.length; i++) {
			let tr = this.createRow(i, data[i], columns);
			if (tr) {
				this.tbody.appendChild(tr);
			}
		}
	}

	/**
	 * Selected cells. Normally would return cells with `mark` attribute.
	 * @type {HTMLCollection}
	 */
	get selectedCells() {
		return this.root.querySelectorAll('[mark]');
	}

	/**
	 * Remove selection
	 */
	clearSelection() {
		this.selectedCells.forEach(el => {
			el.removeAttribute('mark');
		});
	}

	/**
	 * Select provided cells
	 * @param {HTMLCollection} cells Collection of cells
	 * @param {?boolean} unselect Optionally unselect if provided
	 * @fires select
	 */
	selectCells(cells, unselect) {
		if (!cells) { return; }
		if (cells.length == null) { cells = [cells]; }
		cells.forEach(el => {
			if (unselect) {
				el.removeAttribute('mark');
			} else {
				el.setAttribute('mark', '');
			}
		});
		this.fire('select');
	}

	/**
	 * Select a cell or cells by row index or column name. Selects a row when only `rowIndex` is specified. Selects a column when only `columnName` is specified. Or selects a cell if both are specified.
	 * @param {?number} rowIndex Optional index of a row to select
	 * @param {?string} columnName Optional name of a column
	 * @param {?boolean} unselect Optional flag to unselect
	 */
	select(rowIndex, columnName, unselect) {
		if (rowIndex != null && columnName == null) { // row
			let tr = this.tbody.rows[rowIndex];
			if (tr) {
				this.selectCells(tr.cells, unselect);
			}
		} else if (rowIndex == null && columnName != null) { // column
			let cells = this.root.querySelectorAll('tbody td[key="' + columnName + '"]');
			this.selectCells(cells, unselect);
		} else { // cell
			let tr = this.tbody.rows[rowIndex];
			if (tr) {
				let el = tr.querySelector('tbody td[key="' + columnName + '"]');
				this.selectCells(el, unselect);
			}
		}
	}

	/**
	 * Focus event handler
	 * @param {Event} event 
	 */
	onfocus(event) { }


	/**
	 * Select event handler
	 * @param {Event} event 
	 */
	onselect(event) { }

	/**
	 * Connect event handler
	 * @param {CustomEvent} event 
	 */
	onconnect(event) { }

	/**
	 * Render event handler
	 * @param {CustomEvent} event 
	 */
	onrender(event) { }

	/**
	 * Double click and double tap handler
	 * @param {MouseEvent} event 
	 */
	ondblclick(event) { }


	/**
	 * Blur event handler
	 * @param {Event} event 
	 */
	onblur(event) { }

	/**
	 * Sort event handler
	 * @param {Event} event
	 */
	onsort(event) { }
}

Table.is = 'zs-table';
Table.tagName = 'table';

// ForEach polyfill on HTMLCollection
if (HTMLCollection && !HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = function (fn, scope) {
		for (var i = 0, len = this.length; i < len; ++i) {
			fn.call(scope, this[i], i, this);
		}
	}
}
