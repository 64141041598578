// tslint:disable: max-line-length

export const DIMENSION_MAPPING_NAMES = [
  'Drug Mapping',
  'Diagnosis Mapping',
  'Procedure Mapping',
  'Physician Speciality Mapping',
  'Geography Mapping',
  'Physician Mapping'
];

export const MAXIMUM_CSV_FILE_SIZE = 20000000;

export const DOWNLOAD_ZIP_FILE_NAME = 'Patient_Journy_Builder_Templates.zip';

export const ACTION_DEFINATION_CONFIG = {
  'Concept & Cohort': {
    instructions: [
    ],
    isErrorIconShow: true,
    payloadName: 'concept & cohort',
    status:'ideal'
  },
  'Dimension Mapping': {
    instructions: [
      'Please download the templates for the schema of the files to be uploaded.',
      'Duplicate records will automatically be ignored.',
    ],
    isErrorIconShow: true,
    payloadName: 'dimension_mapping',
    status:'ideal'
  },
  'Days of Supply Imputation': {
    instructions: [
      'Please download the templates for the schema of the files to be uploaded.',
      'Duplicate records will automatically be ignored.',
      'The default value to be imputed for Days of Supply is 28 days',
    ],
    isErrorIconShow: true,
    csvFileName: 'dos-total-claims',
    payloadName: 'days_of_supply',
    status:'ideal'
  },
  'Grace Period Analysis': {
    instructions: [
      'Please download the templates for the schema of the files to be uploaded.',
      'Duplicate records will automatically be ignored.',
      'The default Grace Period is 30 Days',
    ],
    isErrorIconShow: true,
    csvFileName: 'grace-total-claims',
    payloadName: 'grace_period',
    status:'ideal'
  },

  'Regimen Refinement': {
    instructions: [],
    isErrorIconShow: true,
    csvFileName: 'consolidated-regimen',
    payloadName: 'regimen_drop_length',
    status:'ideal'
  },

  'Line of Therapy': {
    instructions: ['Select line of therapy from below options.'],
    isErrorIconShow: true,
    csvFileName: 'line-of-therapy',
    payloadName: 'lot_rule',
    status:'ideal',
  },
  'Patient Funnel': { isErrorIconShow: false,     status:'ideal', },
  'Treatment Dynamics': { isErrorIconShow: false,     status:'ideal', },
  'Adherence Analysis': { isErrorIconShow: false,     status:'ideal', },
  'Source of Business': { isErrorIconShow: false,     status:'ideal', },
  'Longitudinal Journey': { isErrorIconShow: false,     status:'ideal', },
  'Custom Query': { isErrorIconShow: false,     status:'ideal' },
  'Python Module': {
    instructions: ['Please mention all the packages in the Requirements file which are required for the execution of the Python file.'],
    isErrorIconShow: false,
    status:'ideal',
  },
  'Feature Catalogue': {
    instructions: ['Select master feature and user feature from following option.'],
    isErrorIconShow: true,
    status:'ideal',
  },
  'Regimen Creation': { 
    isErrorIconShow: false,
    instructions: ['Select the study.'],
    payloadName: 'study_id',
    status:'ideal',
  },
  'Regimen Merge': { 
    isErrorIconShow: false,
    payloadName: 'study_id',
    status:'ideal',
  },
  'Claim Filter': {
    instructions: ['Please select the claims for which you wish to filter the data'],
    isErrorIconShow: false,
    payloadName: 'claim_types',
    status:'ideal',
  },
  'Stage Identification': {
    isErrorIconShow: false,
    payloadName: 'study_id',
    status:'ideal',
  },
  'Data Transfer': {
    isErrorIconShow: false,
    instructions: ['Data Transfer limit is 5TB per table', 'Large size tables may take more time to copy'],
    status:'ideal',
  },
  'Invoke Flow': {
    isErrorIconShow: false,
    status:'ideal',
  },
  'Generate Alert': {
    isErrorIconShow: true,
    payloadName: 'alert_details',
    instructions: ['Generate alerts based on specific Patient events that occurred as part of longitudinal patient journey'],
    status: 'ideal',
  },
  'Cohort Descriptive Study': {
    isErrorIconShow: false,
    instructions: [],
    status: 'ideal',
  },
  'Distributed Cohort': {
    isErrorIconShow: false,
    payloadName: 'cohort',
    status: 'ideal',
  },
  'Study Executor': {
    isErrorIconShow: false,
    instructions: [],
    status: 'ideal',
  },
  'Study Insights': {
    isErrorIconShow: false,
    instructions: [],
    status: 'ideal',
  },
  'Primary Physician Assignment': {
    isErrorIconShow: false,
    payloadName: 'study_id',
    status:'ideal',
  },
  'Patient Journey Insights': {
    instructions: ['Please select insights you want to visualize', 'Dimension Mapping component is a pre-requisite for this step'],
    isErrorIconShow: true,
    payloadName: 'insights',
    status:'ideal',
  },
};

export const AlignName = {
  concept_cohort: 'Concept & Cohort',
  dimension_mapping: 'Dimension Mapping',
  days_of_supply: 'Days of Supply Imputation',
  grace_period: 'Grace Period Analysis',
  regimen_drop_length: 'Regimen Refinement',
  lot_rule: 'Line of Therapy',
  dos_imputation_s3_path: 'Days of Supply Imputation',
  grace_period_s3_path: 'Grace Period Analysis',
  alert_details: 'Generate Alert',
  insights: 'Patient Journey Insights'
};

export const USER_ROLES = {
  OWNER: 'owner',
  VIEWER: 'viewer',
  COLLABORATOR: 'collaborator',
};

export const INCLUDED_FILTER_TYPE = {
  REJECTED: 0,
  APPROVED: 1,
  REVERSED: 2,
};

export const JOB_TYPE_ID = {
  'Patient Journey':{
    'Mapping': 2,
    'Parameter': 3,
    'Visualization': 4
  }
};

export const PATTERNS = {
  FLOW_NAME: '',
  S3_URI: /^s3:\/\/([a-z0-9][a-z0-9.-]*)\/([a-zA-Z0-9\-._~:/]*)$/,
}

export const FILE_TYPES = {
  CSV: [
    'csv',
    'text/csv',
    'csv/text',
  ],
  PYTHON: [
    'py',
    'python',
    'text/x-python-script',
    'txt',
    'text',
    'text/plain'
  ]
};


export const BGH_STUDY_DESCRIPTION = [
  {
    'name': 'Genome Data',
    'id': 1,
    'criteria': {
      'has_genome_data': null,
      'has_egfr_gene_mutation': null
    }
  },
  {
    'name': 'Pathology Result',
    'id': 2,
    'criteria': {
      'has_pathology_result': null
    }
  },
  {
    'name': 'Age',
    'id': 3,
    'criteria': {
      'age_from': null,
      'age_to': null
    }
  },
  {
    'name': 'Surgery',
    'id': 4,
    'criteria': {
      'had_surgery': null
    }
  },
  {
    'name': 'Brain MRI',
    'id': 5,
    'criteria': {
      'had_mri': null,
      'showed_metastases': null
    }
  }
];

export const FLOW_ACTIVITY_STATUS = {
  'locked': 'LOCKED',
  'unlocked': 'UNLOCKED'
};

export const FLOW_ACTIVITY_TYPE = {
  'idle': 'idle',
  'edit': 'updating_flow',
  'save': 'save_flow'
};

export const COLOR_CODE_1 = [
  '#EC7200',
  '#C88800',
  '#A49700',
  '#81A02A',
  '#5FA64F',
  '#3EA871',
  '#21A88E',
  '#27A6A4',
  '#6050DC',
  '#D52DB7',
  '#FF2E7E',
  '#FF6B45',
  '#FFAB05',
  '#18F3CC',
  '#EB6620',
  '#E1F65D',
  '#FD595F',
  '#5648FB',
  '#8D38FC',
  '#5AD767',
  '#ED39DB',
  '#41AFFF',
  '#FF9836',
  '#FFC62B',
  '#6B0019',
  '#FFE949',
  '#8D38FC',
  '#ED39DB',
  '#6B0019',
  '#442727',
  '#32190A',
  '#CC9D2A',
  '#C3A34D',
  '#9EC921',
  '#1A5E07',
  '#2C7772',
  '#2F2B67',
  '#1F526E',
  '#461391',
  '#5B005E',
  '#F282E7',
  '#D1AAFF',
  '#99D3FD',
  '#A8C6FF',
  '#8BFFF5',
  '#D4F5D7',
  '#E9FFAC',
  '#FFFFA9',
  '#FFF6C7',
  '#FECA98',
  '#EE8045',
  '#FEACAF'
]

export const COLOR_CODE_2 = [
  '#2D535F',
  '#43BEBE',
  '#62D2D1',
  '#2D8B93',
  '#82DFE2',
  '#27A6A4',
  '#A5ECF3',
  '#D6F7FF',
  '#2F6F7B',
  '#F3FCFE',
  '#022D42',
  '#6050dc',
  '#d52db7',
  '#ff2e7e',
  '#ff6b45',
  '#ffab05',
]

export const COLOR_CODE_3 = [
  '#27A6A4',
  '#FFC657',
  '#EC7200',
  '#D6F7FF',
  '#2F2C3C',
  '#A5D8DD', 
  '#EA6A47', 
  '#0091D5', 
  '#F1F1F1', 
  '#202020', 
  '#7E909A', 
  '#1C4E80',
  '#0D3C4F',
  '#155B6F',
  '#2D7A8A',
  '#0A2026',
  '#489BAE',
  '#7AD0D9',
  '#2DCEBE',
  '#DAF6ED',
  '#2447B9',
  '#B2979B'
]
