import { Component, Input, OnChanges, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FlowNodeService } from 'src/app/_services/flow-node.service';

@Component({
  selector: 'app-flow-node-container',
  templateUrl: './flow-node-container.component.html',
  styleUrls: ['./flow-node-container.component.less'],
})
export class FlowNodeContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() nodes = [];
  @Input() connections = [];

  @ViewChild('flowNodes', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef;
  constructor(private nodeService: FlowNodeService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.nodeService.setRootViewContainerRef(this.viewContainerRef);
    this.nodeService.resetJsPlumbInstance();
    this.mapNewNodes();
  }

  ngOnDestroy() {
    this.nodeService.clear();
  }

  mapNewNodes() {
    this.nodeService.clear();
    setTimeout(() => {
      this.nodes.forEach((node) => {
        this.nodeService.addDynamicNode(node);
      });
    });
   
  setTimeout(() => {
      this.connections.forEach((connection) => {
        this.nodeService.addConnection(connection);
      });
    });
  }
}
