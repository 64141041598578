import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CanvasModuleService {
  baseUrl;
  httpRequestEvent = new EventEmitter<any>();
  httpRequestCounter = 0;
  constructor() {}

  getRequestCounter() {
    return this.httpRequestCounter;
  }

  updateRequestCounter(value) {
    this.httpRequestCounter = this.httpRequestCounter + value;
  }
}
