// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  fill: steelblue;
}
.bar:hover {
  fill: brown;
}
.axis--y path {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/widgets/d3-horizontal-bar/d3-horizontal-bar.component.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACA;EACI,WAAA;AACJ;AACA;EACI,aAAA;AACJ","sourcesContent":[".bar {\n    fill: steelblue;\n}\n.bar:hover {\n    fill: brown;\n}\n.axis--y path {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
