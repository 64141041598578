import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { jsPlumb } from 'jsplumb';
import { FlowNodeComponent } from './../_components/flow-node/flow-node.component';
import Node from '../_models/flow-node/flow-node.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ACTION_DEFINATION_CONFIG } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class FlowNodeService {
  private rootViewContainer: any;
  private removeNode: BehaviorSubject<object> = new BehaviorSubject({
    removed_node: null,
  });
  public hideIconActionDefination = ACTION_DEFINATION_CONFIG;
  jsPlumbInstance = jsPlumb.getInstance();

  constructor(private factoryResolver: ComponentFactoryResolver) {}

  public setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  public resetJsPlumbInstance() {
    this.jsPlumbInstance.reset();
    this.jsPlumbInstance = jsPlumb.getInstance();
  }

  public addDynamicNode(node: Node) {
    const factory = this.factoryResolver.resolveComponentFactory(FlowNodeComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    (component.instance as any).node = node;
    (component.instance as any).jsPlumbInstance = this.jsPlumbInstance;
    this.rootViewContainer.insert(component.hostView);
  }
  
   addConnection(connection) {
     this.jsPlumbInstance.connect({ uuids: connection.uuids });
   }
  
  public clear() {
    if (this.rootViewContainer) {
      this.rootViewContainer.clear();
    }
  }

  public getRemovedNode() {
    return this.removeNode.asObservable();
  }

  public updateRemovedNode(nodeName: string) {
    this.removeNode.next({ removed_node: nodeName });
  }
}
