import { Injectable } from '@angular/core';
import { ACTION_DEFINATION_CONFIG } from '../app.constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CommonService {
  public hideIconActionDefination = ACTION_DEFINATION_CONFIG;
  public componentStatus = {};
  // private isBreakpointActiveSubject = new Subject<any>();
  isBreakpointActive = false;
  constructor() {}

  breakPointNodes = [];
  selectedBreakPointNodes = [];
  isTwoComponentOnly = false;

  toggleIsNodeDraggable(bool: boolean) {
    // this.isBreakpointActiveSubject.next(bool);
  }

  getToggle() {
    return this.isBreakpointActive;
  }

  getSelectedComponent() {
    return this.breakPointNodes
  }
}
