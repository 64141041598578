import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
import Node from '../../_models/flow-node/flow-node.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalActionDefinitionComponent } from '../../_components/modal-action-definition/modal-action-definition.component';
import { FlowNodeService } from 'src/app/_services/flow-node.service';
import { CommonService } from 'src/app/_services/common.service';
import { CustomQueryService } from 'src/app/_services/custom-query.service';
import { PythonModuleService } from 'src/app/_services/python-module.service';
import { DataTransferService } from 'src/app/_services/data-transfer.service';
import { InvokeFlowService } from 'src/app/_services/invoke-flow.service';
import { Subscription } from 'rxjs';
import { FeatureModuleService } from 'src/app/_services/feature-catalogue-module.service';

@Component({
  selector: 'app-flow-node',
  templateUrl: './flow-node.component.html',
  styleUrls: ['./flow-node.component.less'],
})

// tslint:disable: variable-name
export class FlowNodeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() node: Node;
  @Input() jsPlumbInstance;

  public isHideIcon;
  public nodeName: string;
  public _customQuerySubscription: Subscription;
  public _pythonModuleSubscription: Subscription;
  public _dataTransferSubscription: Subscription;
  public _invokeFlowSubscription: Subscription;
  public _featureCatalogueSubscription: Subscription;
  public isBreakpointActive: boolean = false;
  public breakPointNodes = [];
  public selectedBreakPointNodes = [];
  public breaknodes = [];

  nodesWithDoubleClickAction = [
    'Concept & Cohort',
    'Dimension Mapping',
    'Days of Supply Imputation',
    'Grace Period Analysis',
    'Regimen Refinement',
    'Line of Therapy',
    'Custom Query',
    'Python Module',
    'Feature Catalogue',
    'Regimen Merge',
    'Claim Filter',
    'Stage Identification',
    'Data Transfer',
    'Invoke Flow',
    'Generate Alert',
    'Cohort Descriptive Study',
    'Study Executor',
    'Study Insights',
    'Primary Physician Assignment',
    'Distributed Cohort',
    'Patient Journey Insights'
  ];

  dragDropContainerAttributes = {
    height: 500,
    width: 800,
  };

  showModalActionDefinition: boolean;
  componentStatus = {};
  isTwoSelectedPinned = false;
  isTwoComponentOnly = false

  constructor(
    private modalService: NgbModal,
    public matDialog: MatDialog,
    private _nodeService: FlowNodeService,
    public _commonService: CommonService,
    public _customQueryService: CustomQueryService,
    public _pythonModuleService: PythonModuleService,
    public _dataTransferService: DataTransferService,
    public _invokeFlowService: InvokeFlowService,
    public _featureCatalogueService: FeatureModuleService
  ) {
    this.isHideIcon = this._commonService.hideIconActionDefination;
    this.componentStatus = this._commonService.componentStatus;
    this.isTwoComponentOnly = this._commonService.isTwoComponentOnly;
    this.breakPointNodes = this._commonService.breakPointNodes;
    this.selectedBreakPointNodes = this._commonService.selectedBreakPointNodes;
    this.isBreakpointActive = this._commonService.isBreakpointActive;
  }

  ngOnInit() {
    const dragDropContainerElementAttribues = document.querySelector('.drag-drop-container').getBoundingClientRect();
    this.dragDropContainerAttributes.width = Math.floor(dragDropContainerElementAttribues.width);
    this.dragDropContainerAttributes.height = Math.floor(dragDropContainerElementAttribues.height * 0.98);
    this.createCustomQuerySubscription();
    this.createPythonModuleSubscription();
    this.createDataTransferSubscription();
    this.createInvokeFlowSubscription();
    this.breakingWork();
    //this.createFeatureCatalogueModuleSubscription();
    this.getModuleName();
  }

  ngAfterViewInit() {
    this.drawNode();
  }

  breakingWork() {
    this.updateBreakPointFlag()
  }

  public updateBreakPointFlag() {
    if(this._commonService.isBreakpointActive == false) {
      this._commonService.breakPointNodes = [];
      this._commonService.selectedBreakPointNodes = [];
    }
  }

  private createCustomQuerySubscription() {
    this._customQuerySubscription = this._customQueryService.customQueryState.subscribe((state: any) => {
      if (this.node.name === 'Custom Query' && state.customQueryName.length > 0 && this.node.uuid === state.customQueryUuid) {
        this.nodeName = state.customQueryName;
      }
    });
  }

  private createDataTransferSubscription() {
    this._dataTransferSubscription = this._dataTransferService.dataTransferState.subscribe((state: any) => {
      if (this.node.name === 'Data Transfer' && state.dataTransferName.length > 0 && this.node.uuid === state.dataTransferUuid) {
        this.nodeName = state.dataTransferName
      }
    });
  }

  private createInvokeFlowSubscription() {
    this._invokeFlowSubscription = this._invokeFlowService.invokeFlowState.subscribe((state: any) => {
      if (this.node.name === 'Invoke Flow' && state.invokeFlowName.length > 0 && this.node.uuid === state.invokeFlowUuid) {
        this.nodeName = state.invokeFlowName
      }
    });
  }

  private createPythonModuleSubscription() {
    this._pythonModuleSubscription = this._pythonModuleService.pythonModuleState.subscribe((state: any) => {
      if (this.node.name === 'Python Module' && state.pythonModuleName.length > 0 && this.node.uuid === state.pythonModuleUuid) {
        this.nodeName = state.pythonModuleName;
      }
    });
  }

  private createFeatureCatalogueModuleSubscription() {
    this._featureCatalogueSubscription = this._featureCatalogueService.featureModuleState.subscribe((state: any) => {
      if (this.node.name === 'Feature Catalogue' && state.featureModuleState.length > 0 && this.node.uuid === state.featureModuleUuid) {
        this.nodeName = state.featureModuleName;
      }
    });
  }

  getModuleName() {
    if (this.node.name === 'Custom Query') {
      const moduleData = this._customQueryService.getCustomQueryDetails(this.node.uuid);
      if (moduleData) {
        this.nodeName = moduleData.name;
      } else {
        this.nodeName = this.node.name;
      }
    } else if (this.node.name === 'Python Module') {
      const moduleData = this._pythonModuleService.getPythonFiles(this.node.uuid);
      if (moduleData) {
        this.nodeName = moduleData.name;
      } else {
        this.nodeName = this.node.name;
      }
    } else if (this.node.name === 'Invoke Flow') {
      const moduleData = this._invokeFlowService.getInvokeFlowDetails(this.node.uuid);
      if (moduleData) {
        this.nodeName = moduleData.name;
      } else {
        this.nodeName = this.node.name;
      }
    } else if (this.node.name === 'Data Transfer') {
      const moduleData = this._dataTransferService.getDataTransferDetails(this.node.uuid);
      if (moduleData) {
        this.nodeName = moduleData[0].name;
      } else {
        this.nodeName = this.node.name;
      }
    } else if (this.node.name === 'Feature Catalogue') {
      const moduleData = this._featureCatalogueService.getFeatureDetails(this.node.uuid);
      if (moduleData) {
        this.nodeName = moduleData.name;
      } else {
        this.nodeName = this.node.name;
      }
    } else {
      this.nodeName = this.node.name;
    }
  }

  drawNode() {
    const exampleDropOptions = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive',
    };
    const sourceEndpoint = {
      endpoint: ['Dot', { radius: 8 }],
      paintStyle: { fill: '#36454F' },
      connectorStyle: { stroke: '#36454F', strokeWidth: 3 },
      isSource: true,
      scope: 'jsPlumb_DefaultScope',
      connector: ['Bezier', { curviness: 90 }],
      maxConnections: [1,2,3,4,14].includes(this.node['job_type_id']) ? 1 : 5,
      isTarget: false,
      connectorOverlays: [['Arrow', { location: 1 }]],
      dropOptions: exampleDropOptions,
    };
    const targetEndpoint = {
      endpoint: ['Dot', { radius: 8 }],
      paintStyle: { fill: null, stroke: '#36454F', strokeWidth: 2 },
      connectorStyle: { stroke: '#36454F', strokeWidth: 5 },
      isSource: false,
      scope: 'jsPlumb_DefaultScope',
      maxConnections: [1,2,3,4,14].includes(this.node['job_type_id']) ? 1 : 5,
      isTarget: true,
      uniqueEndpoint: true,
      dropOptions: exampleDropOptions,
    };
    const { uuid } = this.node;
    this.jsPlumbInstance.addEndpoint(uuid, { anchor: 'Left', uuid: uuid + '_left' }, targetEndpoint);
    this.jsPlumbInstance.addEndpoint(uuid, { anchor: 'Right', uuid: uuid + '_right' }, sourceEndpoint);
    this.jsPlumbInstance.draggable(uuid, {
      getConstrainingRectangle: () => [this.dragDropContainerAttributes.width, this.dragDropContainerAttributes.height],
      containment: 'dragFlowContainer',
    });
  }


  addToBreakPoint(node) {
    var key = node.uuid;
    if (this._commonService.breakPointNodes.indexOf(key) === -1) {
      this._commonService.selectedBreakPointNodes.push(node);
      this._commonService.breakPointNodes.push(key);
    } else {
      this._commonService.breakPointNodes.splice(this._commonService.breakPointNodes.indexOf(key), 1);
      const removeIndex = this._commonService.selectedBreakPointNodes.findIndex( item => item.uuid === key );
      this._commonService.selectedBreakPointNodes.splice(removeIndex, 1)
    }
  }

 
  removeNode(node) {
    this.jsPlumbInstance.removeAllEndpoints(node.uuid);
    this.jsPlumbInstance.remove(node.uuid);
    this._nodeService.updateRemovedNode(node.uuid);
    this._customQueryService.removeNode(node.uuid);
  }

  public hide() {
    this.showModalActionDefinition = false;
  }

  open(content) {
    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openModalActionDefinition(nodeName: string, nodeUUID: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-action-definition-component';
    dialogConfig.height = nodeName !== 'Custom Query' ? 'auto' : '620px';
    dialogConfig.maxHeight = '800px';
    dialogConfig.width = nodeName !== 'Custom Query' ? (nodeName != 'Data Transfer' ? '800px' : '1000px') : '1200px';
    dialogConfig.data = { nodeName, nodeUUID };
    const modalActionDefinitonDialog = this.matDialog.open(ModalActionDefinitionComponent, dialogConfig);
  }

  ngOnDestroy() {
    this._customQuerySubscription.unsubscribe();
    this._pythonModuleSubscription.unsubscribe();
    //this._featureCatalogueSubscription.unsubscribe();
  }

  getFailedStatus(uuid) {
    if(this._commonService.breakPointNodes.indexOf(uuid) === -1 && this.componentStatus[uuid] == 'failed') {
      return true;
    }
    return false;
  }

  getIdealStatus(uuid) {
    if(this._commonService.breakPointNodes.indexOf(uuid) === -1 && this.componentStatus[uuid] == 'ideal') {
      return true;
    }
    return false;
  }

  getRunningeStatus(uuid) {
    if(this._commonService.breakPointNodes.indexOf(uuid) === -1 && ['running', 'pending'].includes(this.componentStatus[uuid])) {
      return true;
    }
    return false;
  }

  getBreakPointStatus(uuid) {
    if(this._commonService.isBreakpointActive && this._commonService.breakPointNodes.indexOf(uuid) != -1) {
      return true;
    }
    return false;
  }

  getCompleteStatus(uuid) {
    if(this._commonService.breakPointNodes.indexOf(uuid) === -1 && this.componentStatus[uuid] == 'completed') {
      return true;
    }
    return false;
  }

  breakpointValidation(node) {
    if(this._commonService.isBreakpointActive && !this._commonService.breakPointNodes.includes(node.uuid)) {
      if(this.isTwoComponentOnly == true && this._commonService.breakPointNodes.length > 2) {
        return false
      }
      if(this.isTwoComponentOnly == false && this._commonService.breakPointNodes.length > 1) {
        return false
      }
      else {
        return true;
      }
    } else {
      return false;
    }
  }

  unpinnedBreakPointNode(node) {
    if(this._commonService.isBreakpointActive && this._commonService.breakPointNodes.includes(node.uuid)) {
      return true;
    }
  }
}
