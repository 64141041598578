import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class InvokeFlowService {
  private invokeFlowDetails: object = {};
  private invokeFlowSubject = new Subject<any>();
  invokeFlowState = this.invokeFlowSubject.asObservable();

  constructor() {}

  saveInvokeFlowDetails(uuid: string, invokeFlow: object) {
    if (uuid && invokeFlow) {
      this.invokeFlowDetails[uuid] = invokeFlow;
      this.invokeFlowSubject.next({
        invokeFlowName: this.invokeFlowDetails[uuid].name,
        invokeFlowUuid: uuid,
      } as any);
    }
  }

  initInvokeFlowDetails(invokeFlowDetails: {}) {
    this.invokeFlowDetails = invokeFlowDetails;
  }

  getInvokeFlowDetails(uuid: string = null) {
    if (uuid) {
      return this.invokeFlowDetails[uuid];
    }
    return this.invokeFlowDetails;
  }

  removeNode(nodeName: string) {
    delete this.invokeFlowDetails[nodeName];
  }
}
