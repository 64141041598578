// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flow-definition-container {
  position: relative;
  padding: 0 !important;
  height: 100%;
}
.close-settings {
  position: absolute;
  top: -20px;
  left: -25px;
}
.setting-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
}
.flow-definition-heading {
  padding: 0 0 15px 0;
  color: #2f2c3c;
  background-color: white;
  border-bottom: 1px solid gray;
}
.cta-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/_components/flow-definition/flow-definition.component.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,YAAA;AACF;AAEA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;AAAF;AAIA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AAFF;AAKA;EACE,mBAAA;EACA,cAAA;EACA,uBAAA;EACA,6BAAA;AAHF;AAMA;EAEE,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;AALF","sourcesContent":[".flow-definition-container {\n  position: relative;\n  padding: 0 !important;\n  height: 100%;\n}\n\n.close-settings {\n  position: absolute;\n  top: -20px;\n  left: -25px;\n}\n\n\n.setting-wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  padding:15px;\n  flex-direction: column;\n}\n\n.flow-definition-heading {\n  padding: 0 0 15px 0;\n  color: #2f2c3c;\n  background-color: white;\n  border-bottom: 1px solid gray;\n}\n\n.cta-wrapper {\n  width: 100%;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
