import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../_shared/material.module';
import { D3FunnelChartComponent } from './d3-funnel-chart/d3-funnel-chart.component';
import { D3PieChartComponent } from './d3-pie-chart/d3-pie-chart.component';
import { D3HorizontalBarComponent } from './d3-horizontal-bar/d3-horizontal-bar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    D3FunnelChartComponent,
    D3PieChartComponent,
    D3HorizontalBarComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [
    D3FunnelChartComponent,
    D3PieChartComponent,
    D3HorizontalBarComponent,
  ],
})
export class WidgetsModule {}
