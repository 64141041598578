import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Table from '../../../zsui/src/table/table.m.js';
import Dialog from '../../../zsui/src/dialog/dialog.m.js';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../_components/modal/modal.component';
import { StudiesApiService } from '../_services/api/studies.api.service';
import { common, FLOW_ACTIVITY } from '../app.messages';
import { FlowService } from '../_services/flow.service';
import { FlowApiService } from '../_services/api/flow.api.service';
import { NotificationService } from '../_services/notification.service';
import { FLOW_ACTIVITY_STATUS } from '../app.constants'

// tslint:disable: no-string-literal
Table['register']();

Dialog.register();

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: semicolon
// tslint:disable: deprecation
export class StudiesComponent implements OnInit {
  @ViewChild('myStudiesTable', { static: true }) myStudiesTable;
  @ViewChild('studyName', { static: true }) studyName: ElementRef;

  public allStudies = [];
  public myStudies = [];
  public teamStudies = [];
  public filteredAllStudies = [];
  public filteredMyStudies = [];
  public filteredTeamStudies = [];
  public commonMessage = common;
  closeResult = '';
  searchedKeyword: string;
  searchedKeywordForTeam: string;
  searchedKeywordForAll: string;
  showModal: boolean;
  public isFlowExecuting;
  flowExecutionResponse: {};
  executionLogs = [];
  executionStatus = '';
  failedJobConfig: any;
  studyType: 'allStudies' | 'myStudies' | 'teamStudies' = 'allStudies';

  constructor(
    private _studiesApiService: StudiesApiService,
    private _flowService: FlowService,
    private _flowApiService: FlowApiService,
    private _router: Router,
    private titleService: Title,
    private modalService: NgbModal,
    public matDialog: MatDialog,
    private notifyService: NotificationService
  ) {}

  ngOnInit() {
    this.getFlows();
    this.setTitle('Orchestrator');
  }

  setStudyType(studyType: 'allStudies' | 'myStudies' | 'teamStudies') {
    this.studyType = studyType;
  }

  getFlows() {
    this._studiesApiService.getStudies().subscribe((response: any) => {
      this.myStudies = response.user_studies;
      this.filteredMyStudies = response.user_studies

      this.teamStudies = response.team_studies;
      this.filteredTeamStudies = response.team_studies;

      this.allStudies = [...response.user_studies, ...response.team_studies];
      this.filteredAllStudies = [...response.user_studies, ...response.team_studies];
    });
  }

  onSearchFilter(event, studyType){
    if(studyType === 'allStudies'){
      this.searchedKeywordForAll = event.target.value
      this.filteredAllStudies = this.allStudies.filter(
        (study) =>
          study.name.toLowerCase().includes(this.searchedKeywordForAll.toLowerCase()) ||
          study.data_source.toLowerCase().includes(this.searchedKeywordForAll.toLowerCase())
      );
    }else if(studyType === 'myStudies'){
      this.searchedKeyword = event.target.value
      this.filteredMyStudies = this.myStudies.filter(
        (study) =>
          study.name.toLowerCase().includes(this.searchedKeyword.toLowerCase()) ||
          study.data_source.toLowerCase().includes(this.searchedKeyword.toLowerCase())
      );
    }else{
      this.searchedKeywordForTeam = event.target.value
      this.filteredTeamStudies = this.teamStudies.filter(
        (study) =>
          study.name.toLowerCase().includes(this.searchedKeywordForTeam.toLowerCase()) ||
          study.data_source.toLowerCase().includes(this.searchedKeywordForTeam.toLowerCase())
      );
    }

  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public create() {
    this._flowService.setFlowId(null);
    this._router.navigate(['/studies/create/']);
  }

  public saveStudyName = () => {};
  public onAction = () => {};

  onEdit(flowId) {
    this._flowApiService.getFlowLockStatus(flowId).subscribe((response: any) => {
      if (response.status) {
        if (response.flow_status.status === FLOW_ACTIVITY_STATUS.locked) {
          this.notifyService.showWarning(FLOW_ACTIVITY.lockedFlow.replace('{user}', response.flow_status.locked_by), 'Restricted');
        } else if (response.flow_status.status === FLOW_ACTIVITY_STATUS.unlocked) {
          this._flowApiService.get_schedule_details(flowId).subscribe((scheduleResponse: any) => {
            if (scheduleResponse.status && !scheduleResponse.schedule_details) {
              this._flowService.setFlowId(flowId);
              this._router.navigate([`/studies/${flowId}/edit/`]);
            } else {
              this.notifyService.showWarning(FLOW_ACTIVITY.flowScheduled, 'Restricted');
            }
          });
        } else {
          this.notifyService.showError(response.message, 'Error');
        }
      } else {
        this.notifyService.showError(response, 'Error');
      }
    });
  }

  public hide = () => {
    this.showModal = false;
  };

  open(content) {
    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getFlowExportDetails(flowId) {
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '400px';
    dialogConfig.width = '1200px';
    dialogConfig.data = { flowId, id: 'modal-component' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  getBGHVisualization(flowId) {
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'visualization-modal-component';
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    dialogConfig.data = { flowId, id: 'visualization-modal-component' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  onDelete(flowId) {
    this._flowApiService.getFlowLockStatus(flowId).subscribe((response: any) => {
      if (response.status) {
        if (response.flow_status.status === FLOW_ACTIVITY_STATUS.locked) {
          this.notifyService.showWarning(FLOW_ACTIVITY.lockedFlow.replace('{user}', response.flow_status.locked_by), 'Restricted');
        } else if (response.flow_status.status === FLOW_ACTIVITY_STATUS.unlocked) {
          this._flowService.setFlowId(flowId);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'delete-modal-content-wrapper';
          dialogConfig.height = '200px';
          dialogConfig.width = '500px';
          dialogConfig.data = { flowId, id: 'delete-modal-content-wrapper' };
          const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
          modalDialog.afterClosed().subscribe((result) => {
            if (result) {
              this._flowApiService.deleteFlow(flowId).subscribe(
                (response: any) => {
                  if (response.status) {
                    this.notifyService.showSuccess(response['message'], 'Notification');
                    this.getFlows();
                  }
                },
                (err) => {
                  this.notifyService.showError(err.error['message'], 'Error');
                }
              );
            }
          });
        } else {
          this.notifyService.showError(response.message, 'Error');
        }
      } else {
        this.notifyService.showError(response, 'Error');
      }
    });
  }

  public executeFlow(flowId) {
    this.getExecutionlog(flowId);
  }

  getExecutionlog(flowId) {
    if (flowId) {
      this.executionLogs = [];
      this._flowApiService.getFlowExecutionStatus(flowId).subscribe(
        (response) => {
          this.flowExecutionResponse = response;
          this.assignStatus(flowId);
        },
        (err) => {}
      );
    }
  }

  assignStatus(flowId) {
    this.executionLogs = this.flowExecutionResponse['logs'];
    this.executionStatus = this.flowExecutionResponse['status'];

    if (flowId && this.executionLogs != undefined) {
      for (const res in this.executionLogs) {
        if (this.executionLogs[res]['status'] == 'failed') {
          this.failedJobConfig = this.executionLogs[res];
        }
      }
    }
    this.saveExportDetail(flowId);
  }

  saveExportDetail(flowId) {
    if (flowId && this.failedJobConfig != undefined && this.failedJobConfig['status'] == 'failed') {
      this.reExecuteTheFlow(flowId);
    } else {
      this.executeTheFlow(flowId);
    }
  }

  executeTheFlow(flowId) {
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'export-modal-content-wrapper';
    dialogConfig.height = '250px';
    dialogConfig.width = '500px';
    dialogConfig.data = { flowId, id: 'export-modal-content-wrapper' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getFlows();
      }
    });
  }

  reExecuteTheFlow(flowId) {
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 're-execute-modal-content-wrapper'; // 'export-modal-content-wrapper';
    dialogConfig.height = '200px';
    dialogConfig.width = '500px';
    dialogConfig.data = { flowId: flowId, id: 're-execute-modal-content-wrapper', payload: this.failedJobConfig };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result == false) {
        this.executeTheFlow(flowId);
      }
    });
  }

  getArchiveFilesInfo(flowId) {
    this._flowService.setFlowId(flowId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'archive-data-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '900px';
    dialogConfig.data = { flowId, id: 'archive-data-modal' };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  schedule(flowId) {
    this._flowApiService.getFlowLockStatus(flowId).subscribe((response: any) => {
      if (response.status) {
        if (response.flow_status.status === FLOW_ACTIVITY_STATUS.locked) {
          this.notifyService.showWarning(FLOW_ACTIVITY.lockedFlow.replace('{user}', response.flow_status.locked_by), 'Restricted');
        } else if (response.flow_status.status === FLOW_ACTIVITY_STATUS.unlocked) {
          this._flowService.setFlowId(flowId);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'schedule-modal-content-wrapper';
          dialogConfig.height = 'auto';
          dialogConfig.width = '700px';
          dialogConfig.data = { flowId, id: 'schedule-modal-content-wrapper' };
          const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
          modalDialog.afterClosed().subscribe((result) => {
            if (result) {
            }
          });
        } else {
          this.notifyService.showError(response.message, 'Error');
        }
      } else {
        this.notifyService.showError(response, 'Error');
      }
    });
  }

  onCloneStudy(flowId, title) {
    this._flowApiService.getFlowLockStatus(flowId).subscribe((response: any) => {
      if (response.status) {
        if (response.flow_status.status === FLOW_ACTIVITY_STATUS.locked) {
          this.notifyService.showWarning(FLOW_ACTIVITY.lockedFlow.replace('{user}', response.flow_status.locked_by), 'Restricted');
        } else if (response.flow_status.status === FLOW_ACTIVITY_STATUS.unlocked) {
          this._flowService.setFlowId(flowId);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'clone-modal-content-wrapper';
          dialogConfig.height = '280px';
          dialogConfig.width = '700px';
          dialogConfig.data = { flowId, title, id: 'clone-modal-content-wrapper' };
          const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
          modalDialog.afterClosed().subscribe((result) => {
            if (result) {
              this.ngOnInit();
            }
          });
        } else {
          this.notifyService.showError(response.message, 'Error');
        }
      } else {
        this.notifyService.showError(response, 'Error');
      }
    });
  }
}
