// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-button {
  color: #2F2C3C;
  background-color: white;
  margin: 0px 0px 16px 0px !important;
  padding: 15px;
  border-bottom: 1px solid gray !important;
}
.breakingpoint-class {
  color: #27A6A4;
}
.grey-color {
  color: gray;
}
.green-colored {
  color: #27a6a4;
}
.red-color {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/app/createflow/createflow.component.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;EACA,mCAAA;EACA,aAAA;EACA,wCAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,WAAA;AADJ;AAIA;EACI,cAAA;AAFJ;AAKA;EACI,UAAA;AAHJ","sourcesContent":[".reset-button{\n    color: #2F2C3C;\n    background-color: white;\n    margin: 0px 0px 16px 0px !important;\n    padding: 15px;\n    border-bottom: 1px solid gray !important;\n}\n\n.breakingpoint-class {\n    color: #27A6A4;\n}\n\n.grey-color {\n    color: gray;\n}\n\n.green-colored {\n    color: #27a6a4;\n}\n\n.red-color {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
