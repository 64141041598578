import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class CohortsApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getCohorts(): Observable<object> {
    return this._httpService.get(`${environment.cohortApiBaseUrl}/cohort/flow_builder/cohort_list`);
  }

  getConcepts(): Observable<object> {
    return this._httpService.get(`${environment.cohortApiBaseUrl}/concept/flow_builder/concept_list`);
  }
}
