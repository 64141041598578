import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class FeatureCatalogApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getFeatureList(): Observable<object> {
    return this._httpService.get(`${environment.featureCatalogApiBaseUrl}orchestration/features`);
  }

  executeFeatureJob(payload): Observable<object> {
    return this._httpService.post(`${environment.featureCatalogApiBaseUrl}orchestration/job/execute`, payload);
  }

  getFCStudiesList(): Observable<object> {
    return this._httpService.get(`${environment.featureCatalogApiBaseUrl}orchestration/studies`)
  }
}
